import { Box, Card, CardActionArea, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Project } from "../../models/Project";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { PasskeyProject } from "../../models/PasskeyProject";

export interface Props {
  project: Project | PasskeyProject
}

const ProjectsCard = (props: Props) => {
  const {project} = props
  const navigate = useNavigate();
  const theme = useTheme()
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const width = matchesSmDown ? 'calc(100vw - 48px)' : '336px'
  const isHankoProject = isHankoProjectType(project)

  return (
    <Card elevation={0} sx={{borderRadius: '16px'}}>
      <CardActionArea onClick={() => {
        if (isHankoProject) {
        navigate(project.id + '/dashboard')
        } else {
          navigate(`../passkey_projects/${project.id}/dashboard`)
        }
      }} sx={{
        width: width,
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 4,
      }}>
        <Typography variant="h6" fontSize="1.125rem" width="100%" textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden">{project.application_name}</Typography>
        <Typography variant="body1" width="100%" textOverflow="ellipsis" whiteSpace="nowrap"
                    overflow="hidden">{project.application_url}</Typography>
        <ArrowForwardIos sx={{marginLeft: "auto"}}/>
        <Typography variant="body2" color={!isHankoProject ? "primary" : "error"} sx={{
          position: "absolute",
          bottom: "32px",
          left: "32px",
          padding: "2px 12px",
          backgroundColor: '#3C4049',
          borderRadius: "16px",
          fontWeight: 500,
        }}>{isHankoProject ? "Hanko" : "Passkey API"}</Typography>
      </CardActionArea>
    </Card>
  )
}

export default ProjectsCard

const isHankoProjectType = (toBeDetermined: Project | PasskeyProject): toBeDetermined is Project => {
  if ((toBeDetermined as Project).status) {
    return true
  }

  return false
}