import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { NewOrganization } from "../../components/NewOrganization";
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import { User } from "../../models/User";
import { Width } from "../../const/Width";

export const NoOrganization = () => {
  const user = useRouteLoaderData("user") as User
  const [modalOpen, setModalOpen] = useState(user.organizations.length === 0)
  const navigate = useNavigate()
  const {state} = useLocation()

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    let organizations = user.organizations
    if (state) {
      organizations = organizations.filter((value) => value.id !== state)
    }
    if (organizations.length > 0) {
      navigate(organizations[0].id, {replace: true})
    }
  }, [])

  return <Box maxWidth={Width.NORMAL}>
    <Typography variant="h4">Welcome to Hanko Cloud</Typography>
    <Typography variant="body1" sx={{mb: 2, mt: 2}}>Create a new organization to manage all your Hanko projects and your subscription in one place.</Typography>
    <Button onClick={openModal} variant="contained">Create Organization</Button>
    <NewOrganization open={modalOpen} onClose={closeModal}/>
  </Box>
}