import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Hanko, UnauthorizedError } from "@teamhanko/hanko-frontend-sdk";
import { Box, Button, Typography } from "@mui/material";
import { HankoDialog } from "../components/HankoDialog";

export const UserWrapper = () => {
  const hanko = new Hanko(window.REACT_APP_LOGIN_PROVIDER_URL)
  const [dialogOpen, setDialogOpen] = useState(false)
  const navigate = useNavigate()
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)

  const checkLoginStatus = async () => {
    try {
      const sessionCheckResponse = await hanko.sessionClient.isValid()
      if (dialogOpen && sessionCheckResponse.is_valid) {
        setDialogOpen(false)
      } else if (!sessionCheckResponse.is_valid) {
        setDialogOpen(true)
        clearIntervalIfDefined()
      }
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        setDialogOpen(true)
        clearIntervalIfDefined()
      } else {
        console.log("Failed to get current user: " + e)
      }
    }
  }

  const clearIntervalIfDefined = () => {
    if (interval !== undefined) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }

  const navigateToLogin = () => {
    navigate("/login", { state: document.location.pathname + document.location.search })
  }

  useEffect(() => {
    interval.current = setInterval(checkLoginStatus, 10000)
    return () => {
      clearIntervalIfDefined()
    }
  })

  return <>
    <HankoDialog closeable={false} open={dialogOpen} onClose={() => {
    }}>
      <Box maxWidth="500px" sx={{ display: "flex", gap: "20px", flexDirection: 'column' }}>
        <Typography variant="h4">Session expired</Typography>
        <Typography variant="body1">Your session is expired. Please sign in again.</Typography>
        <Button variant="contained" fullWidth onClick={navigateToLogin}>Sign in</Button>
      </Box>
    </HankoDialog>
    <Outlet />
  </>

}