import { Await, useLoaderData, useNavigate, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { Organization } from "../../models/Organization";
import React, { useContext, useEffect, useState } from "react";
import { OrganizationUser } from "../../models/OrganizationUser";
import { ApiClient } from "../../client/ApiClient";
import {
  Alert,
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { SettingsHead } from "../../components/form/SettingsHead";
import { LoadingTable } from "../../components/LoadingTable";
import { PersonAdd } from "@mui/icons-material";
import { User } from "../../models/User";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { HankoDialog } from "../../components/HankoDialog";
import { PrivateInvitation } from "../../models/Invitation";
import { SnackbarContext } from "../../components/Snackbar/SnackbarProvider";
import { Subscription } from "../../models/Subscription";
import { Width } from "../../const/Width";
import { SubscriptionFeatureTooltip } from "../../components/Tooltip/Tooltip";

interface LoaderData {
  subscription: Subscription | null
}

export const Users = () => {
  const self = useRouteLoaderData("user") as User
  const organization = useOutletContext() as Organization
  const [loader, setLoader] = useState<Promise<[OrganizationUser[], PrivateInvitation[]]> | undefined>()
  const [modalState, setModalState] = useState<{ open: boolean, user: OrganizationUser } | undefined>(undefined)
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false)
  const loaderData = useLoaderData() as LoaderData
  const hasSubscription = loaderData.subscription !== null && loaderData.subscription.is_active
  const theme = useTheme()
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const navigate = useNavigate()
  const {error, success} = useContext(SnackbarContext)

  const removeUserFromOrganization = async (userId: string) => {
    try {
      await ApiClient.deleteUserFromOrganization(organization.id, userId)
      setModalState(undefined)
      if (userId === self.id) {
        navigate("/organizations")
      } else {
        setLoader(Promise.all([ApiClient.getOrganizationUsers(organization.id), ApiClient.listInvitations(organization.id)]))
      }
      success("Removed user successful")
    } catch (e) {
      error("Failed to remove user")
      console.log(e)
    }
  }

  const inviteUser = async (email: string) => {
    try {
      await ApiClient.inviteUser(organization.id, {
        email: email,
      })
      setInviteUserModalOpen(false)
      setLoader(Promise.all([ApiClient.getOrganizationUsers(organization.id), ApiClient.listInvitations(organization.id)]))
      success("Invited user successfully")
    } catch (e) {
      if (e instanceof Response && e.status === 409) {
        error("User is already a member of your organization")
      } else {
        error("Failed to invite user")
        console.log(e)
      }
    }
  }

  const revokeInvitation = async (invitation: PrivateInvitation) => {
    try {
      await ApiClient.revokeInvitation(invitation.organization.id, invitation.id)
      setLoader(Promise.all([ApiClient.getOrganizationUsers(organization.id), ApiClient.listInvitations(organization.id)]))
      success("Revoked invitation successfully")
    } catch (e) {
      error("Failed to revoke invitation")
      console.log()
    }
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  }

  useEffect(() => {
    setLoader(Promise.all([ApiClient.getOrganizationUsers(organization.id), ApiClient.listInvitations(organization.id)]))
  }, [organization])

  const inviteButton = (disabled: boolean) => <Button
    variant="contained"
    startIcon={<PersonAdd/>} sx={{
    height: '40px',
    width: matchesMdDown ? '100%' : '160px'
  }}
    onClick={() => setInviteUserModalOpen(true)}
    disabled={disabled}>Invite</Button>

  return <Box maxWidth={Width.NORMAL}>
    <Box display="flex" flexDirection={matchesMdDown ? "column" : "row"}
         sx={{
           mb: 3,
           gap: 3,
           alignItems: matchesMdDown ? "normal" : "end",
           justifyContent: 'space-between'
         }}>
      <SettingsHead title="Team" description="Manage your team members." sx={{mb: 0, flexGrow: 1}}
                    organization={organization} type="feature" plan="Pro"/>
      {hasSubscription ?
        inviteButton(false) :
        <SubscriptionFeatureTooltip>{inviteButton(true)}</SubscriptionFeatureTooltip>
      }

    </Box>
    {loader !== undefined ? <React.Suspense
      fallback={<LoadingTable columns={3} rowsPerPage={25}
                              onRowsPerPageChange={onRowsPerPageChange} disablePagination>
        <TableCell>Email</TableCell>
        <TableCell sx={{width: 0}}>Role</TableCell>
        <TableCell sx={{width: 0}}></TableCell>
      </LoadingTable>}>
      <Await resolve={loader} errorElement={<Box><Alert severity="error">Failed to load team members</Alert></Box>}>
        {(result: Awaited<OrganizationUser[] | PrivateInvitation[]>[]) => (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell sx={{width: 0}}>Role</TableCell>
                  <TableCell sx={{width: 0}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.map((r) => {
                  if (isUser(r)) {
                    return (r as OrganizationUser[]).map(user => {
                      return <TableRow key={user.id}>
                        <TableCell sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: {xs: 'calc(100vw - 255px)', sm: 'calc(100vw - 570px)'}
                        }}>{user.email}</TableCell>
                        <TableCell>{capitalizeFirstLetter(user.role.name)}</TableCell>
                        <TableCell><Button variant="text" color="error" disabled={user.role.id === 1} onClick={() => {
                          setModalState({
                            open: true,
                            user: user
                          })
                        }}>Remove</Button></TableCell>
                      </TableRow>
                    })
                  } else {
                    return (r as PrivateInvitation[]).map(invitation => {
                      return <TableRow key={invitation.id}>
                        <TableCell sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: {xs: 'calc(100vw - 255px)', sm: 'calc(100vw - 570px)'}
                        }}>{invitation.email} <Chip size="small" color="primary" label="Invited"
                                                    sx={{lineHeight: 1.5}}/></TableCell>
                        <TableCell>{capitalizeFirstLetter(invitation.role.name)}</TableCell>
                        <TableCell><Button variant="text" onClick={() => {
                          revokeInvitation(invitation)
                        }}>Revoke</Button></TableCell>
                      </TableRow>
                    })
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Await>
    </React.Suspense> : null}
    <ConfirmationModal open={modalState?.open ?? false} onClose={() => {
      setModalState(undefined)
    }} onConfirm={() => removeUserFromOrganization(modalState?.user.id ?? "")} title="Remove member"
                       description={`Are you sure you want to remove '${modalState?.user.email ?? ""}' from your organization?`}
                       buttonText="Remove"/>
    <InviteUserModal open={inviteUserModalOpen} onClose={() => {
      setInviteUserModalOpen(false)
    }} onInvite={inviteUser}/>
  </Box>
}

interface Props {
  open: boolean
  onClose: () => void
  onInvite: (email: string) => void
}

export const InviteUserModal = (props: Props) => {
  const {open, onClose, onInvite} = props
  const [state, setState] = useState<{ email: string }>({email: ""})

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onInvite(state.email)
    setState({email: ""})
  }

  const onModalClose = () => {
    onClose()
    setState({email: ""})
  }

  return <HankoDialog onClose={onClose} open={open}>
    <Box maxWidth="500px" sx={{display: 'flex', gap: '20px', flexDirection: 'column'}}>
      <Typography variant="h4">Invite new member</Typography>
      <form onSubmit={onSubmit}>
        <TextField name="email" label="Email" type="email" onChange={onChange} fullWidth value={state.email} autoFocus/>
        <Button variant="contained" type="submit" fullWidth disabled={state.email === ""}
                sx={{mt: 2, mb: 2}}>Invite</Button>
        <Button variant="text" fullWidth onClick={onModalClose}>Cancel</Button>
      </form>
    </Box>
  </HankoDialog>
}

const isUser = (t: OrganizationUser[] | PrivateInvitation[]): boolean => {
  return t.length > 0 && (t[0] as any).token === undefined
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
