import React, { useEffect } from 'react';
import { Box, CircularProgress, Toolbar } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { Await, Outlet, useLoaderData, useMatches, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { projectNavbarItems } from "../../components/Navbar/navbarItems";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
import { Organization } from "../../models/Organization";
import UserBar from "../../components/UserBar/UserBar";
import { ErrorPage } from "../ErrorPage";

export interface ContextType {
  project: Project
  organization: Organization
}

interface Props {
  navbarWidth: number,
}

function ProjectWrapper(props: Props) {
  const data = useLoaderData() as any
  const user = useRouteLoaderData("user") as User
  const organization = useOutletContext() as Organization
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [projectName, setProjectName] = React.useState<string | undefined>(undefined)
  const matches = useMatches()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    (data.project as Promise<Project>).then((p) => {
      if (matches.some((value) => value.id === "project_dashboard" || value.id === "project_index")) {
        setProjectName(undefined)
      } else {
        setProjectName(p.application_name)
      }
    })
  }, [data, matches])

  return (
    <Box sx={{display: 'flex'}}>
      <UserBar onMenuClick={handleDrawerToggle} navBarWidth={props.navbarWidth} projectName={projectName} projectType="hanko_project"/>
      <Box
        component="nav"
        sx={{width: {sm: props.navbarWidth}, flexShrink: {sm: 0}}}
        aria-label="projects"
      >
        <Navbar navbarItems={projectNavbarItems}
                organizations={user.organizations}
                activeOrganization={organization}
                mobileOpen={mobileOpen}
                onMobileClose={handleDrawerToggle}
                backButton={true}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: {xs: 3, sm: 6, xl: 12},
          py: 3,
          width: {xs: '100%', sm: `calc(100% - ${props.navbarWidth}px)`}
        }}
      >
        {/*This Toolbar is needed to push down the content the same as the user bar*/}
        <Toolbar/>
        <React.Suspense fallback={<CircularProgress color="success"/>}>
          <Await resolve={data.project}
                 errorElement={<ErrorPage title="Failed to load page" description="Try to reload the page."/>}>
            {(project: Awaited<Project>) => (
              <Outlet context={{project, organization}}/>
            )}
          </Await>
        </React.Suspense>
      </Box>
    </Box>
  );
}

export default ProjectWrapper;
