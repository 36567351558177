import { Project, UpdateProjectData } from "../../../../models/Project";
import React, { useState } from "react";
import { Box, Switch, useTheme } from "@mui/material";
import { SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  required: boolean
  acquire_on_registration: boolean
  acquire_on_login: boolean
}

export const General = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    required: !project.mfa.optional,
    ...project.mfa
  })

  const somethingChanged = () => {
    return project.mfa.enabled !== state.enabled ||
      !project.mfa.optional !== state.required ||
      project.mfa.acquire_on_registration !== state.acquire_on_registration ||
      project.mfa.acquire_on_login !== state.acquire_on_login
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      mfa: {
        ...project.mfa,
        enabled: state.enabled,
        optional: !state.required,
        acquire_on_registration: state.acquire_on_registration,
        acquire_on_login: state.acquire_on_login
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      ...project.mfa
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper
        sx={{
          borderBottom: `1px solid ${theme.palette.grey["900"]}`
        }}>
        <SettingsHead description="Enable Two-Factor Authentication (2FA) options for users." title="2FA" sx={{ mb: 1 }} minElementsVersion="1.1" />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Users must always have a 2FA method configured. 2FA onboarding prompts during registration or login cannot be skipped." title="Required" sx={{ mb: 1 }} size="small" minElementsVersion="1.1" />
        <Switch name="required" color="success" checked={state.required} onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to set up 2FA when creating a new account." title="Prompt during registration" sx={{ mb: 1 }} size="small" minElementsVersion="1.1" />
        <Switch name="acquire_on_registration" color="success" checked={state.acquire_on_registration} onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to set up 2FA when logging in if not already set up." title="Prompt during login" sx={{ mb: 1 }} size="small" minElementsVersion="1.1" />
        <Switch name="acquire_on_login" color="success" checked={state.acquire_on_login} onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>

      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}