import { Password, ProjectUser } from "../../../../models/ProjectUser";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React from "react";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import { MoreMenu } from "../../../../components/User/MoreMenu";


/**
 * PasswordTable shows the users password state. Although it is a table a user will only have one password.
 * But with a table it is easier to achieve the desired UI.
 * @param props
 * @constructor
 */
export const PasswordTable = (props: { user: ProjectUser }) => {
  const { user } = props

  return <UserAttributesTable size="medium" label="Password" head={undefined}
                              rows={user.password ? <PasswordRow password={user.password} /> : undefined}
                              // TODO: hanko admin API needs to support setting a password for a user
                              // action={{
                              //   label: "Set new password", action: () => {
                              //   }
                              // }}
                              emptyContent="No password set." />
}

const PasswordRow = (props: { password: Password }) => {
  const { password } = props
  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  })
  return <TableRow>
    <TableCell>Last changed: {dateFormat.format(new Date(password.updated_at))}</TableCell>
    <TableCell sx={{ width: 0 }}>
      {/* TODO: hanko admin API needs to support changing and removing a password for a user*/}
      {/*<MooreMenu>*/}
      {/*  <MenuItem>Change</MenuItem>*/}
      {/*  <MenuItem sx={{ color: 'red' }}>Delete</MenuItem> /!* TODO: use error color from theme*!/*/}
      {/*</MooreMenu>*/}
    </TableCell>
  </TableRow>
}
