import { Box, CircularProgress } from "@mui/material";
import { Organization } from "../../models/Organization";
import React, { useEffect } from "react";
import { Await, Outlet, useAsyncError, useLoaderData, useNavigate } from "react-router-dom";
import { ErrorComponent } from "../../components/ErrorComponent";

export const OrganizationLoaderWrapper = () => {
  const data = useLoaderData() as any

  return <React.Suspense
    fallback={<CircularProgress color="success"/>}
  >
    <Await resolve={data.organization} errorElement={<Error/>}>
      {(organization: Awaited<Organization>) => (
        <Outlet context={organization}/>
      )}
    </Await>
  </React.Suspense>
}

const Error = () => {
  const error = useAsyncError() as Response
  const navigate = useNavigate()

  console.log("Error", error)

  useEffect(() => {
    if (error.status === 403) {
      navigate("/organizations")
    }
  }, [error])

  return <Box sx={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}><ErrorComponent title="Something went wrong."
                     description="Unfortunately, the page could not be loaded. Try to reload the page."/></Box>
}