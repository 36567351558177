import { HankoDialog } from "../../../components/HankoDialog";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ApiClient } from "../../../client/ApiClient";
import React, { useEffect, useState } from "react";

interface Props {
  open: boolean
  onClose: () => void
  organizationId: string
  projectId: string
  jobId: string
}

export const UserImportLogModal = (props: Props) => {
  const {open, onClose, organizationId, projectId, jobId} = props
  const [logs, setLogs] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const loadLogs = async () => {
    setIsLoading(true)
    try {
      const logs = await ApiClient.getUserImportJobLogs(organizationId, projectId, jobId)
      setLogs(logs)
    } catch (e) {
      setLogs("Failed to load logs")
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadLogs()
  }, [])

  return <HankoDialog onClose={onClose} open={open}>
    <Box sx={{display: "flex", flexDirection: 'column', width: '100%', maxWidth: "500px", margin: "auto", maxHeight: '500px'}}>
      <Typography variant="h6" sx={{mt: 2.5, mb: 1}}>Import logs</Typography>
      {isLoading ? <CircularProgress color="info" size={16}/> : <span style={{whiteSpace: 'pre-line'}}>{logs}</span>}
    </Box>
  </HankoDialog>
}