import React from "react";
import { Help } from "@mui/icons-material";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface HelpProps {
  title: React.ReactNode
}

export const HankoHelpTooltip = ({title}: HelpProps) => {
  return <HankoTooltip title={title}><Help sx={{fontSize: '1rem'}}/></HankoTooltip>
}

interface SubscriptionProps {
  children: React.ReactElement<any, any>;
}

export const SubscriptionFeatureTooltip = ({children}: SubscriptionProps) => {
  return <HankoTooltip title="This feature is only available in Pro and Business plan, please upgrade."><span>{children}</span></HankoTooltip>
}

export const HankoTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip placement="bottom" {...props} classes={{popper: className}} enterTouchDelay={0} leaveTouchDelay={0}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#313945',
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(1) + " " + theme.spacing(1.5),
    maxWidth: "min(400px, 100vw)",
    borderRadius: '8px'
  },
}))