import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import hankoTheme from "./theme/HankoMaterialTheme";
import { SnackbarProvider } from "./components/Snackbar/SnackbarProvider";

function App() {
  return (
    <ThemeProvider theme={hankoTheme}>
      <SnackbarProvider>
        <CssBaseline/>
        <Outlet/>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
