import { Card, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode
  sx?: SxProps<Theme>
}

export const HankoSecondaryCard = (props: Props) => {
  const {sx} = props
  const theme = useTheme()
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const width = matchesSmDown ? 'calc(100vw - 48px)' : '336px'

  return <Card sx={{
    borderRadius: '16px',
    backgroundColor: "transparent",
    border: "1px solid",
    width: width,
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    p: 4,
    ...sx,
  }} elevation={0}>
    {props.children}
  </Card>
}