import React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";

interface LoadingTableProps {
  children: React.ReactNode
  columns: number
  rowsPerPage: number
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  disablePagination?: boolean
  size?: "small" | "medium"
}

export const LoadingTable = (props: LoadingTableProps) => {
  return <TableContainer>
    <Table size={props.size}>
      <TableHead>
        <TableRow>
          {props.children}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={props.columns}>
            <Box display="flex" justifyContent="center">
              <CircularProgress color="info"/>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
      {props.disablePagination && props.disablePagination!! ? null :
        <TableFooter>
          <TableRow>
            <TablePagination
              count={0}
              page={0}
              rowsPerPage={props.rowsPerPage}
              onRowsPerPageChange={props.onRowsPerPageChange}
              onPageChange={() => {
              }}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      }
    </Table>
  </TableContainer>
}