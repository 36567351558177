import { Box, styled } from "@mui/material";

export const SettingsOptionWrapper = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  mb: 3,
  gap: 3,
  alignItems: { xs: 'start', md: 'center' },
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', md: 'row' },
}))

export const SettingsSwitchOptionWrapper = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  mb: 3,
  gap: 3,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}))