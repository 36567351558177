import { useNavigate, useOutletContext } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { Width } from "../../../const/Width";
import { ChevronLeft } from "@mui/icons-material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { useDropzone } from "react-dropzone";
import { FormButtons } from "../../../components/form/FormButtons";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InfoIcon from '@mui/icons-material/Info';
import { UserImportJob } from "../../../models/UserImport";
import { ApiClient } from "../../../client/ApiClient";
import { ReactComponent as EllipseIcon } from '../../../assets/ellipse.svg'
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { UserImportLogModal } from "./UserImportLogModal";

export const UserImport = () => {
  const navigate = useNavigate()
  const {project, organization} = useOutletContext() as ContextType
  const [file, setFile] = useState<undefined | File>()
  const theme = useTheme()
  const [isUploading, setIsUploading] = useState(false)
  const [logState, setLogState] = useState<{ jobId: undefined | string, open: boolean }>({
    jobId: undefined,
    open: false
  })
  const {error, success} = useContext(SnackbarContext)
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const [importJobs, setImportJobs] = useState<UserImportJob[]>([])
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 52428800, // 50 MiB
    accept: {
      "application/json": ['.json'],
    }
  })

  const navigateBack = () => {
    navigate(-1)
  }

  const upload = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsUploading(true)

    const formData = new FormData()
    formData.set("users", file!!)
    try {
      await ApiClient.uploadUserImportFile(organization.id, project.id, formData)
      setFile(undefined)
      getJobs()
    } catch (e) {
      error("Failed to upload file")
      return
    } finally {
      setIsUploading(false)
    }
    success("File was successfully uploaded")
  }

  const clearIntervalIfDefined = () => {
    if (interval !== undefined) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }

  const getJobs = async () => {
    try {
      const importJobs = await ApiClient.listUserImportJobs(organization.id, project.id)
      setImportJobs(importJobs)
    } catch (e) {
      console.log("Failed to get import user jobs")
    }
  }

  useEffect(() => {
    getJobs()
    interval.current = setInterval(getJobs, 5000)
    return () => {
      clearIntervalIfDefined()
    }
  }, [])

  const schemaButtons = <>
    <Button variant="text" size="medium" startIcon={<SaveAltIcon/>}
            sx={{textAlign: 'left', width: 'fit-content'}} href="/examples/import_users.json" target="_blank" download>Sample
      file</Button>
    <Button variant="text" size="medium" startIcon={<SaveAltIcon/>}
            sx={{textAlign: 'left', width: 'fit-content'}}
            href={`https://raw.githubusercontent.com/teamhanko/hanko/backend/${project.api_version}/backend/json_schema/hanko.user_import.json`}
            target="_blank" download>Schema</Button>
  </>

  const tableHead = <>
    <TableCell>Import date (UTC)</TableCell>
    <TableCell sx={{width: 0}}>Status</TableCell>
    <TableCell sx={{width: 0}}></TableCell>
  </>

  return <Box maxWidth={Width.NORMAL} sx={{pt: 1}}>
    <Button variant="outlined" startIcon={<ChevronLeft/>} onClick={navigateBack}>Back</Button>
    <Box sx={{
      my: 5
    }}>
      <SettingsHead title="User import" description="Import existing users into this project."
                    sx={{mb: {xs: 1, sm: 3}}}/>
      <Box sx={{display: {xs: "flex", sm: "none"}, flexDirection: 'row', mb: 2}}>
        {schemaButtons}
      </Box>
      <form onSubmit={upload}>
        <Box sx={{
          borderRadius: "8px",
          border: "1px dashed #494949",
          background: "rgba(255, 255, 255, 0.05)",
          py: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          cursor: 'pointer',
          height: '130px',
          justifyContent: 'center',
        }} {...getRootProps()}>
          <input {...getInputProps()} />

          {file !== undefined ? <>
            <Typography sx={{
              border: '1px solid #7C8E9C',
              borderRadius: '12px',
              px: 3,
              py: 1,
              width: 'fit-content'
            }}>{file.name}</Typography>
            <Typography component="p" variant="caption" sx={{textAlign: 'center', color: 'rgba(255, 255, 255, 0.70)'}}>Upload
              a new file</Typography>
          </> : (isDragActive ?
            <Typography component="p" variant="caption" sx={{textAlign: 'center', color: 'rgba(255, 255, 255, 0.70)'}}>Drop
              the file here ...</Typography> :
            <>
              <Typography variant="body1" sx={{fontWeight: 500, textAlign: 'center'}}>Upload user data</Typography>
              <Typography component="p" variant="caption"
                          sx={{textAlign: 'center', color: 'rgba(255, 255, 255, 0.70)'}}>Drag
                & Drop or click to open file chooser.</Typography>
            </>)
          }
        </Box>
        <Box sx={{mt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Box sx={{display: {xs: "none", sm: "flex"}, flexDirection: 'column'}}>
            {schemaButtons}
          </Box>
          <FormButtons onCancel={() => {
            setFile(undefined)
          }} loading={isUploading} disabled={file === undefined} sx={{width: {xs: '100%', sm: 'fit-content'}}}
                       submitButtonText="Import"/>
        </Box>
      </form>
    </Box>
    <>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {tableHead}
            </TableRow>
          </TableHead>
          <TableBody>
            {importJobs.length !== 0 ? null : <TableRow>
              <TableCell colSpan={3} sx={{color: '#7C8E9C'}} size="medium">No recent user import
                jobs</TableCell>
            </TableRow>}
            {importJobs.map((job) => {
              const createdAt = new Intl.DateTimeFormat('default', {
                dateStyle: "medium",
                timeStyle: "medium"
              }).format(new Date(job.created_at))

              let icon: React.ReactNode
              let status: React.ReactNode
              switch (job.status) {
                case "success":
                  icon = <EllipseIcon style={{fill: theme.palette.success.main, marginRight: '16px'}}/>
                  status = "Completed"
                  break;
                case "failure":
                  icon = <EllipseIcon style={{fill: theme.palette.error.main, marginRight: '16px'}}/>
                  status = "Failed"
                  break;
                default:
                  icon = <CircularProgress color="info" size={16} sx={{
                    marginRight: '12px',
                    marginLeft: '-4px',
                    color: '#7C8E9C',
                  }}/>
                  status = <span style={{color: '#7C8E9C'}}>Running...</span>
              }

              return <TableRow key={job.id}>
                <TableCell>{createdAt}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{icon}{status}</TableCell>
                <TableCell onClick={() => {
                  setLogState({
                    jobId: job.id,
                    open: true
                  })
                }} sx={{cursor: 'pointer'}}><InfoIcon/></TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
    {logState.jobId !== undefined ? <UserImportLogModal open={logState.open} onClose={() => {
      setLogState({jobId: undefined, open: false})
    }} organizationId={organization.id} projectId={project.id} jobId={logState.jobId}/> : null}
  </Box>
}