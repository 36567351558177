import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Organization } from "../../../models/Organization";
import { Await, useLoaderData, useOutletContext } from "react-router-dom";
import { Project } from "../../../models/Project";
import ProjectsCard from "../../../components/ProjectsCard/ProjectsCard";
import { NewProjectModal } from "../../../components/NewProject/NewProjectModal";
import NewProjectsCard from "../../../components/ProjectsCard/NewProjectCard";
import { UpgradeCard } from "../../../components/ProjectsCard/UpgradeCard";
import { Width } from "../../../const/Width";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { Subscription } from "../../../models/Subscription";
import { ErrorPage } from "../../ErrorPage";
import { PasskeyProject } from "../../../models/PasskeyProject";

const ProjectsOverview = () => {
  const data = useLoaderData() as any
  const organization = useOutletContext() as Organization
  const [modalOpen, setModalOpen] = useState(false)
  const subscription: Subscription | null = data.subscription

  const openNewProjectModal = () => {
    setModalOpen(true)
  }

  const closeNewProjectModal = () => {
    setModalOpen(false)
  }

  const isSubscriptionActive = () => {
    return subscription !== null && subscription.is_active
  }

  return (
    <Box maxWidth={Width.WIDE}>
      <SettingsHead title="All projects"
                    description={isSubscriptionActive() ? null : "Projects will be paused after 7 days of inactivity."}
                    organization={organization} plan={isSubscriptionActive() ? "Pro" : "Starter"} type="plan"/>
      <Box sx={{
        display: 'flex',
        gap: '24px',
        flexWrap: 'wrap'
      }}>
        <React.Suspense fallback={<Skeleton variant="rounded" sx={{width: {xs: 'calc(100vw - 48px)', sm: '336px', height: '200px', borderRadius: '16px'}}}/>}>
          <Await resolve={Promise.all<Project[] | PasskeyProject[]>([data.projects, data.passkeyProjects])} errorElement={<ErrorPage title="Failed to load projects" description="Try to reload the page." />}>
            {(projects: Awaited<Project[] | PasskeyProject[]>[]) => (
              <>
               {
                  projects.map((p) => {
                    return p.map((it) => {
                      return <ProjectsCard key={it.id} project={it}/>
                    })
                })}
                {((projects[0].length + projects[1].length) >= organization.limit.max_projects) && organization.limit.max_projects > 0 ?
                  <UpgradeCard organization={organization}/> : <NewProjectsCard onClickFunc={openNewProjectModal}/>}
              </>
            )}
          </Await>
        </React.Suspense>
      </Box>
      <NewProjectModal open={modalOpen} onClose={closeNewProjectModal} organizationId={organization.id}/>
    </Box>
  )
}

export default ProjectsOverview