import { Project } from "../models/Project";
import { Organization } from "../models/Organization";
import { Box, Link, Typography } from "@mui/material";
import { ApiClient } from "../client/ApiClient";
import { useContext, useEffect, useRef, useState } from "react";
import { SnackbarContext } from "./Snackbar/SnackbarProvider";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { useRevalidator } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface Props {
  project: Project
  organization: Organization
  type: "list" | "data"
  dataType?: "user"
}

export const SuspendedInfoComponent = (props: Props) => {
  const {project, organization, type, dataType} = props
  const {error} = useContext(SnackbarContext)
  const revalidator = useRevalidator()
  const [loading, setLoading] = useState(false)
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)

  const reactivate = async () => {
    setLoading(true)
    try {
      await ApiClient.reactivateProject(organization.id, project.id)
    } catch (e) {
      error("Failed to reactivate project")
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loading) {
      interval.current = setInterval(checkProjectStatus, 5000)
    } else {
      clearIntervalIfDefined()
    }
    return clearIntervalIfDefined
  }, [loading])

  const clearIntervalIfDefined = () => {
    if (interval !== undefined && interval.current !== undefined) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }

  const checkProjectStatus = async () => {
    try {
      const status = await ApiClient.getProjectStatus(organization.id, project.id)
      if (status.ready_replicas > 0) {
        setLoading(false)
        revalidator.revalidate()
      }
    } catch (e) {
      console.log("failed to get project status: ", e)
    }
  }

  let suspensionText: string = ""
  switch (type) {
    case "list":
      suspensionText = "There are no entries available because the project is suspended. Reactivate the project to see all entries."
      break
    case "data":
      switch (dataType) {
        case "user":
          suspensionText = "The user could not be loaded because the project is suspended. Reactivate the project to see the user details."
          break
      }
      break
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'background.paper',
    borderRadius: 2,
    py: 6,
    px: 3
  }}>
    <DoDisturbIcon color="primary"/>
    <Typography variant="h6" sx={{mt: 1}}>Project suspended</Typography>
    <Box sx={{maxWidth: '400px', mt: 1}}>
      <Typography variant="body1">{suspensionText} To prevent your project from being suspended <Link
        href={`/organizations/${organization.id}/billing`}>upgrade now</Link>.</Typography>
    </Box>
    <LoadingButton variant="contained" onClick={reactivate} sx={{width: '160px', mt: 5}}
                   loading={loading}>Reactivate</LoadingButton>
  </Box>
}