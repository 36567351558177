import { Box, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { People, Person } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { HankoSecondaryCard } from "../ProjectsCard/HankoSecondaryCard";

interface Props {
  userCount: number
  variant: "default" | "green"
  label: string
  isLoading?: boolean
}

const UserCountCard = ({userCount, variant, label, isLoading = false}: Props) => {
  const theme = useTheme()
  const formatter = new Intl.NumberFormat('default', {notation: 'compact', compactDisplay: 'short'})
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const color = variant === "green" ? theme.palette.success.light : theme.palette.common.white
  const Icon = variant === "green" ? Person : People

  return (
    <HankoSecondaryCard sx={{
      p: 2,
      maxWidth: isXs ? 'calc(50% - 24px)' : '170px',
      height: 'auto'
    }} /*sx={{borderRadius: '16px', maxWidth: 550}} elevation={0}*/>
      <Box>
        <Grid2 container spacing={1} alignItems="center" sx={{p: 0.5}}>
          <Grid2>
            <Typography variant="h5" display="flex">
              <Icon sx={{color: color}} fontSize="inherit"/>
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="h5" sx={{color: color}}>
              {isLoading ? (<Skeleton width={40}/>) : formatter.format(userCount)}
            </Typography>
          </Grid2>
        </Grid2>
        <Typography variant="body1">
          {label}
        </Typography>
      </Box>
    </HankoSecondaryCard>
  )

}

export default UserCountCard