import { Box, Button, Radio, RadioGroup, Typography, useRadioGroup } from "@mui/material";
import React, { useState } from "react";
import { ProjectTypeLabel } from "../ProjectTypeLabel"
import { ProjectType } from "../../const/types";

interface Props {
  onProjectTypeSelected: (projectType: ProjectType) => void
}

export const ProjectTypeSelector = (props: Props) => {
  const [projectType, setProjectType] = useState<ProjectType | "">("")

  const handleProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value
    if (v === "hanko_project" || v === "passkey_project" || v === "") {
      setProjectType(v);
    }
  }

  return <Box margin="auto" maxWidth="920px" sx={{display: "flex", flexDirection: "column"}}>
    <Typography variant="h4" sx={{mt: 2.5, mb: 1}}>Select project type</Typography>
    <Typography variant="body1" sx={{mb: 2.5}}>Select the project type based on your requirements. This cannot be changed later, but you can always create another project.</Typography>

    <RadioGroup
      value={projectType}
      onChange={handleProjectTypeChange}
      sx={{
        flexDirection: {xs: "column", lg: "row"},
        gap: 3
      }}
    >
      <ProjectTypeCard projectType="hanko_project" title="Authentication and user management"
                       list={["Onboard and authenticate users with a range of login options, including passkeys, social logins, and enterprise SSO", "Customizable UI components for registration, login and the user profile", "Admin dashboard"]}
                       setProjectType={(pType) => setProjectType(pType)}/>
      <ProjectTypeCard projectType="passkey_project" title="Passkey infrastructure"
                       list={["Integrate passkeys into any app", "Supports passkey creation, authentication, and transaction use cases", "A managed FIDO2-certified WebAuthn server API"]}
                       setProjectType={(pType) => setProjectType(pType)}/>
    </RadioGroup>
    <Button disabled={projectType === ""} variant="contained"
            sx={{width: {xs: '100%', sm: '160px'}, alignSelf: 'end', mt: 5}}
            onClick={() => props.onProjectTypeSelected(projectType as ProjectType)}>Create project</Button>
  </Box>
}

interface ProjectTypeCardProps {
  projectType: ProjectType
  title: string
  list: string[]
  setProjectType: (projectType: ProjectType) => void
}

const ProjectTypeCard = (props: ProjectTypeCardProps) => {
  const {projectType, title, list, setProjectType} = props
  const radioGroup = useRadioGroup()

  return <Box sx={{
    padding: 4,
    paddingTop: 3,
    border: radioGroup?.value === projectType ? "1px solid #AEDFFF" : "1px solid #7C8E9C",
    borderRadius: 4,
    maxWidth: {xs: "100%", lg: "448px"},
    cursor: "pointer",
    "&:hover": {
      border: radioGroup?.value === projectType ? "1px solid #AEDFFF" : "1px solid #FFFFFF",
    },
  }} onClick={() => setProjectType(projectType)}>
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between'
    }}>
      <ProjectTypeLabel projectType={projectType} sx={{alignSelf: 'center'}}/>
      <Radio value={projectType} sx={{padding: 0}}/>
    </Box>
    <Typography variant="h6" sx={{mt: 3}}>{title}</Typography>
    <ul>
      {list.map((value) => <li>{value}</li>)}
    </ul>
  </Box>
}
