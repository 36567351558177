import { NavbarItems } from "./navbarItems";
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";


interface Props {
  item: NavbarItems
  routePrefix?: string
  onNavigate: () => void
}

export const NavbarItem = (props: Props) => {
  const matches = useMatches()
  const isInPath = () => {
    return matches.some((value) => value.id === item.routeId)
  }
  const {item, routePrefix, onNavigate} = props
  const [open, setOpen] = useState(isInPath())
  const navigate = useNavigate()
  const theme = useTheme()

  const matchesLastRoute = () => {
    return matches[matches.length - 1].id === item.routeId
  }

  const hasChildren = item.children?.length ?? 0 > 0
  const hasInvisibleChildren = (item.invisibleChildren ?? false)

  let expandIcons = null
  if (hasChildren && !hasInvisibleChildren) {
    expandIcons = open ? <ExpandLess/> : <ExpandMore/>
  }

  return <>
    <ListItem disablePadding onClick={() => {
      if (hasChildren && !hasInvisibleChildren) {
        setOpen(!open)
      } else {
        onNavigate()
        if (routePrefix) {
          navigate(routePrefix + "/" + item.route)
        } else {
          navigate(item.route)
        }
      }
    }}>
    <ListItemButton selected={hasInvisibleChildren ? isInPath() : matchesLastRoute()} sx={{pl: routePrefix !== undefined ? 0 : 2}}>
      <ListItemIcon sx={{color: isInPath() ? theme.palette.primary.main : ""}}>
        {item.icon}
      </ListItemIcon>
      <ListItemText primary={item.label} primaryTypographyProps={{color: isInPath() ? theme.palette.primary.main : ""}} />
      {expandIcons}
    </ListItemButton>
  </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      {hasInvisibleChildren ? null : item.children?.map((child, index) => (
        <NavbarItem key={child.id} item={child} routePrefix={routePrefix !== undefined ? routePrefix + "/" + item.route : item.route} onNavigate={onNavigate} />
      ))}
    </Collapse>
  </>
}