import React from "react";
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableProps,
  TableRow
} from "@mui/material";

interface ErrorTableProps {
  children: React.ReactNode
  columns: number
  rowsPerPage: number
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  disablePagination?: boolean
  size: TableProps['size']
  message: string
}

export const ErrorTable = (props: ErrorTableProps) => {
  const {children, columns, rowsPerPage, onRowsPerPageChange, disablePagination, size, message} = props
  return <TableContainer>
    <Table size={size}>
      <TableHead>
        {children}
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={columns}>
             <Alert variant="standard" severity="error">
               {message}
            </Alert>
          </TableCell>
        </TableRow>
      </TableBody>
      {disablePagination && disablePagination!! ? null :
        <TableFooter>
          <TableRow>
            <TablePagination
              count={0}
              page={0}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              onPageChange={() => {
              }}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      }
    </Table>
  </TableContainer>
}
