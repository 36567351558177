import { useLoaderData, useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { FormButtons } from "../../../components/form/FormButtons";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Width } from "../../../const/Width";
import { Subscription } from "../../../models/Subscription";
import { SubscriptionFeatureTooltip } from "../../../components/Tooltip/Tooltip";
import { ApiClient } from "../../../client/ApiClient";
import { CreateApiKeyResponse } from "../../../models/Project";
import { HankoDialog } from "../../../components/HankoDialog";
import CopyableTextField from "../../../components/CopyableTextField";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { LoadingButton } from "@mui/lab";

interface LoaderData {
  subscription: Subscription | null
}

interface State {
  admin_api_enabled: boolean
}

export const AdminApi = () => {
  const {project, organization} = useOutletContext() as ContextType
  const [loading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [state, setState] = useState<State>({admin_api_enabled: project.admin_api.enabled})
  const [newApiKey, setNewApiKey] = useState<CreateApiKeyResponse | undefined>(undefined)
  const revalidator = useRevalidator()
  const loaderData = useLoaderData() as LoaderData
  const {error, success} = useContext(SnackbarContext)
  const hasSubscription = loaderData.subscription !== null && loaderData.subscription.is_active

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value === "true", // the check is needed, because radio groups return strings
    })
  }

  const onEnableAdminApi = async (e: React.SyntheticEvent) => {
    setIsLoading(true)
    e.preventDefault()

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        enable_admin_api: state.admin_api_enabled
      })
      revalidator.revalidate()
      success("Settings updated successfully")
    } catch (e) {
      error("Failed to updated settings")
    }
    setIsLoading(false)
  }

  const createAPIKey = async (e: React.SyntheticEvent) => {
    setIsCreating(true)
    e.preventDefault()

    try {
      const response = await ApiClient.createApiKey(organization.id, project.id)
      revalidator.revalidate()
      setNewApiKey(response)
    } catch (e) {
      error("Failed to create new API key")
    }

    setIsCreating(false)
  }

  const deleteApiKey = async (apiKeyId: string) => {
    try {
      await ApiClient.deleteApiKey(organization.id, project.id, apiKeyId)
      revalidator.revalidate()
      success("API key deleted successfully")
    } catch (e) {
      error("Failed to delete API key")
    }

  }

  const onCancel = () => {
    setState({
      ...state,
      admin_api_enabled: project.admin_api.enabled
    })
  }

  return <Box maxWidth={Width.NORMAL} display="flex" flexDirection="column">
    <SettingsHead title="Admin API"
                  description={<span>Enable the Admin API to manage users, search audit logs, etc. See <Link
                    href="https://docs.hanko.io/api-reference/introduction" target="_blank">here</Link> for the endpoints that you can use.</span>}
                  type="feature"
                  plan="Pro"
                  organization={organization}
                  sx={{mb: 3}}/>

    <form onSubmit={onEnableAdminApi}>
      <RadioGroup row value={state.admin_api_enabled} onChange={onChange} name="admin_api_enabled">
        {hasSubscription ? <>
          <FormControlLabel control={<Radio/>} label="Enabled" value="true"/>
          <FormControlLabel control={<Radio/>} label="Disabled" value="false"/>
        </> : <>
          <SubscriptionFeatureTooltip>
            <FormControlLabel control={<Radio/>} label="Enabled" value="true" disabled/>
          </SubscriptionFeatureTooltip>
          <SubscriptionFeatureTooltip>
            <FormControlLabel control={<Radio/>} label="Disabled" value="false" disabled/>
          </SubscriptionFeatureTooltip>
        </>}
      </RadioGroup>
      <CopyableTextField copyableContent={`${project.api_url}/admin`} label="Admin API URL"
                         disabled={!project.admin_api.enabled} sx={{mt: 3}}/>
      <FormButtons onCancel={onCancel} loading={loading}
                   disabled={project.admin_api.enabled === state.admin_api_enabled}
                   sx={{mt: 5}}/>
    </form>
    <Table sx={{mt: 8}} size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{px: 0, py: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <SettingsHead title="API Keys" description={null} sx={{mb: 0, gap: 0}}/>
              <form onSubmit={createAPIKey}>
                <LoadingButton loading={isCreating} variant="contained" type="submit" sx={{width: '160px'}}
                               disabled={!project.admin_api.enabled || isCreating}>Create
                  API key</LoadingButton>
              </form>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {project.admin_api.api_keys.length === 0 ? <TableRow>
          <TableCell colSpan={2} sx={{color: '#7C8E9C'}}
                     size="medium">
            This project has no API keys.
          </TableCell>
        </TableRow> : null}
        {project.admin_api.api_keys.map((key) => {
          return <ApiKeyTableRow key={key} apiKey={key} deleteApiKey={deleteApiKey}/>
        })}
      </TableBody>
    </Table>
    <NewAPIKeyModal onClose={() => {
      setNewApiKey(undefined)
    }} apiKey={newApiKey ?? {id: "", secret: ""}} open={newApiKey !== undefined}/>
  </Box>
}

interface RowProps {
  apiKey: string
  deleteApiKey: (key: string) => Promise<void>
}

const ApiKeyTableRow = (props: RowProps) => {
  const {apiKey, deleteApiKey} = props
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteKey = async () => {
    await deleteApiKey(apiKey)
    setDeleteModalOpen(false)
  }

  return <>
    <TableRow>
      <TableCell>{apiKey}</TableCell>
      <TableCell sx={{width: 0}}><Button variant="text" color="error"
                                         onClick={() => {
                                           setDeleteModalOpen(true)
                                         }}>Delete</Button></TableCell>
    </TableRow>
    <ConfirmationModal buttonText="Delete API key" description="Do you really want to delete this API key?"
                       onClose={() => {
                         setDeleteModalOpen(false)
                       }} onConfirm={deleteKey} open={deleteModalOpen} title="Delete API key"/>
  </>
}

interface ModalProps {
  onClose: () => void
  apiKey: CreateApiKeyResponse
  open: boolean
}

const NewAPIKeyModal = (props: ModalProps) => {
  const {open, onClose, apiKey} = props
  return <HankoDialog onClose={onClose} open={open}>
    <Box maxWidth="500px" sx={{display: "flex", gap: "24px", flexDirection: 'column'}}>
      <Typography variant="h4">New API key</Typography>
      <Alert severity="warning" variant="outlined">The secret is only displayed here. There is no possibility to view it
        again later.</Alert>
      <CopyableTextField copyableContent={apiKey.id} label="ID"/>
      <CopyableTextField copyableContent={apiKey.secret} label="Secret"/>
      <Button variant="contained" fullWidth onClick={onClose} sx={{mt: 2, mb: 2}}>Ok</Button>
    </Box>
  </HankoDialog>
}