import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, Chip, CircularProgress, Link, Typography, useTheme } from "@mui/material";
import { Organization } from "../../../models/Organization";
import { Await, createSearchParams, useLoaderData, useOutletContext } from "react-router-dom";
import { ApiClient } from "../../../client/ApiClient";
import { OpenInNew } from "@mui/icons-material";
import { Subscription } from "../../../models/Subscription";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { LoadingButton } from "@mui/lab";
import { HankoSecondaryCard } from "../../../components/ProjectsCard/HankoSecondaryCard";

const Billing = () => {
  const data = useLoaderData() as any
  const organization = useOutletContext() as Organization
  const {error} = useContext(SnackbarContext)
  const [isPortalLoading, setIsPortalLoading] = useState(false)
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

  const getCheckoutSession = async () => {
    setIsCheckoutLoading(true)
    try {
      const location = encodeURIComponent(document.location.toString())
      const queryParams = createSearchParams({'success_url': location, 'cancel_url': location})
      const checkoutSession = await ApiClient.getStripeCheckoutSession(organization.id, queryParams)
      window.location.href = checkoutSession.url
    } catch {
      error("failed to upgrade")
      setIsCheckoutLoading(false)
    }
  }

  const getPortalSession = async () => {
    setIsPortalLoading(true)
    try {
      const location = encodeURIComponent(document.location.toString())
      const queryParams = createSearchParams({'return_url': location})
      const customerPortalSession = await ApiClient.getStripeCustomerPortalSession(organization.id, queryParams)
      window.location.href = customerPortalSession.url
    } catch {
      error("failed to get portal session")
      setIsPortalLoading(false)
    }
  }

  const isPlanActive = (plan: typeof plans[0], subscription: Subscription | null) => {
    if (plan.id === "pro" && subscription !== null && subscription.is_active) {
      return true
    } else if (plan.id === "free" && ((subscription !== null && !subscription.is_active) || subscription === null)) {
      return true
    }
    return false
  }

  useEffect(() => {
    setIsPortalLoading(false)
    setIsCheckoutLoading(false)
  }, [])

  return (
    <Box maxWidth="1062px">
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}>
        <SettingsHead title="Manage subscription"
                      description="Edit your billing details, download invoices and manage your subscription."
                      sx={{mb: {xs: 1, md: 0}}}/>
        <LoadingButton variant="contained" endIcon={<OpenInNew/>}
                       sx={{height: '40px', width: {xs: '100%', md: 'fit-content'}, whiteSpace: 'nowrap'}}
                       loading={isPortalLoading} onClick={getPortalSession}>Billing portal</LoadingButton>
      </Box>
      <React.Suspense
        fallback={<CircularProgress color="success"/>}
      >
        <Await resolve={data.subscription} errorElement={<div>Failed to load subscription</div>}>
          {(subscription: Awaited<Subscription | null>) => (
            <>
              <Box sx={{mt: 6, display: 'flex', gap: 3, flexWrap: 'wrap'}}>
                {plans.map(plan => {
                  return <PlanCard key={plan.title} {...plan}
                                   active={isPlanActive(plan, subscription)}
                                   loading={isCheckoutLoading && plan.id === "pro"}
                                   onClick={getCheckoutSession}/>
                })}
              </Box>
              <Box sx={{mt: 3, display: 'flex', gap: 3, flexWrap: 'wrap', alignItems: 'stretch'}}>
                {secondaryPlans.map(plan => {
                  return <SecondaryCard key={plan.title} {...plan}/>
                })}
              </Box>

            </>
          )}
        </Await>
      </React.Suspense>
    </Box>
  )
}

export default Billing

interface Props {
  active: boolean
  disabled: boolean
  title: string
  description: string
  buttonText: string
  selectable: boolean
  loading: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const PlanCard = (props: Props) => {
  const {active, disabled, title, description, buttonText, selectable, loading, onClick} = props
  const theme = useTheme()

  let button: React.ReactNode | undefined = undefined
  if (selectable) {
    button = <LoadingButton variant="contained" sx={{width: 'fit-content', mx: 'auto', px: 5}} onClick={onClick}
                            disabled={disabled} loading={loading}>{buttonText}</LoadingButton>
  }
  if (active) {
    button = <Chip label="CURRENT PLAN" color="primary" variant="outlined" sx={{mx: 'auto'}}/>
  }

  return <Card elevation={0}
               sx={{
                 width: {xs: '100%', md: '338px'},
                 height: '265px',
                 borderRadius: 4,
                 borderWidth: '1px',
                 borderStyle: 'solid',
                 borderColor: active ? theme.palette.primary.main : theme.palette.background.paper,
               }}>
    <CardContent sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: 4
    }}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2,}}>
        <Typography variant="h6" sx={{fontWeight: 700}}>{title}</Typography>
        <Typography variant="body1" sx={{fontWeight: 500}}>{description}</Typography>
        <Link href="https://hanko.io/pricing" variant="body1" underline="none" target="_blank" sx={{fontWeight: 500}}>Show
          all plan features</Link>
      </Box>
      {button}
    </CardContent>
  </Card>
}

interface SecondaryProps {
  title: string
  description: string
  linkText: string
  link: string
}

export const SecondaryCard = (props: SecondaryProps) => {
  const {title, description, link, linkText} = props

  return <HankoSecondaryCard
    sx={{borderColor: 'rgba(152, 204, 255, 0.34)', height: 'unset', p: 5, width: {xs: '100%', md: '338px'}}}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, height: '100%', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <Typography variant="h6" sx={{fontWeight: 700}}>{title}</Typography>
        <Typography variant="body1" sx={{fontWeight: 400}}>{description}</Typography>
      </Box>
      <Link href={link} variant="body1" underline="none" target="_blank" sx={{fontWeight: 500}}>{linkText}</Link>
    </Box>
  </HankoSecondaryCard>
}

const plans = [
  {
    id: "free",
    title: "Starter",
    description: "Free forever",
    buttonText: "",
    disabled: false,
    selectable: false
  },
  {
    id: "pro",
    title: "Pro",
    description: "$29 / month + $0.01 per MAU",
    buttonText: "Upgrade",
    disabled: false,
    selectable: true
  }
]

const secondaryPlans = [
  {
    title: "Enterprise",
    description: "You have advanced requirements? We’re happy to help!",
    linkText: "Schedule a call",
    link: "https://cal.com/team/hanko/sales"
  }
]