import { Box, Collapse, FormControl, Link, TextField, ToggleButtonGroup, Typography } from "@mui/material";
import { SettingsHead } from "../form/SettingsHead";
import { TransitionGroup } from "react-transition-group";
import { FormButtons } from "../form/FormButtons";
import React, { useContext, useState } from "react";
import { ipAddressRegex } from "../../const/regex";
import { ApiClient } from "../../client/ApiClient";
import { PasskeyProjectApiClient } from "../../client/PasskeyProjectApiClient";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../Snackbar/SnackbarProvider";
import { ProjectTypeLabel } from "../ProjectTypeLabel";
import { ProjectType } from "../../const/types";

interface Props {
  projectType: ProjectType
  organizationId: string
  onBack: () => void
}

export const NewProjectForm = (props: Props) => {
  const {projectType, organizationId, onBack} = props
  const navigate = useNavigate()
  const [nameError, setNameError] = useState<string | undefined>()
  const [urlError, setUrlError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const {error} = useContext(SnackbarContext)
  const [showMore, setShowMore] = useState(false)

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const target = e.target as typeof e.target & {
      project_name: { value: string }
      project_url: { value: string }
    }

    const projectName = target.project_name.value.trim()
    const projectUrl = target.project_url.value.trim()

    let newNameError: string | undefined = undefined
    let newUrlError: string | undefined = undefined

    if (projectName.length === 0) {
      newNameError = "name must not be empty"
    }

    try {
      const url = new URL(projectUrl)
      if (url.hostname === "") {
        newUrlError = "URL is missing host"
      } else if (ipAddressRegex.test(url.hostname.replace("[", "").replace("]", ""))) {
        newUrlError = "URL must not contain an ip address"
      }
    } catch (e) {
      newUrlError = "URL is not a valid url"
    }

    if (newNameError || newUrlError) {
      setNameError(newNameError)
      setUrlError(newUrlError)
      setIsLoading(false)
      return
    }

    try {
      if (projectType === "hanko_project") {
        const project = await ApiClient.createProject(props.organizationId, projectName, projectUrl, false)
        navigate(`${project.id}/dashboard`)
      } else {
        const project = await PasskeyProjectApiClient.createProject(props.organizationId, projectName, projectUrl)
        navigate(`../passkey_projects/${project.id}/dashboard`)
      }
    } catch (e) {
      error("Failed to create project")
    }
    setIsLoading(false)
  }

  const showMoreLessButton = <Link
    href="#"
    underline="hover"
    onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</Link>

  const onBackLocal = () => {
    setNameError(undefined)
    setUrlError(undefined)
    setShowMore(false)
    onBack()
  }

  return <Box margin="auto" maxWidth="500px" sx={{display: "flex", flexDirection: 'column'}}>
    <ProjectTypeLabel projectType={projectType} sx={{alignSelf: 'center'}}/>
    <Typography variant="h4" sx={{mt: 4, mb: 1}}>{texts[projectType].title}</Typography>
    <Typography variant="body1" sx={{mb: 2.5}}>{texts[projectType].description}</Typography>
    <form onSubmit={onSubmit}>
      <FormControl fullWidth focused>
        <SettingsHead title="Project name"
                      description={texts[projectType].projectNameDescription}
                      sx={{mb: 0}}/>
        <TextField label="Name" name="project_name" autoFocus error={nameError !== undefined}
                   placeholder="E.g. Example App"
                   required margin="normal"
                   InputLabelProps={{
                     shrink: true
                   }}/>
        <SettingsHead title="App URL"
                      description={
                        <TransitionGroup>
                          {
                            !showMore ?
                              <Collapse key="text_1">
                                {texts[projectType].applicationUrlShortDescription} {showMoreLessButton}
                              </Collapse> :
                              <Collapse key="text_2">
                                {texts[projectType].applicationUrlLongDescription} {showMoreLessButton}
                              </Collapse>
                          }
                        </TransitionGroup>
                      }
                      sx={{mt: 5, mb: 0}}/>
        <TextField label="App URL" type="url" name="project_url" error={urlError !== undefined}
                   placeholder="E.g. https://app.example.com"
                   required margin="normal"
                   InputLabelProps={{
                     shrink: true
                   }}/>
        <FormButtons onCancel={onBackLocal} loading={isLoading} disabled={false} sx={{mt: 5}} resetButtonText="Back"
                     submitButtonText="Create project"/>
      </FormControl>
    </form>
  </Box>
}

let texts = {
  "hanko_project": {
    title: "New Hanko project",
    description: "Create a new Hanko project. All settings can be changed later.",
    projectNameDescription: "The project name is used here in the Hanko Console and in the email subject for emails sent by Hanko (e.g. passcodes).",
    applicationUrlShortDescription: "The URL where your application runs and where you want to integrate the Hanko frontend components.",
    applicationUrlLongDescription: "The URL where your application runs and where you want to integrate the Hanko frontend components. A full URL without path is required (e.g. https://app.example.com or http://localhost:8080). An IP address is not allowed. Be sure to add the port if it is not 80 or 443. Note: Passkeys only work in a secure context. That is either an application served over https or on localhost.",
  },
  "passkey_project": {
    title: "New Passkey API project",
    description: "Create a new Passkey API project. All settings can be changed later.",
    projectNameDescription: "The project name is used here in the Hanko Console and as an identifier for the passkey credential.",
    applicationUrlShortDescription: "The URL where your application runs and where the passkeys will be bound to.",
    applicationUrlLongDescription: "The URL where your application runs and where the passkeys will be bound to. A full URL without path is required (e.g. https://app.example.com or http://localhost:8080). An IP address is not allowed. Be sure to add the port if it is not 80 or 443. Note: Passkeys only work in a secure context. That is either an application served over https or on localhost.",
  }
}
