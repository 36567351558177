import { SettingsHead } from "../../../../components/form/SettingsHead";
import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  useTheme
} from "@mui/material";
import { FormButtons } from "../../../../components/form/FormButtons";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { Project, UpdateProjectData } from "../../../../models/Project";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  required: boolean
  recovery: boolean
  minLength: number
  acquireOnRegistration: "always" | "conditional" | "never"
  acquireOnLogin: "always" | "conditional" | "never"
}

export const Password = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    enabled: project.password.enabled ?? false,
    required: !(project.password.optional ?? false),
    recovery: project.password.recovery ?? false,
    minLength: project.password.min_length,
    acquireOnRegistration: project.password.acquire_on_registration,
    acquireOnLogin: project.password.acquire_on_login
  })

  const somethingChanged = () => {
    return (project.password.enabled ?? false) !== state.enabled ||
      !(project.password.optional ?? false) !== state.required ||
      (project.password.recovery ?? false) !== state.recovery ||
      project.password.min_length !== state.minLength ||
      project.password.acquire_on_registration !== state.acquireOnRegistration ||
      project.password.acquire_on_login !== state.acquireOnLogin
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else if (e.target.type === "number") {
      setState({
        ...state,
        [e.target.name]: e.target.valueAsNumber,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSelectChange = (e: SelectChangeEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      password: {
        ...project.password,
        enabled: state.enabled,
        optional: !state.required,
        recovery: state.recovery,
        min_length: state.minLength,
        acquire_on_registration: state.acquireOnRegistration,
        acquire_on_login: state.acquireOnLogin
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: project.password.enabled ?? false,
      required: !(project.password.optional ?? false),
      recovery: project.password.recovery ?? false,
      minLength: project.password.min_length,
      acquireOnRegistration: project.password.acquire_on_registration,
      acquireOnLogin: project.password.acquire_on_login
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead title="Passwords"
                      description="Allow users to create and use passwords to authenticate."
                      sx={{ mb: 1 }} />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead title="Required" description="Users must create a password and cannot delete it." size="small"
                      sx={{ mb: 0 }}
                      minElementsVersion="1.0" />
        <Switch name="required" color="success" checked={state.required} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead title="Recovery" description="Set whether passwords can be recovered with an email passcode."
                      size="small" sx={{ mb: 0 }}
                      minElementsVersion="1.0" />
        <Switch name="recovery" color="success" checked={state.recovery} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Minimum password length"
                      description="Passwords must contain at least 8 characters. Select a higher value for increased security."
                      size="small" sx={{ mb: 0 }} />
        <TextField fullWidth label="Minimum password length" sx={{ maxWidth: { xs: '100%', md: '220px' } }}
                   type="number"
                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 6, max: 72 }}
                   name="minLength" size="small" onChange={onChange} disabled={!state.enabled}
                   value={state.minLength} />
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Prompt during registration"
                      description="Ask the user to create a password when creating a new account."
                      helpText="'Conditional' is linked to the passkey setting of the same name. If both are set to 'Conditional', the user can choose between passkey and password during registration. If only one credential type is set to 'Conditional', the user will not be prompted to create such a credential if a credential of other type has already been created."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.0" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="acquireOnRegistration">Prompt during registration</InputLabel>
          <Select labelId="acquireOnRegistration" id="acquire_on_registration_select"
                  name="acquireOnRegistration" label="Prompt during registration" onChange={onSelectChange}
                  value={state.acquireOnRegistration}>
            <MenuItem key="always" value="always">Always</MenuItem>
            <MenuItem key="conditional" value="conditional">Conditional</MenuItem>
            <MenuItem key="never" value="never">Never</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Prompt during login"
                      description="Ask the user to create a password when logging in if they do not already have one."
                      helpText="'Conditional' is linked to the passkey setting of the same name. If both are set to 'Conditional', the user can choose between passkey and password during login. If only one credential type is set to 'Conditional', the user will not be prompted to create such a credential if a credential of other type has already been created."
                      size="small" sx={{ mb: 0 }} minElementsVersion="1.0" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="acquireOnLogin">Prompt during login</InputLabel>
          <Select labelId="acquireOnLogin" id="acquire_on_login_select"
                  name="acquireOnLogin" label="Prompt during login" onChange={onSelectChange}
                  value={state.acquireOnLogin}>
            <MenuItem key="always" value="always">Always</MenuItem>
            <MenuItem key="conditional" value="conditional">Conditional</MenuItem>
            <MenuItem key="never" value="never">Never</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}


