import { useLoaderData, useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../ProjectWrapper";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { Width } from "../../../../const/Width";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import CopyableTextField from "../../../../components/CopyableTextField";
import { Project, SamlIdentityProvider } from "../../../../models/Project";
import { CheckCircleOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FormButtons } from "../../../../components/form/FormButtons";
import { ApiClient } from "../../../../client/ApiClient";
import { Organization } from "../../../../models/Organization";
import { CreateEnterpriseConnectionModal } from "./CreateEnterpriseConnectionModal";
import { LoadingButton } from "@mui/lab";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { SubscriptionFeatureTooltip } from "../../../../components/Tooltip/Tooltip";
import { Subscription } from "../../../../models/Subscription";

interface LoaderData {
  subscription: Subscription | null
}

export const EnterpriseSSO = () => {
  const {project, organization} = useOutletContext() as ContextType
  const [open, setOpen] = useState(false)
  const loaderData = useLoaderData() as LoaderData

  const onClose = () => {
    setOpen(false)
  }

  const openModal = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setOpen(true)
  }

  const isLimitReached = (organization.limit.enterprise_connections <= project.saml.identity_providers.length) && organization.limit.enterprise_connections !== 0
  const createButton = (disabled: boolean) => <Button variant="contained" type="submit" sx={{width: '160px'}}
                                                      disabled={disabled}>New connection</Button>
  const tableTitle = organization.limit.enterprise_connections === 0 ? "Connections" : `Connections (${project.saml.identity_providers.length}/${organization.limit.enterprise_connections})`

  return <Box maxWidth={Width.NORMAL}>
    <SettingsHead title="Enterprise connections"
                  description='Configure Enterprise connections using SAML. During sign in, users that enter an email address with a domain configured in an enterprise connection will automatically be redirected to the configured identity provider. Enterprise connections do not show up as a "Sign in with..." button in Hanko Elements.'/>
    <CopyableTextField copyableContent={project.api_url + "/saml/callback"} label="Rediect URL (read-only)"/>

    <Table size="small" sx={{mt: 10}}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3} sx={{px: 0, py: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <SettingsHead title={tableTitle}
                            description={null} sx={{mb: 0, gap: 0}}/>
              <form onSubmit={openModal}>
                {isLimitReached ?
                  <SubscriptionFeatureTooltip>{createButton(true)}</SubscriptionFeatureTooltip> : createButton(false)}
              </form>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {project.saml.identity_providers.length !== 0 ? null : <TableRow>
          <TableCell colSpan={3} sx={{color: '#7C8E9C'}} size="medium">No connections</TableCell>
        </TableRow>}
        {project.saml.identity_providers.map((samlProvider) => {
          return <SamlIdentityProviderRow key={samlProvider.id} provider={samlProvider} organization={organization}
                                          project={project}/>
        })}
      </TableBody>
    </Table>
    <CreateEnterpriseConnectionModal open={open} onClose={onClose} organization={organization} project={project} subscription={loaderData.subscription}/>
  </Box>
}

interface SamlIdentityProviderRowProps {
  provider: SamlIdentityProvider
  organization: Organization
  project: Project
}

const SamlIdentityProviderRow = (props: SamlIdentityProviderRowProps) => {
  const [open, setOpen] = useState(false)
  const {provider, organization, project} = props

  return <>
    <TableRow hover key={provider.domain} onClick={() => setOpen(!open)} sx={{cursor: 'pointer'}}>
      <TableCell sx={{borderBottom: 'unset', width: 0}}>
        <IconButton aria-label="expand row"
                    size="small"
                    disableRipple>
          {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
        </IconButton>
      </TableCell>
      <TableCell sx={{borderBottom: 'unset'}}>{provider.name}</TableCell>
      <TableCell sx={{borderBottom: 'unset', width: 0}}>
        {provider.enabled ?
          <Chip label="Enabled" color="success" variant="outlined" icon={<CheckCircleOutlined color="success"/>}
                sx={{fontWeight: 500}}/> :
          <Chip label="Disabled" color="primary" variant="outlined" sx={{width: '100%', fontWeight: 500}}/>}
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{py: 0}} colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{margin: 2}}>
            <SamlProviderSetting provider={provider} organization={organization} project={project}/>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

interface SamlProviderSettingProps {
  provider: SamlIdentityProvider
  organization: Organization
  project: Project
}

interface SamlProviderSettingState {
  enabled: boolean
  name: string
  domain: string
  metadata_url: string
  skip_email_verification: boolean
}

const SamlProviderSetting = (props: SamlProviderSettingProps) => {
  const {provider, organization, project} = props
  const {error, success} = useContext(SnackbarContext)
  const revalidator = useRevalidator()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<SamlProviderSettingState>({
    enabled: provider.enabled ?? false,
    name: provider.name,
    domain: provider.domain,
    metadata_url: provider.metadata_url,
    skip_email_verification: provider.skip_email_verification ?? false
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked
    })
  }

  const deleteSamlProvider = async () => {
    try {
      await ApiClient.deleteSamlIdentityProvider(organization.id, project.id, provider.id)
      revalidator.revalidate()
      success("Provider deleted successfully")
    } catch (e) {
      error("Failed to delete provider")
    }
  }

  const updateSamlProvider = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      await ApiClient.updateSamlIdentityProvider(organization.id, project.id, provider.id, {
        enabled: state.enabled,
        name: state.name,
        domain: state.domain,
        metadata_url: state.metadata_url,
        skip_email_verification: state.skip_email_verification
      })
      revalidator.revalidate()
      success("Provider updated successfully")
    } catch (e) {
      if (e instanceof Response && e.status === 400 && (await e.json()).message === "metadata url validation failed") {
        error("Metadata url validation failed")
      } else {
        error("Failed to update provider")
      }
    }
    setLoading(false)
  }

  const onCancel = () => {
    setState({
      enabled: provider.enabled ?? false,
      name: provider.name,
      domain: provider.domain,
      metadata_url: provider.metadata_url,
      skip_email_verification: provider.skip_email_verification ?? false
    })
  }

  const somethingChanged = () => {
    return state.enabled !== (provider.enabled ?? false) ||
      state.name !== provider.name ||
      state.domain !== provider.domain ||
      state.metadata_url !== provider.metadata_url ||
      state.skip_email_verification !== (provider.skip_email_verification ?? false)
  }

  return <>
    <form onSubmit={updateSamlProvider}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, mt: 3}}>
        <FormGroup>
          <FormControlLabel name="enabled" control={<Switch name="enabled" onChange={onChecked} checked={state.enabled}
                                                            color="success"/>} label="Enable provider"/>
        </FormGroup>
        <TextField name="name" label="Name" fullWidth type="text" onChange={onChange} value={state.name}
                   required={state.enabled} autoComplete="off"/>
        <TextField name="domain" label="Email domain" fullWidth type="text" onChange={onChange} value={state.domain}
                   required={state.enabled} autoComplete="off"/>
        <TextField name="metadata_url" label="Metadata URL" fullWidth type="url" onChange={onChange}
                   value={state.metadata_url} required={state.enabled} autoComplete="off"/>
        <FormGroup>
          <FormControlLabel name="skip_email_verification"
                            control={<Switch name="skip_email_verification" onChange={onChecked}
                                             checked={state.skip_email_verification} color="success"/>}
                            label="Skip email verification (if the SAML IdP does not specify email_verified)"/>
        </FormGroup>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column-reverse', md: 'row'},
        justifyContent: 'space-between',
        mt: 5,
        gap: 2
      }}>
        <LoadingButton loading={loading} color="error" onClick={() => setOpen(true)}>Delete connection</LoadingButton>
        <FormButtons onCancel={onCancel} loading={loading} disabled={!somethingChanged()}/>
      </Box>
    </form>
    <ConfirmationModal open={open} onClose={() => setOpen(false)} onConfirm={deleteSamlProvider}
                       title="Delete SAML connection"
                       description={`Are you sure you want to remove SAML connection '${provider.name}'?`}
                       buttonText="Delete connection"/>
  </>
}
