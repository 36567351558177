import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../Projects/ProjectWrapper";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { ipAddressRegex } from "../../../const/regex";
import { Box, Collapse, Link, TextField } from "@mui/material";
import { Width } from "../../../const/Width";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { TransitionGroup } from "react-transition-group";
import { FormButtons } from "../../../components/form/FormButtons";
import { DeleteProject } from "../../Projects/Settings/DeleteProject/DeleteProject";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { PasskeyProjectApiClient } from "../../../client/PasskeyProjectApiClient";
import { PasskeyProjectContextType } from "../PasskeyProjectWrapper";

interface State {
  project_name: string
  app_url: string
  projectNameError?: string
  appUrlError?: string
}

export const General = () => {
  const {project, organization} = useOutletContext() as PasskeyProjectContextType
  const [loading, setIsLoading] = useState(false)
  const [state, setState] = useState<State>({project_name: project.application_name, app_url: project.application_url})
  const revalidator = useRevalidator()
  const [open, setOpen] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const {error, success} = useContext(SnackbarContext)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e?: React.SyntheticEvent, checked?: boolean) => {
    e?.preventDefault()
    setIsLoading(true)

    try {
      const url = new URL(state.app_url)
      if (url.hostname === "") {
        setState({
          ...state,
          appUrlError: "missing host"
        })
        setIsLoading(false)
        return
      } else if (ipAddressRegex.test(url.hostname.replace("[", "").replace("]", ""))) {
        setState({
          ...state,
          appUrlError: "host must not contain ip address"
        })
        setIsLoading(false)
        return
      }
    } catch (e) {
      setState({
        ...state,
        appUrlError: "not a valid url"
      })
      setIsLoading(false)
      return
    }

    if (state.project_name.trim().length === 0) {
      setState({
        ...state,
        projectNameError: "not a valid project name"
      })
      setIsLoading(false)
      return
    }

    if (project.application_url !== state.app_url && !checked) {
      setIsLoading(false)
      setOpen(true)
      return
    }

    try {
      await PasskeyProjectApiClient.patchProject(organization.id, project.id, {
        application_name: state.project_name,
        application_url: state.app_url
      })
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }

    setState({
      ...state,
      projectNameError: undefined,
      appUrlError: undefined
    })
    setOpen(false)
    setIsLoading(false)
  }

  const onCancel = () => {
    setState({
      ...state,
      project_name: project.application_name,
      app_url: project.application_url
    })
  }

  const showMoreLessButton = <Link
    href="#"
    underline="hover"
    onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</Link>

  return <Box maxWidth={Width.NORMAL} display="flex" flexDirection="column">
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap={5}>
        <Box>
          <SettingsHead title="Project name"
                        description="The project name is used here in the console and as a associated name for the webauthn credential."/>
          <TextField fullWidth label="Project name" name="project_name" value={state.project_name} onChange={onChange}
                     error={state.projectNameError !== undefined}/>
        </Box>
        <Box>
          <SettingsHead title="App URL"
                        description={<>
                          <TransitionGroup>
                            {
                              !showMore ?
                                <Collapse key="text_1">
                                  The URL where your application runs and where you want to integrate passkeys. {showMoreLessButton}
                                </Collapse> :
                                <Collapse key="text_2">
                                  The URL where your application runs and where you want to integrate passkeys. A full URL without path is required (e.g. https://app.example.com or
                                  http://localhost:8080). An IP address is not allowed. Be sure to add the port if it is
                                  not 80 or 443. Note: Passkeys only work in a secure context. That is either an
                                  application served over https or on localhost. {showMoreLessButton}
                                </Collapse>
                            }
                          </TransitionGroup>
                        </>}/>
          <TextField fullWidth label="App URL" name="app_url" value={state.app_url} onChange={onChange}
                     error={state.appUrlError !== undefined}/>
        </Box>
      </Box>
      <FormButtons onCancel={onCancel} loading={loading}
                   disabled={state.project_name === project.application_name && state.app_url === project.application_url}
                   sx={{mt: 5}}/>
    </form>

    <DeleteProject organization={organization} project={project} sx={{mt: 15}} projectType="passkey_project"/>
    <ConfirmationModal open={open} onClose={() => {
      setOpen(false)
    }} onConfirm={() => {
      onSubmit(undefined, true)
    }} title="App URL change"
                       description="Are you sure you want to change the app URL? Your user's passkeys are bound to the domain of your app URL. Changing the app URL makes all existing passkeys unusable."
                       buttonText="Save"/>
  </Box>
}