import React from 'react';
import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { Outlet, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { NavbarItems } from "../../components/Navbar/navbarItems";
import { Organization } from "../../models/Organization";
import { User } from "../../models/User";
import UserBar from "../../components/UserBar/UserBar";

interface Props {
  navbarItems: NavbarItems[],
  navbarWidth: number,
}

function OrganizationWrapper(props: Props) {
  const user = useRouteLoaderData("user") as User
  const organization = useOutletContext() as Organization
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <UserBar onMenuClick={handleDrawerToggle} navBarWidth={props.navbarWidth}/>
      <Box
        component="nav"
        sx={{width: {sm: props.navbarWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders organization"
      >
        <Navbar navbarItems={props.navbarItems}
                organizations={user.organizations}
                activeOrganization={organization}
                mobileOpen={mobileOpen}
                onMobileClose={handleDrawerToggle}
                backButton={false}
        />
      </Box>
      <Box
        component="main"
        sx={{flexGrow: 1, px: {xs: 3, sm: 6, xl: 12}, py: 3, width: {sm: `calc(100% - ${props.navbarWidth}px)`}}}>
        {/*This Toolbar is needed to push down the content the same as the user bar*/}
        <Toolbar/>
        <Outlet context={organization}/>
      </Box>
    </Box>
  );
}

export default OrganizationWrapper;
