import { Button, Typography } from "@mui/material";
import { HankoSecondaryCard } from "./HankoSecondaryCard";

interface Props {
  onClickFunc: () => void,
}

const NewProjectsCard = (props: Props) => {
  return (
    window.REACT_APP_DISABLE_PROJECT_CREATION ?
      <HankoSecondaryCard>
        <Typography variant="body1">Project creation is temporarily disabled due to maintenance.</Typography>
        <Button sx={{width: 'fit-content', marginLeft: 'auto'}} variant="contained" onClick={props.onClickFunc} disabled>Create
          project</Button>
      </HankoSecondaryCard> :
      <HankoSecondaryCard>
        <Typography variant="body1">Create a new project</Typography>
        <Button sx={{width: 'fit-content', marginLeft: 'auto'}} variant="contained" onClick={props.onClickFunc}>Create
          project</Button>
      </HankoSecondaryCard>
  )
}

export default NewProjectsCard