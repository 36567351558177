import { ErrorComponent } from "../components/ErrorComponent";
import React from "react";
import { Card, useMediaQuery, useTheme } from "@mui/material";

interface Props {
  title: string
  description: string
}

export const ErrorPage = (props: Props) => {
  const theme = useTheme()
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const width = matchesSmDown ? 'calc(100vw - 48px)' : '336px'

  return <Card sx={{
    borderRadius: '16px',
    backgroundColor: "transparent",
    border: "1px solid #262B35",
    width: width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    p: 4,
    mt: 1
  }} elevation={0}>
    <ErrorComponent {...props} size="small" sx={{
      p: 0,
      textAlign: 'center',
    }}/>
  </Card>
}