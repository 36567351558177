import React from "react";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

export interface UserAttributesTableAction {
  label: string
  action: () => void
}

export interface UserAttributesTableProps {
  size?: "small" | "medium",
  label: string,
  head: React.ReactNode,
  rows: React.ReactNode,
  action?: UserAttributesTableAction,
  emptyContent: string,
}

export const UserAttributesTable = (props: UserAttributesTableProps) => {
  const { size, label, head, rows, action, emptyContent } = props
  return <Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography variant="h6">{label}</Typography>
      {action ? <Button variant="text" onClick={() => action?.action()}>+ {action?.label}</Button> : null}
    </Box>
    <Table size={size}>
      <TableHead>
        <TableRow>
          {head}
          {head === undefined ? <TableCell colSpan={100}></TableCell> : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
        {rows === undefined ? <TableRow>
          <TableCell colSpan={100} size="medium"><Typography variant="body2" sx={{
            color: '#7C8E9C'
          }}>{emptyContent}</Typography></TableCell>
        </TableRow> : null}
      </TableBody>
    </Table>
  </Box>
}

UserAttributesTable.defaultProps = {
  size: "small"
}
