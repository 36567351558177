import { Box, IconButton, InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import React, { useContext } from "react";
import { SnackbarContext } from "./Snackbar/SnackbarProvider";

interface Props {
  copyableContent: string
  label: string
  sx?: SxProps<Theme>
  disabled?: boolean
}

const CopyableTextField = (props: Props) => {

  const {error, success} = useContext(SnackbarContext)

  const tryCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(props.copyableContent)
      success("Copied to clipboard")
    } catch (e) {
      error("Failed to copy to clipboard")
    }
  }

  return (
    <Box sx={{...props.sx}}>
      <TextField
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position="end">
            <IconButton onClick={tryCopyUrl} disabled={props.disabled ?? false}>
              <ContentCopy/>
            </IconButton>
          </InputAdornment>
        }}
        InputLabelProps={{
          shrink: true
        }}
        label={props.label} defaultValue={props.copyableContent}
        disabled={props.disabled ?? false}
      />
    </Box>
  )
}

export default CopyableTextField