import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import {
  Box,
  Button,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField, Theme,
  Typography
} from "@mui/material";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import React, { useContext, useState } from "react";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { useRevalidator } from "react-router-dom";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { HankoDialog } from "../../../../components/HankoDialog";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  organization: Organization
  project: Project
  sx?: SxProps<Theme>
}

export const AllowedOrigins = (props: Props) => {
  const {organization, project, sx} = props
  const [open, setOpen] = useState(false)

  return <Box sx={{...sx}}>
    <Box
      sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: {xs: 2, md: 4}, alignItems: 'end', mb: 3, justifyContent: 'space-between'}}>
      <SettingsHead title="Allowed origins" description="Configure allowed CORS and passkey (WebAuthn) origins here. The app URL of this project will automatically be added. Additional origins must be registrable domain suffixes (subdomains) of the effective domain specified in the app URL." sx={{mb: 0}}/>
      <Button variant="contained" onClick={() => setOpen(true)}
              sx={{width: {xs: '100%', md: 'fit-content'}, minWidth: '160px'}} size="medium">Add origin</Button>
    </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <OriginsTableRow origin={project.application_url} deletable={false} organization={organization}
                           project={project}/>
          {project.additional_webauthn_origins?.map((key) => {
            return <OriginsTableRow key={key} origin={key} deletable={true} organization={organization}
                                    project={project}/>
          })}
        </TableBody>
      </Table>
    <AddOriginModal open={open} onClose={() => setOpen(false)} organizationId={organization.id} project={project}/>
  </Box>
}

const OriginsTableRow = (props: TableRowProps) => {
  const {organization, project, origin, deletable} = props
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const deleteOrigin = async (origin: string) => {
    let newOrigins: string[] = project.additional_webauthn_origins?.filter((value) => value != origin) ?? []

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        additional_webauthn_origins: newOrigins
      })
      revalidator.revalidate()
      success("Origin successful deleted")
    } catch (e) {
      error("Failed to delete origin")
    }
  }

  return <>
  <TableRow sx={{height: '53px'}}>
    <TableCell>{origin}</TableCell>
    <TableCell sx={{width: 0}}><Button variant="text" color="error"
                                       sx={{display: deletable ? 'block' : 'none'}}
                                       onClick={() => {
                                         setDeleteModalOpen(true)
                                       }}>Delete</Button>
    </TableCell>
  </TableRow>
  <ConfirmationModal buttonText="Delete origin" description="Are you sure you want to delete this origin?"
                     onClose={() => {
                       setDeleteModalOpen(false)
                     }} onConfirm={() => deleteOrigin(origin)} open={deleteModalOpen} title="Delete origin"/>
  </>
}

interface TableRowProps {
  origin: string
  deletable: boolean
  organization: Organization
  project: Project
}

const AddOriginModal = (props: ModalProps) => {
  const {open, onClose, organizationId, project} = props
  const [loading, setLoading] = useState(false)
  const [inputError, setInputError] = useState<undefined | string>(undefined)
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const close = () => {
    setInputError(undefined)
    setLoading(false)
    onClose()
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)

    const target = e.target as typeof e.target & {
      origin: { value: string }
    }

    const newOrigin = target.origin.value.trim()

    if (!newOrigin.startsWith("android:apk-key-hash:")) {
      try {
        if (!newOrigin.startsWith("http:") && !newOrigin.startsWith("https:")) {
          setInputError("Missing or wrong protocol")
          setLoading(false)
          return
        }
        const appUrl = new URL(project.application_url)
        const origin = new URL(newOrigin)

        if (!origin.hostname.endsWith(appUrl.hostname)) {
          setInputError("Origin is not a registrable domain suffix (subdomain) of the app URL.")
          setLoading(false)
          return
        }
      } catch (e) {
        setInputError("Origin is not a valid URL or Android APK key hash")
        setLoading(false)
        return
      }
    }

    try {
      const newOrigins = project.additional_webauthn_origins ?? []
      newOrigins.push(newOrigin)
      await ApiClient.updateProject(organizationId, project.id, {
        additional_webauthn_origins: newOrigins
      })
      revalidator.revalidate()
      success("Origin added successfully")
      setLoading(false)
      close()
    } catch (e) {
      error("Failed to add origin")
    }

    setLoading(false)
  }

  return <HankoDialog onClose={close} open={open}>
    <Box margin="auto" maxWidth="500px" sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="h4" sx={{mt: 2.5, mb: 1}}>Add origin</Typography>
      <Typography variant="body1" sx={{mb: 2.5}}>Must be a subdomain of the app URL <b>{project.application_url}</b> or
        a valid Android
        APK key hash.
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField label="Origin" name="origin" required margin="normal" error={inputError !== undefined}
                   helperText={inputError} fullWidth/>
        <FormButtons onCancel={close} loading={loading} disabled={false} sx={{mt: 5}}
                     submitButtonText="Save"/>
      </form>
    </Box>
  </HankoDialog>
}

interface ModalProps {
  open: boolean
  onClose: () => void
  organizationId: string
  project: Project
}
