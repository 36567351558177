import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import {
  Box,
  Button,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField,
  Theme,
  Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { useRevalidator } from "react-router-dom";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { HankoDialog } from "../../../../components/HankoDialog";
import { FormButtons } from "../../../../components/form/FormButtons";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

interface Props {
  organization: Organization
  project: Project
  sx?: SxProps<Theme>
}

export const AllowedRedirectUrls = (props: Props) => {
  const {organization, project, sx} = props
  const [open, setOpen] = useState(false)

  return <Box sx={{...sx}}>
    <Box
      sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: {xs: 2, md: 4}, alignItems: {xs: 'start', md: 'end'}, mb: 3, justifyContent: 'space-between'}}>
      <SettingsHead title="Allowed redirect URLs" description="Allowed URLs to which Hanko backend may redirect the user after successful authentication with the 3rd party provider." sx={{mb: 0}}/>
      <Button variant="contained" onClick={() => setOpen(true)}
              sx={{width: {xs: '100%', md: 'fit-content'}, minWidth: '160px'}} size="medium">Add redirect URL</Button>
    </Box>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {project.third_party.allowed_redirect_urls.length !== 0 ? null : <TableRow>
          <TableCell colSpan={2} sx={{color: '#7C8E9C'}} size="medium">No redirect URLs configured</TableCell>
        </TableRow> }
        {project.third_party.allowed_redirect_urls.map((key) => {
          return <RedirectUrlTableRow key={key} redirectUrl={key} deletable={true} organization={organization} project={project} />
        })}
      </TableBody>
    </Table>
    <AddRedirectUrlModal open={open} onClose={() => setOpen(false)} organizationId={organization.id} project={project} />
  </Box>
}

interface TableRowProps {
  redirectUrl: string
  deletable: boolean
  organization: Organization
  project: Project
}

const RedirectUrlTableRow = (props: TableRowProps) => {
  const {organization, project, redirectUrl, deletable} = props
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const deleteRedirectUrl = async (redirectUrl: string) => {
    let allowedRedirectUrls: string[] = project.third_party.allowed_redirect_urls?.filter((value) => value != redirectUrl) ?? []

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        third_party: {
          ...project.third_party,
          allowed_redirect_urls: allowedRedirectUrls
        }
      })
      revalidator.revalidate()
      success("Redirect URL successful deleted")
    } catch (e) {
      error("Failed to delete redirect URL")
    }
  }

  return <>
    <TableRow sx={{height: '53px'}}>
      <TableCell>{redirectUrl}</TableCell>
      <TableCell sx={{width: 0}}><Button variant="text" color="error"
                                         sx={{display: deletable ? 'block' : 'none'}}
                                         onClick={() => {
                                           setDeleteModalOpen(true)
                                         }}>Delete</Button>
      </TableCell>
    </TableRow>
    <ConfirmationModal buttonText="Delete Redirect URL" description="Are you sure you want to delete this redirect URL?"
                       onClose={() => {
                         setDeleteModalOpen(false)
                       }} onConfirm={() => deleteRedirectUrl(redirectUrl)} open={deleteModalOpen} title="Delete redirect URL"/>
  </>
}

interface ModalProps {
  open: boolean
  onClose: () => void
  organizationId: string
  project: Project
}

const AddRedirectUrlModal = (props: ModalProps) => {
  const {open, onClose, organizationId, project} = props
  const [loading, setLoading] = useState(false)
  const [inputError, setInputError] = useState<undefined | string>(undefined)
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const close = () => {
    setInputError(undefined)
    setLoading(false)
    onClose()
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)

    const target = e.target as typeof e.target & {
      redirect_url: { value: string }
    }

    const newRedirectUrl = target.redirect_url.value.trim()
    try {
      const allowedRedirectUrls = project.third_party.allowed_redirect_urls
      allowedRedirectUrls.push(newRedirectUrl)
      await ApiClient.updateProject(organizationId, project.id, {
        third_party: {
          ...project.third_party,
          allowed_redirect_urls: allowedRedirectUrls
        }
      })
      revalidator.revalidate()
      success("Redirect URL added successfully")
      setLoading(false)
      close()
    } catch (e) {
      error("Failed to add redirect URL")
    }

    setLoading(false)
  }

  return <HankoDialog onClose={close} open={open}>
    <Box margin="auto" maxWidth="500px" sx={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant="h4" sx={{mt: 2.5, mb: 1}}>Add Redirect URL</Typography>
      <Typography variant="body1" sx={{mb: 2.5}}>Add an url which Hanko is allowed to redirect to.
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField label="Redirect URL" name="redirect_url" required type="text" margin="normal" error={inputError !== undefined}
                   helperText={inputError} fullWidth/>
        <FormButtons onCancel={close} loading={loading} disabled={false} sx={{mt: 5}}
                     submitButtonText="Save"/>
      </form>
    </Box>
  </HankoDialog>
}
