import { useNavigate, useOutletContext } from "react-router-dom";
import React, { useContext } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Width } from "../../../const/Width";
import { Box, Button, Card, CardActionArea, IconButton, Stack, Step, StepLabel, Typography } from "@mui/material";
import { ContentCopy, KeyboardArrowRight } from "@mui/icons-material";
import { PasskeyProjectContextType } from "../PasskeyProjectWrapper"
import { IntegrationGuides } from "../../Projects/Dashboard/IntegrationGuides";
import { ReactComponent as JavascriptIcon } from "../../../assets/javascript.svg";
import { ReactComponent as NextJsIcon } from "../../../assets/nextjs.svg";
import { ActiveUsersChart } from "../../../components/Charts/ActiveUsersChart";

export const Dashboard = () => {
  const {project, organization} = useOutletContext() as PasskeyProjectContextType
  const {error, success} = useContext(SnackbarContext)
  const navigate = useNavigate()

  const steps = [
    {
      label: "Create API key",
      description: "You'll need the API key to use the Passkey API",
      linkText: "Create API key",
      link: "../settings/api_keys",
      internal: true
    },
    {
      label: "Integrate passkeys",
      description: "Find the right guide for your application stack in our Docs",
      linkText: "Docs",
      link: "https://docs.hanko.io/passkey-api",
      internal: false
    }
  ]

  const tryCopyUrl = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      success("Copied to clipboard")
    } catch (e) {
      error("Failed to copy to clipboard")
    }
  }

  return (
    <Grid2 container spacing={3} maxWidth={Width.WIDE}>
      <Grid2 xs={12}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
          <Typography variant="h4" sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{project.application_name}</Typography>
          <Typography variant="body1" sx={{
            color: '#7C8E9C',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{project.application_url}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} maxWidth={Width.NORMAL}>
        <Box sx={{
          border: '1.5px solid #98CCFF57',
          borderRadius: '16px',
          p: 4,
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: 'space-between',
          gap: 2
        }}>
          {steps.map((step, index) => {
            return <Step key={step.label} expanded active index={index}>
              <StepLabel>{step.label}</StepLabel>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, l: 1.5, pl: 2.5, pr: 1}}>
                {step.description}
                {step.internal ? <Button variant="contained" onClick={() => navigate(step.link)}
                                         sx={{width: '160px', borderRadius: 2}}>{step.linkText}</Button> :
                  <Button variant="contained" href={step.link} target="_blank"
                          sx={{width: '160px', borderRadius: 2}}>{step.linkText}</Button>}
              </Box>
            </Step>
          })}
        </Box>
      </Grid2>
      <Grid2 xs={12} sx={{mt: 2}}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, flexWrap: 'wrap', gap: 4}}>
          <Stack direction="column" flexGrow={1}>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'baseline', mt: 1}}
                 flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>API URL:</Typography>
              <Box sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
                wordBreak: 'break-word',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}>
                {project.api_url}
                <IconButton onClick={() => tryCopyUrl(project.api_url)} size="small"
                            sx={{alignSelf: 'center', ml: 1}}><ContentCopy fontSize="small"/></IconButton>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'baseline', mt: 1}}
                 flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>JWKS URL:</Typography>
              <Box sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
                wordBreak: 'break-word',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}>
                {project.api_url}/.well-known/jwks.json
                <IconButton onClick={() => tryCopyUrl(`${project.api_url}/.well-known/jwks.json`)} size="small"
                            sx={{alignSelf: 'center', ml: 1}}><ContentCopy fontSize="small"/></IconButton>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'baseline', mt: 1}}
                 flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>Tenant ID:</Typography>
              <Box sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
                wordBreak: 'break-word',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}>
                {project.id}
                <IconButton onClick={() => tryCopyUrl(project.id)} size="small"
                            sx={{alignSelf: 'center', ml: 1}}><ContentCopy fontSize="small"/></IconButton>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Grid2>
      <Grid2 xs={12}>
        <ActiveUsersChart projectType="passkey_project" organization={organization} project={project} size="large"/>
      </Grid2>
      <Grid2 xs={12} sx={{mt: 7}}>
        <Typography variant="h6">Integration guides</Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3, mt: 3}}>
          {integrationGuides.map((guide) => {
            return <Card key={guide.title} sx={{height: '67px', width: {xs: '100%', sm: '180px'}, borderRadius: 2}}>
              <CardActionArea sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                p: 2,
                justifyContent: 'space-between'
              }} href={guide.href} target="_blank">
                <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
                  {guide.icon}
                  <Typography variant="body1" sx={{fontWeight: 500}}>{guide.title}</Typography>
                </Box>
                <KeyboardArrowRight/>
              </CardActionArea>
            </Card>
          })}
        </Box>
      </Grid2>
    </Grid2>
  )
}

export const integrationGuides: IntegrationGuides[] = [
  {
    title: "Next.js",
    href: "https://docs.hanko.io/passkey-api/next-auth",
    icon: <NextJsIcon/>
  },
  {
    title: "Vanilla JS",
    href: "https://docs.hanko.io/passkey-api/js-sdk",
    icon: <JavascriptIcon/>
  },
]