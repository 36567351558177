import { PasskeyProject } from "../../models/PasskeyProject";
import { Organization } from "../../models/Organization";
import { Await, Outlet, useLoaderData, useMatches, useOutletContext, useRouteLoaderData } from "react-router-dom";
import { User } from "../../models/User";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Toolbar } from "@mui/material";
import UserBar from "../../components/UserBar/UserBar";
import Navbar from "../../components/Navbar/Navbar";
import { passkeyProjectNavbarItems } from "../../components/Navbar/navbarItems";
import { ErrorPage } from "../ErrorPage";

export interface PasskeyProjectContextType {
  project: PasskeyProject
  organization: Organization
}

interface Props {
  navbarWidth: number
}

function PasskeyProjectWrapper(props: Props) {
  const data = useLoaderData() as any
  const user = useRouteLoaderData("user") as User
  const organization = useOutletContext() as Organization
  const [mobileOpen, setMobileOpen] = useState(false)
  const [projectName, setProjectName] = React.useState<string | undefined>(undefined)
  const matches = useMatches()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    (data.project as Promise<PasskeyProject>).then((p) => {
      if (matches.some((value) => value.id === "passkey_project_dashboard" || value.id === "passkey_project_index")) {
        setProjectName(undefined)
      } else {
        setProjectName(p.application_name)
      }
    })
  }, [data, matches])

  return (
    <Box sx={{display: 'flex'}}>
      <UserBar onMenuClick={handleDrawerToggle} navBarWidth={props.navbarWidth} projectName={projectName} projectType="passkey_project"/>
      <Box
        component="nav"
        sx={{width: {sm: props.navbarWidth}, flexShrink: {sm: 0}}}
        aria-label="projects"
      >
        <Navbar navbarItems={passkeyProjectNavbarItems}
                organizations={user.organizations}
                activeOrganization={organization}
                mobileOpen={mobileOpen}
                onMobileClose={handleDrawerToggle}
                backButton={true}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: {xs: 3, sm: 6, xl: 12},
          py: 3,
          width: {xs: '100%', sm: `calc(100% - ${props.navbarWidth}px)`}
        }}
      >
        {/*This Toolbar is needed to push down the content the same as the user bar*/}
        <Toolbar/>
        <React.Suspense fallback={<CircularProgress color="success"/>}>
          <Await resolve={data.project}
                 errorElement={<ErrorPage title="Failed to load page" description="Try to reload the page."/>}>
            {(project: Awaited<PasskeyProject>) => (
              <Outlet context={{project, organization}}/>
            )}
          </Await>
        </React.Suspense>
      </Box>
    </Box>
  );
}

export default PasskeyProjectWrapper;