import { HankoDialog } from "../../../../components/HankoDialog";
import { Alert, Box, FormControlLabel, FormGroup, Switch, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormButtons } from "../../../../components/form/FormButtons";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import { useRevalidator } from "react-router-dom";
import { IncludedQuantity, Price, Subscription } from "../../../../models/Subscription";

interface Props {
  open: boolean
  onClose: () => void
  organization: Organization
  project: Project
  subscription: Subscription | null
}

interface State {
  name: string
  domain: string
  metadata_url: string
  skip_email_verification: boolean
}

export const CreateEnterpriseConnectionModal = (props: Props) => {
  const { open, onClose, organization, project, subscription } = props
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<State>({
    name: '',
    domain: '',
    metadata_url: '',
    skip_email_verification: false
  })
  const { error, success } = useContext(SnackbarContext)
  const revalidator = useRevalidator()
  const [price, setPrice] = useState<Price>()
  const [includedQuantity, setIncludedQuantity] = useState<IncludedQuantity>()

  useEffect(() => {
    if (subscription !== null && subscription !== undefined) {
      for (const includedQuantity of subscription.included_quantities) {
        if (includedQuantity.type === "enterprise_connections") {
          setIncludedQuantity(includedQuantity)
          break
        }
      }

      for (const priceItem of subscription.prices) {
        if (priceItem.feature === "enterprise_connections") {
          setPrice(priceItem)
          break
        }
      }
    }

  }, [subscription, open])

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)

    const found = props.project.saml.identity_providers.find((value) => {
      return value.domain === state.domain
    })

    if (found) {
      error("Connection already exists")
      setLoading(false)
      return
    }

    try {
      await ApiClient.createSamlIdentityProvider(organization.id, project.id, {
        enabled: true,
        name: state.name,
        domain: state.domain,
        metadata_url: state.metadata_url,
        skip_email_verification: state.skip_email_verification
      })
      revalidator.revalidate()
      success("Created new connection successfully")
      onCancel()
    } catch (e) {
      if (e instanceof Response && e.status === 400 && (await e.json()).message === "metadata url validation failed") {
        error("Metadata url validation failed")
      } else {
        error("Failed to create new connection")
      }
    }
    setLoading(false)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked
    })
  }

  const onCancel = () => {
    setState({
      name: '',
      domain: '',
      metadata_url: '',
      skip_email_verification: false
    })
    onClose()
  }

  return <HankoDialog onClose={onCancel} open={open} fullScreen>
    <Box sx={{ margin: 'auto', maxWidth: '500px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4">New SAML connection</Typography>
      <Typography sx={{ mt: 2.5, mb: 3 }}>Create a new SAML connection below.</Typography>
      {subscription !== undefined && (subscription?.is_active ?? false) && project.saml.identity_providers.length >= (includedQuantity?.quantity ?? 0) ?
        <Alert variant="outlined" severity="warning" sx={{ mb: 3 }}>Enterprise connections are a paid feature. The free
          limit ({includedQuantity?.quantity}) will be exceeded and your organization will be
          charged <b>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: price?.currency ?? "USD"
          }).format((price?.amount ?? 0) / 100,)} per month </b> for this connection.</Alert> : null
      }
      <form onSubmit={onSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField name="name" label="Name" fullWidth type="text" onChange={onChange} value={state.name}
                     required autoComplete="off" />
          <TextField name="domain" label="Domain" fullWidth type="text" onChange={onChange} value={state.domain}
                     required autoComplete="off" />
          <TextField name="metadata_url" label="Metadata URL" fullWidth type="url" onChange={onChange}
                     value={state.metadata_url} required autoComplete="off" />
          <FormGroup>
            <FormControlLabel name="skip_email_verification"
                              control={<Switch name="skip_email_verification" onChange={onChecked}
                                               checked={state.skip_email_verification} color="success" />}
                              label="Skip email verification (if the SAML IdP does not specify email_verified)" />
          </FormGroup>
        </Box>
        <FormButtons onCancel={onCancel} loading={loading} disabled={false} sx={{ mt: 5 }} />
      </form>
    </Box>
  </HankoDialog>
}