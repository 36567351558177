import React from "react";
import { ReactComponent as AngularIcon } from "../../../assets/angular.svg";
import { ReactComponent as NextJsIcon } from "../../../assets/nextjs.svg";
import { ReactComponent as ReactIcon } from "../../../assets/react.svg";
import { ReactComponent as SvelteIcon } from "../../../assets/svelte.svg";
import { ReactComponent as VueIcon } from "../../../assets/vue.svg";
import { ReactComponent as JavascriptIcon } from "../../../assets/javascript.svg";

export type IntegrationGuides = {
  title: string,
  href: string,
  icon?: React.ReactNode
}

export const integrationGuides: IntegrationGuides[] = [
  {
    title: "Angular",
    href: "https://docs.hanko.io/quickstarts/frontend/angular",
    icon: <AngularIcon/>
  },
  {
    title: "Next.js",
    href: "https://docs.hanko.io/quickstarts/fullstack/next",
    icon: <NextJsIcon/>
  },
  {
    title: "Vanilla JS",
    href: "https://docs.hanko.io/quickstarts/frontend/javascript",
    icon: <JavascriptIcon/>
  },
  {
    title: "React",
    href: "https://docs.hanko.io/quickstarts/frontend/react",
    icon: <ReactIcon/>
  },
  {
    title: "Svelte",
    href: "https://docs.hanko.io/quickstarts/frontend/svelte",
    icon: <SvelteIcon/>
  },
  {
    title: "Vue",
    href: "https://docs.hanko.io/quickstarts/frontend/vue",
    icon: <VueIcon/>
  },
]