import { IPasskeyProjectApiClient } from "./PasskeyProjectApiClient";
import { PasskeyProject, PasskeyProjectApiKey, UpdatePasskeyProjectData } from "../models/PasskeyProject";
import { AuditLog, AuditLogsWithTotalCount } from "../models/AuditLog";
import { wait } from "@testing-library/user-event/dist/utils";

export class MockPasskeyProjectApiClient implements IPasskeyProjectApiClient{
  createApiKey(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey> {
    return Promise.resolve({
      id: "5403f6e4-fc74-418b-8d47-672d75c11432",
      name: "Second API key",
      secret: "randomSecret",
      created_at: "2023-09-28T14:39:53.364407Z"
    });
  }

  createProject(organizationId: string, applicationName: string, applicationUrl: string): Promise<PasskeyProject> {
    return Promise.resolve(project_1);
  }

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any> {
    return Promise.resolve(undefined);
  }

  deleteProject(organizationId: string, projectId: string): Promise<any> {
    return Promise.resolve(undefined);
  }

  getApiKeys(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey[]> {
    return Promise.resolve([
      {
        id: "5403f6e4-fc74-418b-8d47-672d75c11432",
        name: "Initial API key",
        secret: "randomSecret",
        created_at: "2022-09-28T14:39:53.364407Z"
      },
      {
        id: "5e770df2-6307-481f-96df-4bc1c5bfa502",
        name: "Second API key",
        secret: "randomSecret2",
        created_at: "2023-09-28T14:39:53.364407Z"
      }
    ]);
  }

  getProject(request: Request, organizationId: string, projectId: string): Promise<PasskeyProject> {
    if (projectId === project_1.id) {
      return Promise.resolve(project_1)
    } else {
      return Promise.resolve(project_2)
    }
  }

  getProjects(request: Request, organizationId: string): Promise<PasskeyProject[]> {
    return Promise.resolve([project_1, project_2]);
  }

  patchProject(organizationId: string, projectId: string, data: UpdatePasskeyProjectData): Promise<PasskeyProject> {
    return Promise.resolve(project_1);
  }

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount> {
    return wait(2000).then(() => {
      const list: AuditLog[] = []
      const perPage = parseInt(queryParams.get("per_page") ?? "0", 10)
      for (let i = 0; i < perPage; i++) {
        list.push({
          id: crypto.randomUUID(),
          type: auditLogTypes[Math.floor(Math.random() * 17)],
          meta_http_request_id: crypto.randomUUID(),
          meta_source_ip: "127.0.0.1",
          meta_user_agent: "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
          actor_user_id: crypto.randomUUID(),
          actor_email: i % 3 === 0 ? undefined : "example@example.com",
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z"
        })
      }

      return Promise.resolve({logs: list, totalCount: list.length})
    })
  }
}

const project_1 = {
  id: "90383b28-75a0-48a6-808e-ea104d3d68a3",
  api_url: "https://passkeys.hanko.io/90383b28-75a0-48a6-808e-ea104d3d68a3",
  application_name: "Hanko Passkey API",
  application_url: "https://cloud.hanko.io",
  additional_webauthn_origins: []
}

const project_2 = {
  id: "4d5f78a0-4e14-4ced-a8d9-07487f3a8f00",
  api_url: "https://passkeys.hanko.io/4d5f78a0-4e14-4ced-a8d9-07487f3a8f00",
  application_name: "Hanko Passkey API STG",
  application_url: "https://cloud.stg.hanko.io",
  additional_webauthn_origins: []
}

const auditLogTypes = [
  "webauthn_registration_init_succeeded",
  "webauthn_registration_init_failed",
  "webauthn_registration_final_succeeded",
  "webauthn_registration_final_failed",
  "webauthn_authentication_init_succeeded",
  "webauthn_authentication_init_failed",
  "webauthn_authentication_final_succeeded",
  "webauthn_authentication_final_failed",
]