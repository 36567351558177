import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useState } from "react";
import { Box, TextField } from "@mui/material";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { FormButtons } from "../../../components/form/FormButtons";
import { DeleteProject } from "./DeleteProject/DeleteProject";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Width } from "../../../const/Width";

interface State {
  project_name: string
  projectNameError?: string
}

export const General = () => {
  const {project, organization} = useOutletContext() as ContextType
  const [loading, setIsLoading] = useState(false)
  const [state, setState] = useState<State>({project_name: project.application_name})
  const revalidator = useRevalidator()
  const [showMore, setShowMore] = useState(false)
  const {error, success} = useContext(SnackbarContext)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault()
    setIsLoading(true)

    if (state.project_name.trim().length === 0) {
      setState({
        ...state,
        projectNameError: "not a valid project name"
      })
      setIsLoading(false)
      return
    }

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        application_name: state.project_name,
      })
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }

    setState({
      ...state,
      projectNameError: undefined,
    })
    setIsLoading(false)
  }

  const onCancel = () => {
    setState({
      ...state,
      project_name: project.application_name,
    })
  }

  return <Box maxWidth={Width.NORMAL} display="flex" flexDirection="column">
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap={5}>
        <Box>
          <SettingsHead title="Project name"
                        description="The project name is used here in the console and in the email subject for emails sent by Hanko (e.g. passcodes)."/>
          <TextField fullWidth label="Project name" name="project_name" value={state.project_name} onChange={onChange}
                     error={state.projectNameError !== undefined}/>
        </Box>
      </Box>
      <FormButtons onCancel={onCancel} loading={loading}
                   disabled={state.project_name === project.application_name}
                   sx={{mt: 5}}/>
    </form>

    <DeleteProject organization={organization} project={project} sx={{mt: 15}} projectType="hanko_project"/>
  </Box>
}