import { HankoDialog } from "../../../components/HankoDialog";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import { FormButtons } from "../../../components/form/FormButtons";
import { ApiClient } from "../../../client/ApiClient";

interface Props {
  organizationId: string
  projectId: string
  open: boolean
  onClose: (success?: boolean) => void
}

export const CreateUserModal = (props: Props) => {
  const {organizationId, projectId, open, onClose} = props
  const {error, success} = useContext(SnackbarContext)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    setIsLoading(true)
    e.preventDefault()
    const target = e.target as typeof e.target & {
      email_address: { value: string }
      is_verified: { checked: boolean }
    }

    try {
      await ApiClient.createProjectUser(organizationId, projectId, target.email_address.value, target.is_verified.checked)
      success("User created")
      onClose(true)
    } catch (err) {
      if ((err as Response).status === 409) {
        error("User already exists")
      } else {
        error("Failed to create user")
      }
    }

    setIsLoading(false)
  }

  return <HankoDialog onClose={onClose} open={open} fullScreen>
    <Box margin="auto" maxWidth="500px" sx={{display: "flex", flexDirection: 'column', width: '100%'}}>
      <Typography variant="h4">New User</Typography>
      <Typography sx={{mt: 2.5, mb: 2.5}}>Add a new user by entering their email address.</Typography>
      <form onSubmit={onSubmit}>
        <FormControl fullWidth focused>
          <TextField type="email" required name="email_address" placeholder="example@example.com"
                     autoFocus label="Email Address"/>
          <FormGroup sx={{mt: 1}}>
            <FormControlLabel control={<Checkbox/>} label="Set email address as verified" name="is_verified"/>
          </FormGroup>
          <FormButtons onCancel={onClose} loading={isLoading} disabled={false} submitButtonText="Create new user"
                       sx={{mt: 5}}/>
        </FormControl>
      </form>
    </Box>
  </HankoDialog>
}