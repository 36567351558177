import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext } from "react";
import { Box } from "@mui/material";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Width } from "../../../const/Width";
import { Password } from "./Authentication/Password";
import { Passkey } from "./Authentication/Passkey";
import { UpdateProjectData } from "../../../models/Project";

export const Authentication = () => {
  const { project, organization } = useOutletContext() as ContextType
  const revalidator = useRevalidator()
  const { error, success } = useContext(SnackbarContext)

  const submit = async (data: UpdateProjectData) => {
    try {
      await ApiClient.updateProject(organization.id, project.id, data)
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: Width.NORMAL,
    gap: 5
  }}>
    <Password project={project} submit={submit} />
    <Passkey project={project} submit={submit} />
  </Box>
}