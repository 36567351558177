import { Chip } from "@mui/material";
import React from "react";

interface Props {
  version: string
}

export const VersionTag = (props: Props) => {
  return <Chip label={props.version} color="info" size="small" sx={{
    borderRadius: '6px',
    borderColor: '#AEDFFF',
    height: '20px',
    "& .MuiChip-label": { paddingX: '5px', color: 'white', fontSize: '0.625rem', paddingY: 0 }
  }} variant="outlined" />
}