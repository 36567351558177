import { SxProps, Theme } from "@mui/material";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import React, { useContext, useState } from "react";
import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import { ApiClient } from "../../../../client/ApiClient";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { DeleteComponent } from "../../../../components/DeleteComponent";
import { PasskeyProjectApiClient } from "../../../../client/PasskeyProjectApiClient";
import { GenericProject } from "../../../../const/types";

interface Props {
  organization: Organization
  project: GenericProject
  projectType?: "hanko_project" | "passkey_project"
  sx?: SxProps<Theme>
}

export const DeleteProject = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const {error} = useContext(SnackbarContext)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const deleteProject = async () => {
    try {
      if (props.projectType === "passkey_project") {
        await PasskeyProjectApiClient.deleteProject(props.organization.id, props.project.id)
      } else {
        await ApiClient.deleteProject(props.organization.id, props.project.id)
      }
      closeModal()
      navigate(`/organizations/${props.organization.id}/projects`)
    } catch (e) {
      error("failed to delete project")
    }
  }

  return <>
    <DeleteComponent onDelete={openModal} title="Delete project"
                     description="You can delete your project and all user data here." buttonText="Delete project"/>
    <ConfirmationModal open={modalOpen} onClose={closeModal} onConfirm={deleteProject} title="Delete project"
                       description={`Are you sure you want to delete '${props.project.application_name}'? All project data, users and settings will be deleted and cannot be restored.`}
                       buttonText="Delete project"/>
  </>
}