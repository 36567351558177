import { Project, UpdateProjectData } from "../../../../models/Project";
import { Box, Switch, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  allowAccountCreation: boolean
  allowAccountDeletion: boolean
}

export const SelfService = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    allowAccountCreation: project.allow_signup,
    allowAccountDeletion: project.allow_self_service_account_deletion,
  })

  const somethingChanged = () => {
    return project.allow_signup !== state.allowAccountCreation ||
      project.allow_self_service_account_deletion !== state.allowAccountDeletion
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      allow_signup: state.allowAccountCreation,
      allow_self_service_account_deletion: state.allowAccountDeletion
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      allowAccountCreation: project.allow_signup,
      allowAccountDeletion: project.allow_self_service_account_deletion,
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead description="Configure which actions can be done by users." title="Account self-service" sx={{ mb: 1 }} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Users can register a new account." helpText="When disabling self-service registration, we recommended to use the <hanko-login> element for your login page, since <hanko-auth> will still show the registration option." title="Allow Account creation" sx={{ mb: 1 }} size="small" />
        <Switch name="allowAccountCreation" color="success" checked={state.allowAccountCreation} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Users can delete their account. This setting also controls whether the 'Delete account' option is displayed in the <hanko-profile> element."
                      title="Allow account deletion" sx={{ mb: 1 }} size="small" />
        <Switch name="allowAccountDeletion" color="success" checked={state.allowAccountDeletion}
                onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}