import { useLoaderData, useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import { Subscription } from "../../../models/Subscription";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { Width } from "../../../const/Width";
import { SettingsHead } from "../../../components/form/SettingsHead";
import React, { useContext, useEffect, useState } from "react";
import { Webhook } from "../../../models/Webhooks";
import { SubscriptionFeatureTooltip } from "../../../components/Tooltip/Tooltip";
import { HankoDialog } from "../../../components/HankoDialog";
import { FormButtons } from "../../../components/form/FormButtons";
import { MoreHoriz } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface LoaderData {
  subscription: Subscription | null
  webhooks: Webhook[]
}

export const Webhooks = () => {
  const {project, organization} = useOutletContext() as ContextType
  const {subscription, webhooks} = useLoaderData() as LoaderData
  const theme = useTheme()
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const {error, success} = useContext(SnackbarContext)
  const revalidator = useRevalidator()

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isCreateOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState(false)
  const [updateWebhook, setUpdateWebhook] = useState<Webhook | undefined>(undefined)
  const [deleteWebhook, setDeleteWebhook] = useState<Webhook | undefined>(undefined)

  const deleteWh = async () => {
    try {
      await ApiClient.deleteWebhook(organization.id, project.id, {
        callback: deleteWebhook?.callback ?? ""
      })

      revalidator.revalidate()
      setDeleteWebhook(undefined)
      setDeleteModalOpen(false)
      success("Webhook delete successfully")
    } catch (e) {
      error("Failed to delete webhook")
    }
  }

  const hasSubscription = subscription !== null && subscription.is_active
  const createButton = (disabled: boolean) => <Button variant="contained" disabled={disabled}
                                                      onClick={() => setCreateOrUpdateModalOpen(true)}>Create
    webhook</Button>

  return <Box maxWidth={Width.NORMAL} display="flex" flexDirection="column">
    <Box display="flex" flexDirection={matchesMdDown && !hasSubscription ? "column" : "row"}
         sx={{
           mt: 1,
           mb: 3,
           gap: 3,
           alignItems: matchesMdDown && !hasSubscription ? "normal" : "start",
           justifyContent: 'space-between'
         }}>
      <SettingsHead title="Webhooks"
                    description="Define webhooks to get informed when something happens." sx={{my: 0}}
                    organization={organization} type="feature" plan="Pro"/>
      {hasSubscription ? createButton(false) :
        <SubscriptionFeatureTooltip>{createButton(true)}</SubscriptionFeatureTooltip>
      }
    </Box>
    <TableContainer>
      <Table sx={{maxWidth: '800px'}}>
        <TableHead>
          <TableRow>
            <TableCell>Callback</TableCell>
            <TableCell>Events</TableCell>
            <TableCell sx={{width: 0}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.length !== 0 ? null : <TableRow>
            <TableCell colSpan={4} sx={{color: '#7C8E9C'}}
                       size="medium">This project has no webhooks.</TableCell>
          </TableRow>}
          {webhooks.map((value) => {
            return <WebhookTableRow webhook={value} onUpdate={(webhook) => {
              setUpdateWebhook(webhook)
              setCreateOrUpdateModalOpen(true)
            }} onDelete={(webhook) => {
              setDeleteWebhook(webhook)
              setDeleteModalOpen(true)
            }}/>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <WebhookModal organizationId={organization.id} projectId={project.id} webhook={updateWebhook}
                  open={isCreateOrUpdateModalOpen} onClose={() => {
      setCreateOrUpdateModalOpen(false)
      setUpdateWebhook(undefined)
    }}/>
    <ConfirmationModal buttonText="Delete webhook" description="Are you sure you want to delete the webhook?"
                       onClose={() => {
                         setDeleteModalOpen(false)
                         setDeleteWebhook(undefined)
                       }} onConfirm={deleteWh} open={isDeleteModalOpen} title="Delete webhook"/>
  </Box>
}

interface Props {
  webhook: Webhook
  onUpdate: (webhook: Webhook) => void
  onDelete: (webhook: Webhook) => void
}

export const WebhookTableRow = (props: Props) => {
  const {webhook, onUpdate, onDelete} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <TableRow sx={{maxWidth: '800px'}}>
    <TableCell sx={{
      maxWidth: '400px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }}>{webhook.callback}</TableCell>
    <TableCell>{webhook.events?.join(", ")}</TableCell>
    <TableCell>
      <IconButton onClick={handleMenu}>
        <MoreHoriz/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          onUpdate(webhook)
          handleClose()
        }}>Edit</MenuItem>
        <MenuItem onClick={() => {
          onDelete(webhook)
          handleClose()
        }} sx={{color: 'red'}}>Delete</MenuItem>
      </Menu>
    </TableCell>
  </TableRow>
}

interface ModalProps {
  webhook?: Webhook
  onClose: () => void
  open: boolean
  organizationId: string
  projectId: string
}

interface State {
  callback: string
  events: string[]
}

export const WebhookModal = (props: ModalProps) => {
  const {open, onClose, webhook, organizationId, projectId} = props
  const {error, success} = useContext(SnackbarContext)
  const revalidator = useRevalidator()
  const [state, setState] = useState<State>({
    callback: webhook?.callback ?? "",
    events: webhook?.events ?? [],
  })
  const maxTagsShown = 2

  const update = async () => {
    try {
      await ApiClient.updateWebhook(organizationId, projectId, {
        old_callback: webhook?.callback ?? "",
        new_callback: state.callback,
        events: state.events
      })
      revalidator.revalidate()
      onClose()
      success("Webhook updated successfully")
    } catch (e) {
      error("Failed to update webhook")
    }
  }

  const create = async () => {
    try {
      await ApiClient.createWebhook(organizationId, projectId, {
        callback: state.callback,
        events: state.events
      })
      revalidator.revalidate()
      onClose()
      success("Webhook created successfully")
    } catch (e: any) {
      if (e instanceof Response && e.status === 409) {
        error("webhook already exists")
      } else {
        error("Failed to create webhook")
      }
    }
  }

  const createOrUpdate = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (webhook === undefined) {
      return create()
    } else {
      return update()
    }
  }

  useEffect(() => {
    if (open) {
      setState({
        callback: webhook?.callback ?? "",
        events: webhook?.events ?? []
      })
    } else {
      setState({
        callback: "",
        events: []
      })
    }
  }, [open]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onEventsChange = (event: React.SyntheticEvent, value: string[], reason: AutocompleteChangeReason) => {
    setState({
      ...state,
      events: value
    })
  }

  return <HankoDialog onClose={onClose} open={open}>
    <Box maxWidth="500px" sx={{display: "flex", gap: "20px", flexDirection: 'column', width: '500px'}}>
      <Typography variant="h4" sx={{mb: 2}}>{webhook !== undefined ? "Update webhook" : "Create webhook"}</Typography>
      <form onSubmit={createOrUpdate} style={{width: '100%'}}>
        <TextField name="callback" label="Callback URL" type="url" fullWidth onChange={onChange}
                   value={state.callback} required/>
        <Autocomplete
          multiple
          limitTags={maxTagsShown}
          id="multiple-limit-tags"
          options={eventTypes}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          size="medium"
          onChange={onEventsChange}
          value={state.events}
          renderOption={(props, option, {selected}) => {
            return (
              <li {...props}>
                <Checkbox
                  name="checkbox"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option}
              </li>
            )
          }}
          renderInput={(params) => {
            return (
              <TextField name="events" variant="outlined" {...params} placeholder="Events"/>
            )
          }}
          renderTags={(value, getTagProps) => {
            const numTags = value.length

            return <>
              {value.slice(0, maxTagsShown).map((option, index) => <Chip size="medium" key={index} label={option}
                                                                         onDelete={getTagProps({index}).onDelete}
                                                                         className={getTagProps({index: 0}).className}/>)}
              {numTags > maxTagsShown &&
                  <span style={{marginLeft: '4px'}}>{` +${numTags - maxTagsShown} more`}</span>}
            </>
          }}
          sx={{width: '100%', maxWidth: '500px', mt: 2}}
        />
        <FormButtons onCancel={onClose} loading={false} disabled={false} sx={{my: 5}}
                     submitButtonText={webhook !== undefined ? "Update" : "Create"}/>
      </form>
    </Box>
  </HankoDialog>
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const eventTypes = [
  "user",
  "user.create",
  "user.update",
  "user.delete",
  "user.update.email",
  "user.update.email.create",
  "user.update.email.primary",
  "user.update.email.delete",
  "email.send"
]
