import { Apps, CreditCard, Dashboard, Event, People, Settings } from "@mui/icons-material";

export type NavbarItems = {
  id: number
  icon?: JSX.Element
  label: string
  route: string
  routeId: string // is the id set in the index.tsx
  children?: NavbarItems[]
  invisibleChildren?: boolean
}

export const emptyNavbarItems = []

export const organizationNavbarItems: NavbarItems[] = [
  {
    id: 0,
    icon: <Apps />,
    label: 'All projects',
    route: 'projects',
    routeId: "organization_projects",
  },
  {
    id: 1,
    icon: <Settings />,
    label: "Organization",
    route: "settings",
    routeId: "organization_settings",
  },
  {
    id: 3,
    icon: <People />,
    label: "Team",
    route: "users",
    routeId: "organization_users"
  },
  {
    id: 2,
    icon: <CreditCard />,
    label: 'Billing',
    route: 'billing',
    routeId: "organization_billing",
  },
]

export const projectNavbarItems: NavbarItems[] = [
  {
    id: 0,
    icon: <Dashboard />,
    label: 'Dashboard',
    route: 'dashboard',
    routeId: "project_dashboard",
  },
  {
    id: 1,
    icon: <People />,
    label: 'Users',
    route: 'users',
    routeId: "project_users",
    invisibleChildren: true,
    children: [
      {
        id: 10,
        label: "Users",
        route: "users",
        routeId: "project_users_list"
      },
      {
        id: 11,
        label: "User Detail",
        route: "users/:userId",
        routeId: "project_user_detail"
      }
    ]
  },
  {
    id: 2,
    icon: <Event />,
    label: 'Events',
    route: 'events',
    routeId: "project_events",
  },
  {
    id: 3,
    icon: <Settings />,
    label: 'Settings',
    route: 'settings',
    routeId: "project_settings",
    children: [
      {
        id: 31,
        label: "User account",
        route: "user_account",
        routeId: "project_settings_user_account",
      },
      {
        id: 32,
        label: "Authentication",
        route: "authentication",
        routeId: "project_settings_authentication",
      },
      {
        id: 33,
        label: "2FA",
        route: "mfa",
        routeId: "project_settings_mfa",
      },
      {
        id: 34,
        label: "Session",
        route: "session",
        routeId: "project_settings_session"
      },
      {
        id: 35,
        label: "Social connections",
        route: "auth_provider",
        routeId: "project_auth_provider",
      },
      {
        id: 36,
        label: "Enterprise connections",
        route: "enterprise_sso",
        routeId: "project_enterprise_sso"
      },
      {
        id: 37,
        label: "Email delivery",
        route: "smtp",
        routeId: "project_settings_smtp",
      },
      {
        id: 38,
        label: "Webhooks",
        route: "webhooks",
        routeId: "project_settings_webhooks"
      },
      {
        id: 39,
        label: "URLs",
        route: "urls",
        routeId: "project_settings_urls",
      },
      {
        id: 41,
        label: "API Keys",
        route: "admin_api",
        routeId: "admin_api"
      },
      {
        id: 41,
        label: "General",
        route: "general",
        routeId: "project_settings_general",
      },
    ]
  },
]

export const passkeyProjectNavbarItems: NavbarItems[] = [
  {
    id: 0,
    icon: <Dashboard />,
    label: 'Dashboard',
    route: 'dashboard',
    routeId: "passkey_project_dashboard",
  },
  // {
  //   id: 1,
  //   icon: <People/>,
  //   label: 'Users',
  //   route: 'users',
  //   routeId: "passkey_project_users",
  //   invisibleChildren: true,
  //   children: [
  //     {
  //       id: 10,
  //       label: "Users",
  //       route: "users",
  //       routeId: "passkey_project_users_list"
  //     },
  //     {
  //       id: 11,
  //       label: "User Detail",
  //       route: "users/:userId",
  //       routeId: "passkey_project_user_detail"
  //     }
  //   ]
  // },
  {
    id: 2,
    icon: <Event />,
    label: 'Events',
    route: 'events',
    routeId: "passkey_project_events",
  },
  {
    id: 3,
    icon: <Settings />,
    label: 'Settings',
    route: 'settings',
    routeId: "passkey_project_settings",
    children: [
      {
        id: 30,
        label: "General",
        route: "general",
        routeId: "passkey_project_settings_general",
      },
      {
        id: 31,
        label: "Webauthn",
        route: "webauthn",
        routeId: "passkey_project_settings_webauthn",
      },
      {
        id: 32,
        label: "API Keys",
        route: "api_keys",
        routeId: "passkey_api_keys"
      }
    ]
  },
]