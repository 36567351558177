import React from "react";
import { LoadingTable } from "../LoadingTable";
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Await } from "react-router-dom";
import { AuditLogsWithTotalCount } from "../../models/AuditLog";
import { EventTableRow } from "./EventTableRow";
import { ErrorTable } from "../ErrorTable";

interface Props {
  loader: Promise<AuditLogsWithTotalCount> | undefined
  rowsPerPage: number
  page: number
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  noResultsFoundThroughSearch: boolean // should only be set, when the results where constructed through a search
  projectType: "hanko_project" | "passkey_project"
}

export const EventsTable = (props: Props) => {
  const {loader, rowsPerPage, page, onRowsPerPageChange, onPageChange, noResultsFoundThroughSearch, projectType} = props
  const theme = useTheme()
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const head = <>
    <TableCell>Event</TableCell>
    <TableCell>UserID</TableCell>
    <TableCell sx={{display: matchesLgDown ? 'none' : 'table-cell'}}>IP</TableCell>
    <TableCell sx={{display: matchesMdDown ? 'none' : 'table-cell'}}>Occurred at (UTC)</TableCell>
    <TableCell sx={{width: 0}}/>
  </>

  return loader !== undefined ?
    <React.Suspense
      fallback={<LoadingTable size="small"
                              columns={5}
                              rowsPerPage={rowsPerPage}
                              onRowsPerPageChange={onRowsPerPageChange}>
        {head}
      </LoadingTable>}>
      <Await resolve={loader}
             errorElement={
               <ErrorTable size="small"
                           message="Failed to load events."
                           columns={4}
                           rowsPerPage={rowsPerPage}
                           onRowsPerPageChange={onRowsPerPageChange}>{head}</ErrorTable>
      }>
        {(auditLogs: Awaited<AuditLogsWithTotalCount>) => (
          <>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {head}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditLogs.logs.length !== 0 ? null : <TableRow>
                    <TableCell colSpan={5} sx={{color: '#7C8E9C'}} size="medium">{noResultsFoundThroughSearch ? "No results found." : "No recent activity."}</TableCell>
                  </TableRow>}
                  {auditLogs.logs.map((auditLog) => {
                    return <EventTableRow key={auditLog.id} auditLog={auditLog} matchesLgDown={matchesLgDown}
                                          matchesMdDown={matchesMdDown} projectType={projectType}/>
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={auditLogs.totalCount}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={onRowsPerPageChange}
                      onPageChange={onPageChange}
                      showFirstButton
                      showLastButton
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </Await>
    </React.Suspense>
    : null
}
