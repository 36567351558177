import { SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { ProjectType } from "../const/types";

export const ProjectTypeLabel = (props: { projectType: ProjectType, sx?: SxProps<Theme> }) => {
  const {projectType, sx} = props
  const isHankoProject = projectType === "hanko_project"

  return <Typography variant="body2" color={!isHankoProject ? "primary" : "error"} sx={{
    height: 'fit-content',
    padding: "2px 12px",
    backgroundColor: '#3C4049',
    borderRadius: "16px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    ...sx
  }}>{isHankoProject ? "Hanko" : "Passkey API"}</Typography>
}