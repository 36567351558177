import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import { HankoDialog } from "./HankoDialog";


interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string
  buttonText: string
}

export const ConfirmationModal = (props: Props) => {
  const [confirmationChecked, setConfirmationChecked] = useState(false)
  const {title, description, buttonText} = props

  const onConfirmationChange = (_: any, checked: boolean) => {
    setConfirmationChecked(checked)
  }

  const onClose = () => {
    setConfirmationChecked(false)
    props.onClose()
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    props.onConfirm()
  }

  return <HankoDialog open={props.open} onClose={onClose}>
    <Box maxWidth="500px" sx={{display: "flex", gap: "20px", flexDirection: 'column'}}>
      <Typography variant="h4">{title}</Typography>
      <Typography>{description}</Typography>
      <form onSubmit={onSubmit}>
        <FormGroup>
          <FormControlLabel control={<Checkbox/>} label="Yes, I’m sure." onChange={onConfirmationChange}/>
        </FormGroup>
        <Button variant="contained" type="submit" fullWidth disabled={!confirmationChecked} sx={{mt: 2, mb: 2}}>{buttonText}</Button>
        <Button variant="text" fullWidth onClick={onClose}>Cancel</Button>
      </form>
    </Box>
  </HankoDialog>
}