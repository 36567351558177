import { Box, Button, FormControl, FormGroup, TextField, Typography } from "@mui/material";
import { ApiClient } from "../client/ApiClient";
import React, { useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import { HankoDialog } from "./HankoDialog";
import { LoadingButton } from "@mui/lab";

interface Props {
  open: boolean
  onClose: () => void
}

export const NewOrganization = (props: Props) => {
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const revalidator = useRevalidator()

  const onClose = () => {
    setError(undefined)
    props.onClose()
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    setIsLoading(true)
    e.preventDefault()
    const target = e.target as typeof e.target & {
      organization_name: { value: string }
    }
    const organizationName = target.organization_name.value.trim()
    if (organizationName.length === 0) {
      setError("name must not be empty")
    } else {
      try {
        const organization = await ApiClient.createOrganization(organizationName)
        revalidator.revalidate()
        navigate(`${organization.id}/projects`, {replace: true})
      } catch (e) {
        setError("")
      }
    }
    setIsLoading(false)
  }

  return <HankoDialog
    open={props.open}
    onClose={onClose}
    fullScreen
  >
    <Box margin="auto" maxWidth="500px" sx={{display: "flex", flexDirection: 'column'}}>
      <Typography variant="h4">New organization</Typography>
      <Typography sx={{mt: 2.5, mb: 5}}>Create a new organization to manage all your Hanko projects and your
        subscription in one
        place.</Typography>
      <form onSubmit={onSubmit}>
        <FormControl fullWidth focused>
          <Typography variant="h6">Organization name</Typography>
          <TextField sx={{mt: 2.5}} name="organization_name" placeholder="Organization name" autoFocus
                     error={error !== undefined}
                     helperText={error}/>
          <FormGroup row sx={{mt: 5, justifyContent: 'flex-end'}}>
            <Button disabled={isLoading} onClick={onClose}>Cancel</Button>
            <LoadingButton loading={isLoading} type="submit">Create organization</LoadingButton>
          </FormGroup>
        </FormControl>
      </form>
    </Box>
  </HankoDialog>
}