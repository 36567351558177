import React from "react";
import { Box, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

export interface ErrorComponentProps {
  size?: "small" | "normal"
  title: string
  description: string
  sx?: SxProps<Theme>
}

export const ErrorComponent = (props: ErrorComponentProps) => {
  const {size, title, description, sx} = props

  let iconFontSize = 42
  let titleVariant: TypographyProps['variant'] = "h4"
  let gap = 4

  if (size === "small") {
    iconFontSize = 27
    titleVariant = "h6"
    gap = 2
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: gap,
    maxWidth: '573px',
    p: 5,
    height: 'fit-content',
    ...sx
  }}>
    <ErrorOutline color="error" sx={{fontSize: iconFontSize, alignSelf: 'center'}}/>
    <Typography variant={titleVariant}>{title}</Typography>
    <Typography variant="body1">{description}</Typography>
  </Box>
}