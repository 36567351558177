import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
  useTheme
} from "@mui/material";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import React from "react";

export interface Props {
  title: string
  data: ChartData[]
  precision: "days" | "months"
  timeRange: "7" | "30" | "90" | "3" | "6" | "12"
  onPrecisionChange: (event: SelectChangeEvent<Props['precision']>) => void
  onTimeRangeChange: (event: SelectChangeEvent<Props['timeRange']>) => void
  isLoading: boolean
  size?: "small" | "medium" | "large"
  availablePrecisions?: Props["precision"][]
}

export type ChartData = {
  name: string
  count: number
}

export const ChartCard = (props: Props) => {
  const {
    title,
    data,
    precision,
    timeRange,
    onPrecisionChange,
    onTimeRangeChange,
    isLoading,
    size,
    availablePrecisions
  } = props
  const theme = useTheme()
  const options = {
    days: [
      <MenuItem key="7" value="7">Last 7 days</MenuItem>,
      <MenuItem key="30" value="30">Last 30 days</MenuItem>,
      <MenuItem key="90" value="90">Last 90 days</MenuItem>
    ],
    months: [
      <MenuItem key="3" value="3">Last 3 months</MenuItem>,
      <MenuItem key="6" value="6">Last 6 months</MenuItem>,
      <MenuItem key="12" value="12">Last 12 months</MenuItem>
    ]
  }

  const precisionOptions = {
    days: <MenuItem key="days" value="days">Days</MenuItem>,
    months: <MenuItem key="months" value="months">Months</MenuItem>
  }

  let p: Props["precision"][] = availablePrecisions ?? ["days", "months"]

  let precisionMenuItems: React.ReactNode[] = []
  for (const key of p) {
    precisionMenuItems.push(precisionOptions[key])
  }

  const s = size ?? "large"
  const height = {
    small: 200,
    medium: 300,
    large: 400
  }

  const tickFormatter = (value: string, index: number) => {
    const formatter = new Intl.NumberFormat('default', {notation: 'compact', compactDisplay: 'short'})
    return formatter.format(parseInt(value, 10))
  }

  return <Card sx={{borderRadius: '8px', flexGrow: 1}} elevation={0}>
    <CardContent>
      <Box>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, mt: 1, mb: 3}}>
        <FormControl size="small">
          <Select value={precision} onChange={onPrecisionChange}>
            {precisionMenuItems}
          </Select>
        </FormControl>
        <FormControl size="small">
          <Select value={timeRange} onChange={onTimeRangeChange}>
            {options[precision]}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {isLoading ?
          <Skeleton variant="rounded" height={height[s] + 28} width="100%"/> :
          <>
            <ResponsiveContainer height={height[s]} width="100%">
              <LineChart width={undefined} height={height[s]} data={data}>
                <Line type="monotone" dataKey="count" stroke={theme.palette.primary.main} strokeWidth={2}/>
                <CartesianGrid strokeWidth={0.5} stroke="#555a61"/>
                <XAxis dataKey="name" tick={{fontSize: '12px', fill: 'rgba(255, 255, 255, 0.72)'}} stroke="#555a61"
                       dy={8}/>
                <YAxis width={40} allowDecimals={false} tickFormatter={tickFormatter}
                       tick={{fontSize: '12px', fill: 'rgba(255, 255, 255, 0.72)'}} dx={-8} stroke="#555a61"/>
                <Tooltip content={<CustomTooltip/>} wrapperStyle={{outline: 'none'}}/>
              </LineChart>
            </ResponsiveContainer>
            <Typography variant="caption" sx={{color: 'rgba(255, 255, 255, 0.72)', alignSelf: 'flex-end', mt: 1}}>*Dates
              are shown in UTC</Typography>
          </>
        }
      </Box>
    </CardContent>
  </Card>
}

const CustomTooltip = ({active, payload, label}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length > 0) {
    return <Box sx={{bgcolor: '#313945', p: 1, outline: 'none'}}>
      <p>{label}: {payload[0].value}</p>
    </Box>
  } else {
    return null
  }
}