import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import React, { useEffect } from "react";
import { ErrorComponent } from "../components/ErrorComponent";
import { Box } from "@mui/material";

export const ErrorBoundary = () => {
  const navigate = useNavigate()
  const error = useRouteError()

  useEffect(() => {
    console.log(error)
    if (isRouteErrorResponse(error)) {
      if (error.status === 401) {
        navigate("/login", {state: document.location.pathname + document.location.search, replace: true})
      } else if (error.status === 403) {
        navigate("/organizations")
      }
    }
  })

  return <Box sx={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}><ErrorComponent title="Something went wrong."
                     description="Unfortunately, the page could not be loaded. Try to reload the page."/></Box>
}