import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  Chip,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { createSearchParams, useOutletContext, useSearchParams } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import { ApiClient } from "../../../client/ApiClient";
import { AuditLogsWithTotalCount } from "../../../models/AuditLog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Search } from "@mui/icons-material";
import { EventsTable } from "../../../components/EventsTable/EventsTable";
import { Width } from "../../../const/Width";
import { SuspendedInfoComponent } from "../../../components/SuspendedInfoComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Events = () => {
  const { project, organization } = useOutletContext() as ContextType
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [loader, setLoader] = useState<undefined | Promise<AuditLogsWithTotalCount>>()
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()
  const [types, setTypes] = useState<string[]>(searchParams.getAll("type") ?? [])
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page)
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10)
    setPage(0)
    setRowsPerPage(rowsPerPage)
  }

  useEffect(() => {
    const queryParams = createSearchParams({
      'page': (page + 1).toString(),
      'per_page': rowsPerPage.toString(10),
      'q': searchParams.get("q") ?? "",
      'type': searchParams.getAll("type")
    })
    setLoader(ApiClient.getAuditLogs(organization.id, project.id, queryParams))
  }, [page, rowsPerPage, organization, project, searchParams])


  const search = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      search: { value: string }
    }

    const q = target.search.value.trim()

    const sp = new URLSearchParams()
    sp.set("q", q)
    for (const type of types) {
      sp.append("type", type)
    }
    setSearchParams(sp)
    setPage(0)
  }

  const onChange = (event: React.SyntheticEvent, value: string[], reason: AutocompleteChangeReason) => {
    setTypes(value)
  }

  const maxTagsShown = 1
  const textFieldMaxWidth = matchesMdDown ? '100%' : '400px'

  return (
    <Box maxWidth={Width.WIDE}>
      <Typography variant="h4">Events</Typography>
      <form onSubmit={search}>
        <Box display="flex" flexDirection={matchesMdDown ? "column" : "row"} gap="20px" sx={{ mt: 4, mb: 5 }}>
          <TextField hiddenLabel size="small" name="search" placeholder="Search for user ID or IP"
                     variant="outlined"
                     defaultValue={searchParams.get("q")} sx={{ width: '100%', maxWidth: textFieldMaxWidth }} />
          <Autocomplete
            multiple
            limitTags={maxTagsShown}
            id="multiple-limit-tags"
            options={auditLogTypes}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            size="small"
            onChange={onChange}
            value={types}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    name="checkbox"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )
            }}
            renderInput={(params) => {
              return (
                <TextField name="types" variant="outlined" {...params} placeholder="Events" />
              )
            }}
            renderTags={(value, getTagProps) => {
              const numTags = value.length
              return <>
                {value.slice(0, maxTagsShown).map((option, index) => <Chip size="small" key={index} label={option}
                                                                           onDelete={getTagProps({ index }).onDelete} />)}
                {numTags > maxTagsShown &&
                    <span style={{ marginLeft: '4px' }}>{` +${numTags - maxTagsShown} more`}</span>}
              </>
            }}
            sx={{ width: '100%', maxWidth: textFieldMaxWidth }}
          />
          <Button type="submit" variant="contained" startIcon={<Search />} size="medium" sx={{ minWidth: '100px' }}>
            Search
          </Button>
        </Box>
      </form>
      {project.status.replicas === 0 ?
        <SuspendedInfoComponent project={project} organization={organization} type="list" /> :
        <EventsTable loader={loader} rowsPerPage={rowsPerPage} page={page} onRowsPerPageChange={onRowsPerPageChange}
                     onPageChange={onPageChange}
                     noResultsFoundThroughSearch={(searchParams.get("q") ?? "") !== "" || searchParams.getAll("type").length !== 0}
                     projectType="hanko_project" />
      }
    </Box>
  )
}

export default Events

const oldAPIAuditLogTypes = [
  "password_set_succeeded",
  "password_set_failed",
  "password_login_succeeded",
  "password_login_failed",
  "passcode_login_init_succeeded",
  "passcode_login_init_failed",
  "passcode_login_final_succeeded",
  "passcode_login_final_failed",
  "webauthn_registration_init_succeeded",
  "webauthn_registration_init_failed",
  "webauthn_registration_final_succeeded",
  "webauthn_registration_final_failed",
  "webauthn_authentication_init_succeeded",
  "webauthn_authentication_init_failed",
  "webauthn_authentication_final_succeeded",
  "webauthn_authentication_final_failed",
  "webauthn_credential_updated",
  "webauthn_credential_deleted",
  "thirdparty_signup_succeeded",
  "thirdparty_signin_succeeded",
  "thirdparty_signin_signup_failed",
  "token_exchange_succeeded",
  "token_exchange_failed",
]

const bothAPIAuditLogTypes = [
  "user_created",
  "email_created",
  "email_verified",
  "primary_email_changed",
  "email_deleted",
  "user_logged_out",
  "user_deleted",
]

const flowAPIAuditLogTypes = [
  "login_success",
  "login_failure",
  "passkey_created",
  "passkey_deleted",
  "username_changed",
  "username_deleted",
  "password_changed",
  "password_deleted",
]

const auditLogTypes = [
  ...oldAPIAuditLogTypes,
  ...bothAPIAuditLogTypes,
  ...flowAPIAuditLogTypes
]