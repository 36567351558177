import { ChartCard, ChartData, Props as ChartProps } from "./ChartCard";
import React, { useEffect, useState } from "react";
import { Await, createSearchParams } from "react-router-dom";
import { SelectChangeEvent, Skeleton } from "@mui/material";
import { ApiClient } from "../../client/ApiClient";
import { DailyActiveUser, MonthlyActiveUserStats } from "../../models/DailyActiveUser";
import { Project } from "../../models/Project";
import { Organization } from "../../models/Organization";
import { GenericProject, ProjectType } from "../../const/types";

interface Props {
  organization: Organization
  project: GenericProject
  projectType: ProjectType
  size?: "small" | "medium" | "large"
}

export const ActiveUsersChart = (props: Props) => {
  const {organization, project, projectType} = props
  const [precision, setPrecision] = useState<ChartProps['precision']>("days")
  const [timeRange, setTimeRange] = useState<ChartProps['timeRange']>("30")
  const [loader, setLoader] = useState<Promise<DailyActiveUser[] | MonthlyActiveUserStats[]> | undefined>(undefined)
  const dateFormatDays = new Intl.DateTimeFormat("default", {day: "2-digit", month: "short", timeZone: "UTC"})
  const dateFormatMonths = new Intl.DateTimeFormat("default", {month: "short", year: "numeric", timeZone: "UTC"})
  const dateFormat = precision === "days" ? dateFormatDays : dateFormatMonths

  useEffect(() => {
    const today = new Date()
    const startTime = new Date(today)
    const endTime = new Date(today)

    if (precision === "days") {
      startTime.setUTCHours(0, 0, 0, 0)
      startTime.setUTCDate(today.getUTCDate() - parseInt(timeRange))

      endTime.setUTCHours(23, 59, 59, 999)
      endTime.setUTCDate(today.getUTCDate() - 1)

      setLoader(ApiClient.getDailyActiveUsers(projectType, organization.id, project.id, createSearchParams({
        'start_time': startTime.toISOString(),
        'end_time': endTime.toISOString()
      })))
    } else {
      startTime.setUTCHours(0, 0, 0, 0)
      startTime.setUTCMonth(startTime.getUTCMonth() - parseInt(timeRange) + 1)
      startTime.setUTCDate(1)

      endTime.setUTCHours(23, 59, 59, 999)
      endTime.setUTCMonth(endTime.getUTCMonth() + 1)
      endTime.setUTCDate(0)

      setLoader(ApiClient.getMonthlyActiveUsersList(projectType, organization.id, project.id, createSearchParams({
        'start_time': startTime.toISOString(),
        'end_time': endTime.toISOString()
      })))
    }
  }, [precision, timeRange, organization.id, project.id])

  const formatData = (stats: DailyActiveUser[] | MonthlyActiveUserStats[] | undefined) => {
    if (stats === undefined) {
      return []
    }

    stats.sort((a, b) => {
      return new Date(getTime(a)).getTime() - new Date(getTime(b)).getTime()
    })

    let lastDate = new Date()
    if (stats.length > 0) {
      lastDate = new Date(getTime(stats[0]))
    }

    lastDate.setUTCHours(0, 0, 0, 0)
    if (precision === "months") {
      lastDate.setUTCDate(1)
    }

    let data: ChartData[] = []
    for (let i = parseInt(timeRange) - stats.length; i > 0; i--) {
      if (precision === "days") {
        lastDate.setUTCDate(lastDate.getUTCDate() - 1)
      } else {
        lastDate.setUTCMonth(lastDate.getUTCMonth() - 1)
      }
      data.push({
        name: dateFormat.format(lastDate),
        count: 0
      })
    }

    data = data.reverse()

    for (const stat of stats) {
      const date = new Date(getTime(stat))
      data.push({
        name: dateFormat.format(date),
        count: stat.count
      })
    }
    return data
  }

  const getTime = (stat: DailyActiveUser | MonthlyActiveUserStats) => {
    if ((stat as any).id !== undefined) {
      return (stat as DailyActiveUser).start_time
    } else {
      return (stat as MonthlyActiveUserStats).date
    }
  }

  const timeRangeChange = (event: SelectChangeEvent<typeof timeRange>) => {
    setTimeRange(event.target.value as ChartProps['timeRange'])
  }

  const precisionChange = (event: SelectChangeEvent<typeof precision>) => {
    setPrecision(event.target.value as ChartProps['precision'])
    if (event.target.value === "months") {
      setTimeRange("6")
    } else {
      setTimeRange("30")
    }
  }

  return <React.Suspense fallback={
    <ChartCard title="Active users" data={[]} precision={precision} timeRange={timeRange}
               onPrecisionChange={precisionChange} onTimeRangeChange={timeRangeChange}
               isLoading={true} size={props.size} availablePrecisions={["days"]}/>
  }>
    {loader === undefined ? null :
      <Await resolve={loader} errorElement={<Skeleton height={400} width="100%"/>}>
        {(data: Awaited<DailyActiveUser[] | undefined>) => (
          <ChartCard title="Active users" data={formatData(data)} precision={precision} timeRange={timeRange}
                     onPrecisionChange={precisionChange} onTimeRangeChange={timeRangeChange}
                     isLoading={false} size={props.size}/>
        )}
      </Await>
    }
  </React.Suspense>

}