import { ChartCard, ChartData, Props as ChartProps } from "../../../components/Charts/ChartCard";
import { Organization } from "../../../models/Organization";
import { Project } from "../../../models/Project";
import React, { useEffect, useState } from "react";
import { TotalUser } from "../../../models/TotalUser";
import { SelectChangeEvent, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { ApiClient } from "../../../client/ApiClient";
import { Await, createSearchParams } from "react-router-dom";

interface Props {
  organization: Organization
  project: Project
}

export const TotalUsersChart = (props: Props) => {
  const {organization, project} = props
  const [precision, setPrecision] = useState<ChartProps['precision']>("days")
  const [timeRange, setTimeRange] = useState<ChartProps['timeRange']>("30")
  const [loader, setLoader] = useState<Promise<TotalUser[]> | undefined>(undefined)
  const dateFormatDays = new Intl.DateTimeFormat("default", {day: "2-digit", month: "short", timeZone: "UTC"})
  const dateFormatMonths = new Intl.DateTimeFormat("default", {month: "short", year: "numeric", timeZone: "UTC"})
  const dateFormat = precision === "days" ? dateFormatDays : dateFormatMonths

  useEffect(() => {
    const today = new Date()
    const startTime = new Date(today)
    const endTime = new Date(today)

    if (precision === "days") {
      startTime.setUTCHours(0, 0, 0, 0)
      startTime.setUTCDate(today.getUTCDate() - parseInt(timeRange))

      endTime.setUTCHours(23, 59, 59, 999)
      endTime.setUTCDate(today.getUTCDate() - 1)
    } else {
      startTime.setUTCHours(0, 0, 0, 0)
      startTime.setUTCMonth(startTime.getUTCMonth() - parseInt(timeRange) + 1)
      startTime.setUTCDate(1)

      endTime.setUTCHours(23, 59, 59, 999)
      endTime.setUTCMonth(endTime.getUTCMonth() + 1)
      endTime.setUTCDate(0)
    }

    if (precision === "days") {
      setLoader(ApiClient.getTotalUserDaily(organization.id, project.id, createSearchParams({
        'start_time': startTime.toISOString(),
        'end_time': endTime.toISOString()
      })))
    } else {
      setLoader(ApiClient.getTotalUserMonthly(organization.id, project.id, createSearchParams({
        'start_time': startTime.toISOString(),
        'end_time': endTime.toISOString()
      })))
    }
  }, [precision, timeRange, organization.id, project.id])

  const formatData = (stats: TotalUser[] | undefined) => {
    if (stats === undefined) {
      return []
    }

    stats.sort((a, b) => {
      return new Date(a.record_time).getTime() - new Date(b.record_time).getTime()
    })

    let lastDate = new Date()
    if (stats.length > 0) {
      lastDate = new Date(stats[0].record_time)
    }

    lastDate.setUTCHours(0, 0, 0, 0)
    if (precision === "months") {
      lastDate.setUTCDate(1)
    }

    let data: ChartData[] = []
    for (let i = parseInt(timeRange) - stats.length; i > 0; i--) {
      if (precision === "days") {
        lastDate.setUTCDate(lastDate.getUTCDate() - 1)
      } else {
        lastDate.setUTCMonth(lastDate.getUTCMonth() - 1)
      }
      data.push({
        name: dateFormat.format(lastDate),
        count: 0
      })
    }

    data = data.reverse()

    for (const stat of stats) {
      const date = new Date(stat.record_time)
      data.push({
        name: dateFormat.format(date),
        count: stat.count
      })
    }
    return data
  }

  const timeRangeChange = (event: SelectChangeEvent<typeof timeRange>) => {
    setTimeRange(event.target.value as ChartProps['timeRange'])
  }

  const precisionChange = (event: SelectChangeEvent<typeof precision>) => {
    setPrecision(event.target.value as ChartProps['precision'])
    if (event.target.value === "months") {
      setTimeRange("6")
    } else {
      setTimeRange("30")
    }
  }

  return <React.Suspense fallback={<ChartCard title="Total users" data={[]} precision={precision} timeRange={timeRange}
                                              onPrecisionChange={precisionChange} onTimeRangeChange={timeRangeChange}
                                              isLoading={true} size="small"/>}>
    {loader === undefined ? null :
      <Await resolve={loader} errorElement={<Skeleton height={400} width="100%"/>}>
        {(data: Awaited<TotalUser[] | undefined>) => (
          <ChartCard title="Total users" data={formatData(data)} precision={precision} timeRange={timeRange}
                     onPrecisionChange={precisionChange} onTimeRangeChange={timeRangeChange} isLoading={false}
                     size="small"/>
        )}
      </Await>
    }
  </React.Suspense>
}