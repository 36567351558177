import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../ProjectWrapper";
import React, { useContext } from "react";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { UpdateProjectData } from "../../../../models/Project";
import { ApiClient } from "../../../../client/ApiClient";
import { Box } from "@mui/material";
import { Width } from "../../../../const/Width";
import { TOTP } from "./TOTP";
import { SecurityKeys } from "./SecurityKeys";
import { General } from "./General";

export const MFA = () => {
  const { project, organization } = useOutletContext() as ContextType
  const revalidator = useRevalidator()
  const { error, success } = useContext(SnackbarContext)

  const submit = async (data: UpdateProjectData) => {
    try {
      await ApiClient.updateProject(organization.id, project.id, data)
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: Width.NORMAL,
    gap: 5
  }}>
    <General project={project} submit={submit} />
    <SecurityKeys project={project} submit={submit} />
    <TOTP project={project} submit={submit} />
  </Box>
}