import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../ProjectWrapper";
import { UpdateProjectData } from "../../../../models/Project";
import { ApiClient } from "../../../../client/ApiClient";
import { useContext } from "react";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { Box } from "@mui/material";
import { Width } from "../../../../const/Width";
import { Email } from "./Email";
import { Username } from "./Username";
import { SelfService } from "./SelfService";

export const UserAccount = () => {
  const {project, organization} = useOutletContext() as ContextType
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const submit = async (data: UpdateProjectData) => {
    try {
      await ApiClient.updateProject(organization.id, project.id, data)
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: Width.NORMAL,
    gap: 5
  }}>

    <Email project={project} submit={submit} />
    <Username project={project} submit={submit} />
    <SelfService project={project} submit={submit} />
  </Box>
}