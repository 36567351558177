import { Project, UpdateProjectData } from "../../../../models/Project";
import { Box, Switch, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  required: boolean
  acquireOnRegistration: boolean
  acquireOnLogin: boolean
  canBeUsedAsLoginIdentifier: boolean
  maxLength: number
}

export const Username = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    enabled: project.username.enabled ?? false,
    required: !(project.username.optional ?? false),
    acquireOnRegistration: project.username.acquire_on_registration ?? false,
    acquireOnLogin: project.username.acquire_on_login ?? false,
    canBeUsedAsLoginIdentifier: project.username.use_as_login_identifier ?? false,
    maxLength: project.username.max_length,
  })

  const somethingChanged = () => {
    return (project.username.enabled ?? false) !== state.enabled ||
      !(project.username.optional ?? false) !== state.required ||
      (project.username.acquire_on_registration ?? false) !== state.acquireOnRegistration ||
      (project.username.acquire_on_login ?? false) !== state.acquireOnLogin ||
      (project.username.use_as_login_identifier ?? false) !== state.canBeUsedAsLoginIdentifier ||
      project.username.max_length !== state.maxLength
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else if (e.target.type === "number") {
      setState({
        ...state,
        [e.target.name]: e.target.valueAsNumber,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      username: {
        ...project.username,
        enabled: state.enabled,
        optional: !state.required,
        acquire_on_registration: state.acquireOnRegistration,
        acquire_on_login: state.acquireOnLogin,
        use_as_login_identifier: state.canBeUsedAsLoginIdentifier,
        max_length: state.maxLength
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: project.username.enabled ?? false,
      required: !(project.username.optional ?? false),
      acquireOnRegistration: project.username.acquire_on_registration ?? false,
      acquireOnLogin: project.username.acquire_on_login ?? false,
      canBeUsedAsLoginIdentifier: project.username.use_as_login_identifier ?? false,
      maxLength: project.username.max_length
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead description="Allow users to set a unique username for their account." helpText="Usernames can contain letters (a-z), numbers (0-9), and underscores. Letters are always converted to lower case." title="Username" sx={{ mb: 1 }} minElementsVersion="1.0" />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Users need to enter a username if prompted. The username can only be changed, not deleted." title="Required" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="required" color="success" checked={state.required} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to enter a username when creating a new account."
                      title="Prompt during registration" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="acquireOnRegistration" color="success" checked={state.acquireOnRegistration}
                onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to enter a username when logging in if no username is configured for their account yet." title="Prompt during login"
                      sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="acquireOnLogin" color="success" checked={state.acquireOnLogin} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Allow users to log in with their username."
                      title="Use as login identifier" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="canBeUsedAsLoginIdentifier" color="success" checked={state.canBeUsedAsLoginIdentifier}
                onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Max length" description="Set the maximum length a username can have." size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.0" />
        <TextField fullWidth label="Max length" sx={{ maxWidth: { xs: '100%', md: '220px' } }}
                   type="number"
                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 255 }}
                   name="maxLength" size="small" onChange={onChange} disabled={!state.enabled}
                   value={state.maxLength} />
      </SettingsOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}