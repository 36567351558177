import { Project, UpdateProjectData } from "../../../../models/Project";
import { Box, Switch, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  required: boolean
  requireVerification: boolean
  acquireOnRegistration: boolean
  acquireOnLogin: boolean
  canBeUsedAsLoginIdentifier: boolean
  canBeUsedForAuthentication: boolean
  limit: number
  passcodeTTL: number
}

export const Email = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    enabled: project.email.enabled ?? false,
    required: !(project.email.optional ?? false),
    requireVerification: project.email.require_verification ?? false,
    acquireOnRegistration: project.email.acquire_on_registration ?? false,
    acquireOnLogin: project.email.acquire_on_login ?? false,
    canBeUsedForAuthentication: project.email.use_for_authentication ?? false,
    canBeUsedAsLoginIdentifier: project.email.use_as_login_identifier ?? false,
    limit: project.email.limit,
    passcodeTTL: project.email.passcode_ttl,
  })

  const somethingChanged = () => {
    return (project.email.enabled ?? false) !== state.enabled ||
      !(project.email.optional ?? false) !== state.required ||
      (project.email.require_verification ?? false) !== state.requireVerification ||
      (project.email.acquire_on_registration ?? false) !== state.acquireOnRegistration ||
      (project.email.acquire_on_login ?? false) !== state.acquireOnLogin ||
      (project.email.use_for_authentication ?? false) !== state.canBeUsedForAuthentication ||
      (project.email.use_as_login_identifier ?? false) !== state.canBeUsedAsLoginIdentifier ||
      project.email.limit !== state.limit ||
      project.email.passcode_ttl !== state.passcodeTTL
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else if (e.target.type === "number") {
      setState({
        ...state,
        [e.target.name]: e.target.valueAsNumber,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      email: {
        ...project.email,
        enabled: state.enabled,
        optional: !state.required,
        require_verification: state.requireVerification,
        acquire_on_registration: state.acquireOnRegistration,
        acquire_on_login: state.acquireOnLogin,
        use_for_authentication: state.canBeUsedForAuthentication,
        use_as_login_identifier: state.canBeUsedAsLoginIdentifier,
        limit: state.limit,
        passcode_ttl: state.passcodeTTL,
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: project.email.enabled ?? false,
      required: !(project.email.optional ?? false),
      requireVerification: project.email.require_verification ?? false,
      acquireOnRegistration: project.email.acquire_on_registration ?? false,
      acquireOnLogin: project.email.acquire_on_login ?? false,
      canBeUsedForAuthentication: project.email.use_for_authentication ?? false,
      canBeUsedAsLoginIdentifier: project.email.use_as_login_identifier ?? false,
      limit: project.email.limit,
      passcodeTTL: project.email.passcode_ttl,
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead description="Allow users to add email addresses to their account." title="Email addresses" sx={{ mb: 1 }} minElementsVersion="1.0" />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="There must always be at least one email address associated with an account." title="Required" sx={{ mb: 1 }} size="small"
                      minElementsVersion="1.0" />
        <Switch name="required" color="success" checked={state.required} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Email addresses must always be verified by entering an email passcode." title="Require verification" sx={{ mb: 1 }}
                      size="small" />
        <Switch name="requireVerification" color="success" checked={state.requireVerification} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to enter their email address when creating a new account."
                      title="Prompt during registration" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="acquireOnRegistration" color="success" checked={state.acquireOnRegistration} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Ask the user to enter their email address when logging in if no email address has been added before." title="Prompt during login"
                      sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="acquireOnLogin" color="success" checked={state.acquireOnLogin} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead
          description="Allow users to log in by entering a passcode that was sent to their (primary) email address." helpText="This setting controls the option for users to log in using an email passcode. This does not necessarily mean that an email address must be entered for the login, e.g. if the login identifier is a username to which an email address is assigned."
          title="Use for authentication" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="canBeUsedForAuthentication" color="success" checked={state.canBeUsedForAuthentication}
                onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead description="Allow users to log in with their email address."
                      title="Use as login identifier" sx={{ mb: 1 }} size="small" minElementsVersion="1.0" />
        <Switch name="canBeUsedAsLoginIdentifier" color="success" checked={state.canBeUsedAsLoginIdentifier}
                onChange={onChange} disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Number of email addresses" description="Set the maximum number of email addresses a user can have."
                      size="small" sx={{ mb: 0 }} />
        <TextField fullWidth label="Number of email addresses" sx={{ maxWidth: { xs: '100%', md: '220px' } }}
                   type="number"
                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 100 }}
                   name="limit" size="small" onChange={onChange} disabled={!state.enabled}
                   value={state.limit} />
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Passcode TTL (seconds)" description="Set the lifetime of a passcode (in seconds)."
                      size="small" sx={{ mb: 0 }} />
        <TextField fullWidth label="Passcode TTL (seconds)" sx={{ maxWidth: { xs: '100%', md: '220px' } }}
                   type="number"
                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 3600 }}
                   name="passcodeTTL" size="small" onChange={onChange} disabled={!state.enabled}
                   value={state.passcodeTTL} />
      </SettingsOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}