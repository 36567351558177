import { Box, SxProps, Theme, Typography, TypographyTypeMap } from "@mui/material";
import React from "react";
import { PlanChip } from "../PlanChip";
import { Organization } from "../../models/Organization";
import { VersionTag } from "../VersionTag";
import { HankoHelpTooltip, HankoTooltip } from "../Tooltip/Tooltip";

interface Props {
  title: string
  description: React.ReactNode
  sx?: SxProps<Theme>
  plan?: "Starter" | "Pro" | "Business"
  type?: "plan" | "feature"
  organization?: Organization
  size: "medium" | "small"
  minElementsVersion?: string
  helpText?: React.ReactNode
}

export const SettingsHead = (props: Props) => {
  const { title, description, sx, plan, type, organization, size, minElementsVersion, helpText } = props

  let titleVariant: TypographyTypeMap["props"]["variant"] = undefined
  let descriptionVariant: TypographyTypeMap["props"]["variant"] = undefined
  switch (size) {
    case "medium":
      titleVariant = "h6"
      descriptionVariant = "body1"
      break
    case "small":
      titleVariant = "subtitle1"
      descriptionVariant = "body2"
      break
  }
  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3, mt: plan !== undefined ? 1 : 0, ...sx }}>
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column-reverse', md: 'row' },
      alignItems: { xs: 'unset', md: 'center' },
      gap: 1
    }}>
      <Typography variant={titleVariant} sx={{ fontWeight: 700 }}>{title}</Typography>
      {helpText !== undefined ? <HankoHelpTooltip title={helpText} /> : null}
      {minElementsVersion !== undefined ? <HankoTooltip
        title={`This feature is compatible with Hanko Elements version ${minElementsVersion} or newer.`}><span><VersionTag
        version={`Elements ${minElementsVersion}+`} /></span></HankoTooltip> : null}
      {plan !== undefined && type !== undefined && organization !== undefined ?
        <PlanChip plan={plan} type={type} organization={organization} /> : null}
    </Box>
    <Typography variant={descriptionVariant}>{description}</Typography>
  </Box>
}

SettingsHead.defaultProps = {
  size: 'medium'
}