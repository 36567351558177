import { ProjectUser, WebAuthnCredential } from "../../../../models/ProjectUser";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React from "react";
import { Chip, TableCell, TableRow } from "@mui/material";

export const PasskeyTable = (props: { user: ProjectUser }) => {
  const { user } = props

  return <UserAttributesTable size="medium" label="Passkeys" head={<PasskeyTableHead />}
                              rows={user.webauthn_credentials?.map(value => <PasskeyRow key={value.id}
                                                                                        passkey={value} />)}
                              emptyContent="No passkeys set." />
}

const PasskeyTableHead = () => {
  return <>
    <TableCell>Name</TableCell>
    <TableCell>Created at (UTC)</TableCell>
    <TableCell>Backup state</TableCell>
    <TableCell></TableCell>
  </>
}

const PasskeyRow = (props: { passkey: WebAuthnCredential }) => {
  const { passkey } = props

  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  })

  const uiDisplayName = (credential: WebAuthnCredential) => {
    if (credential.name) {
      return credential.name;
    }
    const alphanumeric = credential.public_key.replace(/[\W_]/g, "");
    return `Passkey-${alphanumeric.substring(
      alphanumeric.length - 7,
      alphanumeric.length
    )}`;
  }

  const getBackupState = (credential: WebAuthnCredential) => {
    if (credential.backup_state) {
      return <Chip variant="outlined" label="Backed up" color="success" size="small" />
    } else if (credential.backup_eligible) {
      return <Chip variant="outlined" label="Eligible" color="primary" size="small" />
    } else {
      return <Chip variant="outlined" label="Device-bound" size="small" />
    }
  }

  return <TableRow>
    <TableCell>{uiDisplayName(passkey)}</TableCell>
    <TableCell>{dateFormat.format(new Date(passkey.created_at))}</TableCell>
    <TableCell>{getBackupState(passkey)}</TableCell>
    <TableCell sx={{ width: 0 }}>
      {/* TODO: hanko admin API needs to support removing passkeys for a user */}
      {/*<MooreMenu>*/}
      {/*  <MenuItem sx={{ color: 'red' }}>Delete</MenuItem> /!* TODO: use error color from theme*!/*/}
      {/*</MooreMenu>*/}
    </TableCell>
  </TableRow>
}
