import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";

interface RenderSnackbarProps {
  message: string
  severity: AlertColor
  open: boolean
  handleClose: () => void
}

export const SnackbarContext = createContext({
  success: (message: string) => {
    console.log("not wrapped by provider")
  },
  error: (message: string) => {
    console.log("not wrapped by provider")
  },
  info: (message: string) => {
    console.log("not wrapped by provider")
  },
  warning: (message: string) => {
    console.log("not wrapped by provider")
  }
})

const RenderSnackbar = (props: RenderSnackbarProps) => {
  const {message, severity, open, handleClose} = props

  return <Snackbar
    open={open}
    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    autoHideDuration={10000}
    onClose={handleClose}
  >
    <Alert variant="filled" onClose={handleClose} severity={severity} sx={{width: '100%'}}>
      {message}
    </Alert>
  </Snackbar>
}

type Snack = {
  message: string
  severity: AlertColor
  open: boolean
}

type State = {
  current?: Snack
  queue: Snack[]
}

export const SnackbarProvider = (props: { children: React.ReactNode }) => {
  const [{current, queue}, setState] = useState<State>({current: undefined, queue: []})


  const success = (message: string) => {
    show(message, "success")
  }

  const error = (message: string) => {
    show(message, "error")
  }

  const info = (message: string) => {
    show(message, "info")
  }

  const warning = (message: string) => {
    show(message, "warning")
  }

  const show = (message: string, severity: AlertColor) => {
    const snack = {message, severity, open: true}
    if (current) {
      setState({current, queue: queue.concat(snack)})
    } else {
      setState({queue, current: snack})
    }
  }

  function handleClose() {
    setState((currentState) => ({
      ...currentState,
      current: {...currentState.current!!, open: false},
    }))
    // time to snack close animation
    setTimeout(openNext, 1000)
  }

  function openNext() {
    if (queue.length) {
      setState({current: queue[0], queue: queue.slice(1)})
    } else {
      setState({current: undefined, queue: []})
    }
  }

  return <SnackbarContext.Provider value={{success, error, info, warning}}>
    {current && <RenderSnackbar {...current} handleClose={handleClose}/>}
    {props.children}
  </SnackbarContext.Provider>
}