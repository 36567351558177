import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { HankoTooltip } from "./Tooltip/Tooltip";

interface Props {
  onDelete: () => void
  title: string
  description: string
  buttonText: string
}

export const DeleteComponent = (props: Props) => {
  const { onDelete, title, description, buttonText } = props
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  return <Box sx={{
    border: 1,
    borderColor: 'error.main',
    mt: 15,
  }} borderRadius="8px" padding="30px">
    <Box display="flex" flexDirection={isMdDown ? "column" : "row"} gap={isMdDown ? "16px" : "8px"}
         justifyContent="space-between"
         alignItems={isMdDown ? "start" : "center"}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
      {window.REACT_APP_DISABLE_PROJECT_CREATION ? <HankoTooltip title="This function is disbaled due to maintenance.">
        <span><Button variant="outlined" color="error" sx={{ width: isMdDown ? '100%' : '160px' }}
                onClick={onDelete} disabled={window.REACT_APP_DISABLE_PROJECT_CREATION}>{buttonText}</Button></span>
      </HankoTooltip> : <Button variant="outlined" color="error" sx={{ width: isMdDown ? '100%' : '160px' }}
                                onClick={onDelete}
                                disabled={window.REACT_APP_DISABLE_PROJECT_CREATION}>{buttonText}</Button>
      }
    </Box>
  </Box>
}