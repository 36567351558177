import React, { useState } from "react";
import { HankoDialog } from "../HankoDialog";
import { ProjectTypeSelector } from "./ProjectTypeSelector";
import { NewProjectForm } from "./NewProjectForm";
import { ProjectType } from "../../const/types";

interface Props {
  open: boolean
  onClose: () => void
  organizationId: string
}

export const NewProjectModal = (props: Props) => {
  const [projectType, setProjectType] = useState<ProjectType>("hanko_project")
  const [step, setStep] = useState<"selector" | "form">("selector")

  const onClose = () => {
    setStep("selector")
    props.onClose()
  }

  const onProjectTypeSelected = (projectType: ProjectType) => {
    setProjectType(projectType)
    setStep("form")
  }

  const onBack = () => {
    setStep("selector")
  }

  let stepNode: JSX.Element
  switch (step) {
    case "form":
      stepNode = <NewProjectForm projectType={projectType} organizationId={props.organizationId} onBack={onBack}/>
      break
    default:
      stepNode = <ProjectTypeSelector onProjectTypeSelected={onProjectTypeSelected}/>
  }

  return <HankoDialog open={props.open} onClose={onClose} fullScreen>
    {stepNode}
  </HankoDialog>
}
