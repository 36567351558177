import { Alert, Box, Collapse, Link, TextField, Typography } from "@mui/material";
import { Width } from "../../../../const/Width";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import React, { useContext, useState } from "react";
import { FormButtons } from "../../../../components/form/FormButtons";
import { AllowedOrigins } from "./AllowedOrigins";
import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../ProjectWrapper";
import { AllowedRedirectUrls } from "./AllowedRedirectUrls";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { ipAddressRegex } from "../../../../const/regex";
import { TransitionGroup } from "react-transition-group";

interface State {
  app_url: string
  default_redirect_url: string
}

export const Urls = () => {
  const {project, organization} = useOutletContext() as ContextType
  const [rpIdLoading, setRpIdLoading] = useState(false)
  const [defaultRedirectUrlLoading, setDefaultRedirectUrlLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [explainerOpen, setExplainerOpen] = useState(false)
  const [state, setState] = useState<State>({
    app_url: project.application_url,
    default_redirect_url: project.third_party.error_redirect_url
  })
  const {error, success} = useContext(SnackbarContext)
  const revalidator = useRevalidator()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onRpIDSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault()
    setRpIdLoading(true)

    try {
      const url = new URL(state.app_url)
      if (url.hostname === "") {
        error("App URL is missing the host")
        setRpIdLoading(false)
        return
      } else if (ipAddressRegex.test(url.hostname.replace("[", "").replace("]", ""))) {
        error("App URL host must not contains an ip address")
        setRpIdLoading(false)
        return
      }
    } catch (e) {
      error("App URL is not a valid url")
      setRpIdLoading(false)
      return
    }

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        application_url: state.app_url,
      })
      revalidator.revalidate()
      success("Relying party ID successful updated")
      setOpen(false)
    } catch (e) {
      error("Failed to update relying party ID")
    }

    setRpIdLoading(false)
  }

  const onDefaultRedirectUrlSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setDefaultRedirectUrlLoading(true)
    try {
      await ApiClient.updateProject(organization.id, project.id, {
        third_party: {
          ...project.third_party,
          error_redirect_url: state.default_redirect_url
        }
      })
      revalidator.revalidate()
      success("Default redirect url successful updated")
    } catch (e) {
      error("Failed to update default redirect url")
    }

    setDefaultRedirectUrlLoading(false)
  }

  return <>
    <Box maxWidth={Width.NORMAL} sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
      <form onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault()
        setOpen(true)
      }}>
        <SettingsHead title="App URL"
                      description="The protocol and hostname of the location where you want to integrate Hanko Elements (i.e., your app’s login page). The domain is also used for passkey binding (WebAuthn rpID)."
                      sx={{mb: 2}}/>
        <Box sx={{mb: 3}}>
          <Link sx={{cursor: 'pointer'}} onClick={() => {
            setExplainerOpen(!explainerOpen)
          }}>What are valid app URLs?</Link>
          <TransitionGroup>
            {explainerOpen ? <Collapse>
              The URL where your application runs and where you want to integrate the Hanko frontend
              components. A full URL without path is required (e.g. https://app.example.com or
              http://localhost:8080). An IP address is not allowed. Be sure to add the port if it is
              not 80 or 443. Note: Passkeys only work in a secure context. That is either an
              application served over https or on localhost.
            </Collapse> : null}
          </TransitionGroup>
        </Box>
        {/*<Box sx={{my: 3}}>*/}
        {/*  <Link href="https://docs.hanko.io" target="_blank">Learn more on passkey binding</Link>*/}
        {/*</Box>*/}
        <Alert variant="outlined" severity="info">If you want to change the environment (e.g. from development to
          production), we strongly recommend <Link href={`/organizations/${organization.id}/projects`}>creating a new
            project</Link> instead of changing the app URL of this project.</Alert>
        <TextField name="app_url" label="App URL" fullWidth type="url" onChange={onChange}
                   value={state.app_url}
                   required autoComplete="off" sx={{mt: 3}}
                   placeholder="E.g. https://example.com or http://localhost:3000"/>
        <FormButtons onCancel={() => {
          setState({...state, app_url: project.application_url})
        }} loading={rpIdLoading} disabled={state.app_url === project.application_url} sx={{mt: 5}}/>
      </form>

      <AllowedOrigins organization={organization} project={project}/>

      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        <Typography variant="h5" fontWeight={700} sx={{fontSize: '1.875rem'}}>3rd-party redirect URLs</Typography>
        <Typography variant="body1">Used for social connections and enterprise connections.</Typography>
      </Box>

      <form onSubmit={onDefaultRedirectUrlSubmit}>
        <SettingsHead title="Default redirect URL"
                      description="The URL to which the Hanko backend redirects the user if an error occurs during authentication with the 3rd party provider."/>
        <TextField name="default_redirect_url" label="Default redirect url" fullWidth type="url" onChange={onChange}
                   value={state.default_redirect_url}
                   required autoComplete="off"/>
        <FormButtons onCancel={() => {
          setState({...state, default_redirect_url: project.third_party.error_redirect_url})
        }} loading={defaultRedirectUrlLoading}
                     disabled={state.default_redirect_url === project.third_party.error_redirect_url} sx={{mt: 5}}/>
      </form>

      <AllowedRedirectUrls organization={organization} project={project}/>
    </Box>
    <ConfirmationModal open={open} onClose={() => {
      setOpen(false)
    }} onConfirm={() => {
      onRpIDSubmit(undefined)
    }} title="App URL changed"
                       description="Are you sure you want to change the app URL? Your users' passkeys are bound to the domain of your app URL. Changing the app URL makes all existing passkeys unusable."
                       buttonText="Save"/>
  </>
}