import { Identity, ProjectUser } from "../../../../models/ProjectUser";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { providerCustomization } from "../../Settings/AuthProvider";
import { ThirdPartyProviders } from "../../../../models/Project";

export const ThirdPartyConnectionTable = (props: { user: ProjectUser }) => {
  const { user } = props
  return <UserAttributesTable size="medium" label="3rd-party connections" head={undefined}
                              rows={user.identities && user.identities.length > 0 ? user.identities.map(identity => <Row
                                key={identity.id}
                                identity={identity} />) : undefined}
                              emptyContent="No 3rd-party connection set." />
}

const Row = (props: { identity: Identity }) => {
  const { identity } = props
  return <TableRow>
    <TableCell sx={{ width: 0 }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {isKnownThirdPartyProvider(identity.provider_name) ?
          <img style={{ width: '17px', height: '17px' }} src={providerCustomization[identity.provider_name].icon}
               alt="provider icon" /> : null}
      </Box>
    </TableCell>
    <TableCell>{isKnownThirdPartyProvider(identity.provider_name) ? providerCustomization[identity.provider_name].name : identity.provider_name}</TableCell>
    <TableCell sx={{ width: 0 }}>
      {/* TODO: hanko admin API needs to support removing third party connections for a user*/}
      {/*<MooreMenu>*/}
      {/*  <MenuItem sx={{color: 'red'}}>Remove</MenuItem>*/}
      {/*</MooreMenu>*/}
    </TableCell>
  </TableRow>
}

const isKnownThirdPartyProvider = (value: string): value is keyof ThirdPartyProviders => {
  const allowedValues = Object.keys(providerCustomization)

  return allowedValues.indexOf(value) !== -1
}
