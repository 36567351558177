import { Project, UpdateProjectData } from "../../../../models/Project";
import React, { useState } from "react";
import { Box, Switch, useTheme } from "@mui/material";
import { SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
}

export const TOTP = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    enabled: project.mfa.totp.enabled,
  })

  const somethingChanged = () => {
    return project.mfa.totp.enabled !== state.enabled
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      mfa: {
        ...project.mfa,
        totp: {
          ...project.mfa.totp,
          enabled: state.enabled
        }
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: project.mfa.totp.enabled
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper
        sx={{
          borderBottom: `1px solid ${theme.palette.grey["900"]}`
        }}>
        <SettingsHead
          description="Users can set up a TOTP authenticator app (e.g. Google Authenticator, Microsoft Authenticator, DUO) as second factor. 2FA needs to be enabled for this setting to have an effect."
          title="TOTP" sx={{ mb: 1 }} minElementsVersion="1.1" />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}