import { useLoaderData, useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useState } from "react";
import { Box, Switch, TextField, useMediaQuery, useTheme } from "@mui/material";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { FormButtons } from "../../../components/form/FormButtons";
import { Subscription } from "../../../models/Subscription";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Width } from "../../../const/Width";
import { SubscriptionFeatureTooltip } from "../../../components/Tooltip/Tooltip";

interface State {
  host?: string
  port?: string
  user?: string
  password?: string
  from_address?: string
  hostError?: string
  portError?: string
  userError?: string
  passwordError?: string
  emailDeliveryEnabled: boolean
}

interface LoaderData {
  subscription: Subscription | null
}

export const Smtp = () => {
  const {project, organization} = useOutletContext() as ContextType
  const [loading, setIsLoading] = useState(false)
  const [state, setState] = useState<State>({
    host: project.smtp?.host ?? "",
    port: project.smtp?.port ?? "",
    user: project.smtp?.user ?? "",
    password: project.smtp?.password ?? "",
    from_address: project.email_from_address,
    emailDeliveryEnabled: project.email_delivery_enabled
  })
  const [enabled, setEnabled] = useState(project.smtp?.enabled !== undefined ? project.smtp.enabled : false)
  const theme = useTheme()
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const loaderData = useLoaderData() as LoaderData
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)

    if (enabled && (state.host === undefined || state.host.trim().length === 0)) {
      setState({
        ...state,
        hostError: "host must not be empty",
      })
      setIsLoading(false)
      return
    }
    if (enabled && (state.port === undefined || state.port.trim().length === 0)) {
      setState({
        ...state,
        portError: "port must not be empty",
      })
      setIsLoading(false)
      return
    }
    if (enabled && (state.user === undefined || state.user.trim().length === 0)) {
      setState({
        ...state,
        userError: "user must not be empty",
      })
      setIsLoading(false)
      return
    }
    if (enabled && (state.password === undefined || state.password.trim().length === 0)) {
      setState({
        ...state,
        passwordError: "password must not be empty",
      })
      setIsLoading(false)
      return
    }

    try {
      await ApiClient.updateProject(organization.id, project.id, {
        smtp: {
          enabled: enabled,
          email_from_address: state.from_address,
          smtp: {
            host: state.host,
            port: state.port,
            user: state.user,
            password: state.password
          }
        },
        email_delivery_enabled: state.emailDeliveryEnabled
      })
      success("Settings updated")
      revalidator.revalidate()
    } catch (e) {
      error("failed to update settings")
    }
    setIsLoading(false)
  }

  const onCancel = () => {
    setState({
      ...state,
      host: project.smtp?.host ?? "",
      port: project.smtp?.port ?? "",
      user: project.smtp?.user ?? "",
      password: project.smtp?.password ?? "",
      from_address: project.email_from_address,
      emailDeliveryEnabled: project.email_delivery_enabled
    })
    setEnabled(project.smtp?.enabled !== undefined ? project.smtp.enabled : false)
  }

  const somethingChanged = () => {
    return state.host !== (project.smtp?.host ?? "") ||
      state.port !== (project.smtp?.port ?? "") ||
      state.user !== (project.smtp?.user ?? "") ||
      state.password !== (project.smtp?.password ?? "") ||
      state.from_address !== project.email_from_address ||
      enabled !== (project.smtp?.enabled !== undefined ? project.smtp.enabled : false) ||
      state.emailDeliveryEnabled !== project.email_delivery_enabled
  }

  const hasSubscription = loaderData.subscription !== null && loaderData.subscription.is_active
  const switchInput = (disabled: boolean) => <Switch color="success" checked={enabled}
                                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEnabled(e.target.checked)}
                                                     disabled={disabled}/>

  const emailDeliverySwitchInput = (disabled: boolean) => <Switch color="success" checked={state.emailDeliveryEnabled}
                                                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState({
                                                                    ...state,
                                                                    emailDeliveryEnabled: e.target.checked
                                                                  })}
                                                                  disabled={disabled}/>

  return <Box maxWidth={Width.NORMAL}>
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection={matchesMdDown && !hasSubscription ? "column" : "row"}
           sx={{
             mb: 3,
             gap: 3,
             alignItems: matchesMdDown && !hasSubscription ? "normal" : "start",
             justifyContent: 'space-between'
           }}>
        <SettingsHead title="Email delivery by Hanko"
                      description="If enabled, Hanko servers will deliver all emails for your project (e.g. passcode emails). If disabled, Hanko will not send any email and you must subscribe to the email.send webhook and implement custom email sending."
                      sx={{mb: 0}}
                      organization={organization} type="feature" plan="Pro"/>
        {hasSubscription ? emailDeliverySwitchInput(false) :
          <SubscriptionFeatureTooltip>{emailDeliverySwitchInput(true)}</SubscriptionFeatureTooltip>
        }
      </Box>
      <Box display="flex" flexDirection={matchesMdDown && !hasSubscription ? "column" : "row"}
           sx={{
             mb: 3,
             gap: 3,
             alignItems: matchesMdDown && !hasSubscription ? "normal" : "start",
             justifyContent: 'space-between'
           }}>
        <SettingsHead title="Custom SMTP server"
                      description="Allows you to send Hanko emails through your own SMTP server. Please check your SMTP settings carefully and make sure your SMTP server actually sends out Hanko emails."
                      sx={{mb: 0}}
                      organization={organization} type="feature" plan="Pro"/>
        {hasSubscription ? switchInput(false) :
          <SubscriptionFeatureTooltip>{switchInput(true)}</SubscriptionFeatureTooltip>
        }
      </Box>

      <Box display="flex" flexDirection="column" sx={{gap: 3}}>
        <Box display="flex" flexDirection="row" sx={{gap: 3}}>
          <TextField required disabled={!enabled} label="Host" name="host" placeholder="example.com" onChange={onChange}
                     value={state.host}
                     error={state.hostError !== undefined}
                     sx={{width: "70%"}} InputLabelProps={{shrink: true}}/>
          <TextField required disabled={!enabled} label="Port" name="port" placeholder="465" onChange={onChange}
                     value={state.port}
                     error={state.portError !== undefined}
                     sx={{width: "30%"}} InputLabelProps={{shrink: true}}/>
        </Box>
        <TextField required fullWidth disabled={!enabled} label="Username" name="user" onChange={onChange}
                   value={state.user} error={state.userError !== undefined} InputLabelProps={{shrink: true}}
                   placeholder="user"/>
        <TextField required fullWidth type="password" disabled={!enabled} label="Password" name="password"
                   onChange={onChange} value={state.password} error={state.passwordError !== undefined}
                   InputLabelProps={{shrink: true}} placeholder="password"/>
        <TextField required fullWidth type="email" disabled={!enabled} label="Sender email address" name="from_address"
                   placeholder="mail@example.com"
                   onChange={onChange}
                   value={state.from_address}/>
      </Box>
      <FormButtons onCancel={onCancel} loading={loading} disabled={!somethingChanged()} sx={{mt: 5}}/>
    </form>
  </Box>
}