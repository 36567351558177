import { HankoDialog } from "../components/HankoDialog";
import { Await, useAsyncError, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PublicInvitation } from "../models/Invitation";
import { ApiClient } from "../client/ApiClient";
import { FormButtons } from "../components/form/FormButtons";
import { ReactComponent as InviteIcon } from "../assets/invite.svg";
import { SnackbarContext } from "../components/Snackbar/SnackbarProvider";

export const Invitation = () => {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loader, setLoader] = useState<Promise<PublicInvitation> | undefined>()
  const [loading, setLoading] = useState(false)
  const {error} = useContext(SnackbarContext)

  useEffect(() => {
    const token = searchParams.get("token") ?? ""
    setLoader(ApiClient.getInvitation(token))
  }, [searchParams])

  const onDecline = async () => {
    try {
      await ApiClient.declineInvitation(searchParams.get("token") ?? "")
      navigate("/organizations", {replace: true})
    } catch (e) {
      if (e instanceof Response && e.status === 403) {
        error("You are not allowed to decline the invitation.")
      } else {
        error("Failed to decline invitation")
        console.log(e)
      }
    }
  }

  const onCancel = () => {
    navigate("/organizations", {replace: true})
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      const invite = await ApiClient.acceptInvitation(searchParams.get("token") ?? "")
      navigate("/organizations/" + invite.organization.id, {replace: true})
    } catch (e) {
      if (e instanceof Response && e.status === 403) {
        error("You are not allowed to accept the invitation.")
      } else {
        error("Failed to accept invitation")
        console.log(e)
      }
    }
    setLoading(false)
  }

  return <HankoDialog onClose={onCancel} open={true} fullScreen>
    <Box margin="auto" sx={{display: 'flex', flexDirection: 'column', maxWidth: "500px"}}>
      {loader !== undefined ?
        <React.Suspense fallback={<CircularProgress color="info"/>}>
          <Await resolve={loader} errorElement={<Error onCancel={onCancel}/>}>
            {(invitation: Awaited<PublicInvitation>) => (
              <>
                <Box sx={{display: 'flex', margin: 'auto'}}>
                  <InviteIcon/>
                </Box>
                <Typography variant="h4" sx={{mb: 2, mt: 6}}>Join {invitation.organization.name}</Typography>
                <Typography variant="body1">You have been invited to join {invitation.organization.name} on Hanko Cloud.
                  You can accept or decline the invitation.</Typography>

                <form onSubmit={onSubmit}>
                  <FormButtons onCancel={onDecline} loading={loading} disabled={false} submitButtonText="Accept" resetButtonText="Decline" sx={{mt: 5}}/>
                </form>
              </>
            )}
          </Await>
        </React.Suspense> : null}
    </Box>
  </HankoDialog>
}

const Error = (props: { onCancel: () => void }) => {
  const error = useAsyncError() as Response
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  let title = "Internal Server Error"
  let description = "Something went wrong. Please try again later."

  if (error.status === 404) {
    title = "Invitation not found"
    description = "This invitation cannot be found or has already been accepted or declined. Please contact the owner of the organization you want to get access to."
  } else if (error.status === 429) {
    title = "Too many requests"
    description = "You have tried to gain access too many times. Try again in a few minutes."
  }

  return <>
    <Box sx={{display: 'flex', margin: 'auto'}}>
      <InviteIcon/>
    </Box>
    <Typography variant="h4" sx={{mb: 2, mt: 6}}>{title}</Typography>
    <Typography variant="body1">{description}</Typography>
    <Box sx={{display: 'flex', marginLeft: 'auto', width: isXs ? '100%' : '160px', my: 5}}>
      <Button variant="contained" onClick={props.onCancel} fullWidth>Ok</Button>
    </Box>
  </>
}