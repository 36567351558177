import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useLoaderData, useNavigate, useOutletContext, useRevalidator } from "react-router-dom";
import { Organization } from "../../../models/Organization";
import { ApiClient } from "../../../client/ApiClient";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { DeletionNotAllowedModal } from "./DeletionNotAllowedModal";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Subscription } from "../../../models/Subscription";
import { Project } from "../../../models/Project";
import { FormButtons } from "../../../components/form/FormButtons";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { DeleteComponent } from "../../../components/DeleteComponent";


const Settings = () => {
  const organization = useOutletContext() as Organization
  const [organizationName, setOrganizationName] = useState(organization.name)
  const [isLoading, setIsLoading] = useState(false)
  const [nameError, setNameError] = useState<string | undefined>(undefined)
  const revalidator = useRevalidator()
  const [notAllowedModalOpen, setNotAllowedModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [hasProjects, setHasProjects] = useState(true)
  const [hasSubscription, setHasSubscription] = useState(true)
  const data = useLoaderData() as any
  const navigate = useNavigate()
  const {success, error} = useContext(SnackbarContext)

  useEffect(() => {
    data.subscription.then((sub: Subscription | null) => {
      setHasSubscription(sub !== null && sub.is_active)
    })
    data.projects.then((projects: Project[]) => {
      setHasProjects(projects.length > 0)
    })
  }, [data])

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)

    let newNameError: string | undefined = undefined
    if (organizationName.trim().length === 0) {
      newNameError = "not a valid name"
    }

    if (newNameError) {
      setNameError(newNameError)
      return
    }

    try {
      await ApiClient.updateOrganization(organization.id, {name: organizationName})
      success("Organization settings updated")
    } catch (e) {
      error("Failed to update organization settings")
      console.log(e)
    }

    setIsLoading(false)
    setNameError(undefined)
    revalidator.revalidate()
  }

  const deleteOrganization = async () => {
    try {
      await ApiClient.deleteOrganization(organization.id)
      revalidator.revalidate()
      navigate("/organizations", {state: organization.id})
    } catch (e) {
      error("Failed to delete organization")
    }
  }

  return (
    <Box maxWidth="800px">
      <form onSubmit={submit}>
        <SettingsHead title="Organization settings" description="Manage your organization settings." sx={{mb: 3}}/>
        <TextField name="name" label="Organization name" value={organizationName} fullWidth
                   error={nameError !== undefined} helperText={nameError}
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                     setOrganizationName(e.target.value)
                   }}/>
        <FormButtons onCancel={() => {
          setOrganizationName(organization.name)
        }} loading={isLoading} disabled={organizationName === organization.name} sx={{mt: 5}}/>
      </form>
      <DeleteComponent onDelete={() => {if (hasProjects || hasSubscription) {
                setNotAllowedModalOpen(true)
              } else {
                setDeleteModalOpen(true)
              }}} title="Delete organization" description="Are you sure about that? We’d be sorry to see you go." buttonText="Delete" />
      <DeletionNotAllowedModal open={notAllowedModalOpen} onClose={() => {
        setNotAllowedModalOpen(false)
      }} title="Deletion not possible"
                               description={<>An organization can only be deleted if there is no active subscription and
                                 all projects have been deleted.<br/><br/><b>Note:</b> When cancelling your
                                 subscription, it remains active until the end of the billing period.</>}
                               buttonText="Ok"/>
      <ConfirmationModal open={deleteModalOpen} onClose={() => {
        setDeleteModalOpen(false)
      }} onConfirm={deleteOrganization} title="Delete organization"
                         description={`Are you sure you want to delete '${organization.name}'?`}
                         buttonText="Delete organization"/>
    </Box>
  )
}

export default Settings