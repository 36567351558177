import { Await, useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { ContextType } from "../../ProjectWrapper";
import { Box, Button, CircularProgress, Link, SxProps, Theme, Typography } from "@mui/material";
import { Width } from "../../../../const/Width";
import { SuspendedInfoComponent } from "../../../../components/SuspendedInfoComponent";
import React, { useContext, useState } from "react";
import { ProjectUser } from "../../../../models/ProjectUser";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ChevronLeft } from "@mui/icons-material";
import { ApiClient } from "../../../../client/ApiClient";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { Project } from "../../../../models/Project";
import { Organization } from "../../../../models/Organization";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { EmailTable } from "./Emails";
import { PasswordTable } from "./Password";
import { PasskeyTable } from "./Passkeys";
import { ThirdPartyConnectionTable } from "./ThirdPartyConnection";
import { ErrorPage } from "../../../ErrorPage";

export const UserDetail = () => {
  const data = useLoaderData() as any
  const navigate = useNavigate()
  const { project, organization } = useOutletContext() as ContextType

  const navigateBack = () => {
    navigate(-1)
  }

  return <Box maxWidth={Width.WIDE}>
    {project.status.replicas === 0 ?
      <SuspendedInfoComponent project={project} organization={organization} type="data" dataType="user" /> :
      <React.Suspense fallback={<CircularProgress />}>
        <Await resolve={data.user}
               errorElement={<ErrorPage title="Failed to load user" description="Try to reload the page." />}>
          {(user: Awaited<ProjectUser>) => (
            <Box>
              <Grid2 container rowSpacing={5} columnSpacing={3}>
                <Grid2 xs={12}>
                  <Button variant="outlined" startIcon={<ChevronLeft />} onClick={navigateBack}>Back</Button>
                </Grid2>
                <Grid2 xs={12} md={6} lg={8} xl={9}>
                  <GeneralUserDetails user={user} />
                  <NameDetail label="Username" content={user.username?.username} defaultContent="No username set"
                              sx={{ mt: 5 }} />
                </Grid2>
                <Grid2 xs={12} md={6} lg={4} xl={3} sx={{ alignContent: 'end' }}>
                  <Actions organization={organization} project={project} user={user} />
                </Grid2>
                <Grid2 xs={12}>
                  <EmailTable user={user} organization={organization} project={project} />
                </Grid2>
                <Grid2 xs={12}>
                  <PasswordTable user={user} />
                </Grid2>
                <Grid2 xs={12}>
                  <ThirdPartyConnectionTable user={user} />
                </Grid2>
                <Grid2 xs={12}>
                  <PasskeyTable user={user} />
                </Grid2>
                {/* TODO: hanko admin API needs to return active sessions from a user */}
                {/*<Grid2 xs={12}>*/}
                {/*  <SessionTable user={user} />*/}
                {/*</Grid2>*/}
              </Grid2>
            </Box>
          )}
        </Await>
      </React.Suspense>
    }
  </Box>
}

const GeneralUserDetails = (props: { user: ProjectUser }) => {
  const { user } = props

  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  })

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <UserHeadline user={user} />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Info label="UserID" content={user.id} />
      <Info label="Created at (UTC)" content={dateFormat.format(new Date(user.created_at))} />
      <Info label="Last active" content="Show activity" link={`../events?q=${user.id}`} />
    </Box>
  </Box>
}

const UserHeadline = (props: { user: ProjectUser }) => {
  const { user } = props
  let name = user.id;
  if (user.username) {
    name = user.username.username
  } else if (user.emails && user.emails?.find((value) => value.is_primary)) {
    name = user.emails?.find((value) => value.is_primary)?.address ?? ""
  }

  return <Typography variant="h6">{name}</Typography>
}

const Info = (props: { label: string, content: string, link?: string }) => {
  const { label, content, link } = props
  return <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
    <Typography variant="body2" fontWeight="bold">{label}:</Typography>
    <Typography variant="body2">{link ? <Link href={link}>{content}</Link> : content}</Typography>
  </Box>
}

const Actions = (props: { organization: Organization, project: Project, user: ProjectUser }) => {
  const { organization, project, user } = props
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { error } = useContext(SnackbarContext)
  const navigate = useNavigate()

  const deleteUser = async (userId: string) => {
    try {
      await ApiClient.deleteProjectUser(organization.id, project.id, userId)
      navigate(-1)
    } catch (e) {
      error("failed to delete user")
    }
  }

  return <Box sx={{
    display: ' flex',
    flexDirection: 'column',
    padding: '16px 24px',
    gap: 2,
    borderRadius: 4,
    border: '1px solid #262B35',
    background: 'rgba(38, 43, 53, 0.4)'
  }}>
    <Typography variant="h6">Actions</Typography>
    <Button variant="outlined" color="error" onClick={() => setDeleteModalOpen(true)}>Delete user</Button>
    <ConfirmationModal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={() => {
      deleteUser(user.id)
    }} title="Delete user" description={`Are you sure you want to delete user '${user.id}'?`}
                       buttonText="Delete user" />
  </Box>
}

const NameDetail = (props: { label: string, content?: string, defaultContent: string, sx?: SxProps<Theme> }) => {
  const { label, content, defaultContent, sx } = props
  return <Box sx={{
    display: ' flex',
    flexDirection: 'column',
    padding: '16px 24px',
    gap: 1,
    borderRadius: 4,
    border: '1px solid #262B35',
    background: 'rgba(38, 43, 53, 0.4)',
    ...sx
  }}>
    <Typography variant="h6" sx={{ fontSize: '18px' }}>{label}</Typography>
    {content !== undefined ? <Typography variant="body2">{content}</Typography> :
      <Typography variant="body2" sx={{
        color: '#7C8E9C'
      }}>{defaultContent}</Typography>}
  </Box>
}
