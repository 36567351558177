import { Button, Typography } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { Organization } from "../../models/Organization";
import { HankoSecondaryCard } from "./HankoSecondaryCard";

interface Props {
  organization: Organization
}

export const UpgradeCard = (props: Props) => {
  const navigate = useNavigate()

  return <HankoSecondaryCard>
    <Typography variant="body1">If you need more projects, please upgrade your plan.</Typography>
    <Button variant="contained" sx={{marginLeft: "auto"}}
            onClick={() => navigate(generatePath("/organizations/:organizationId/billing", {organizationId: props.organization.id}))}>Upgrade</Button>
  </HankoSecondaryCard>
}