import { Box, CircularProgress, Link, Stack, Typography, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { ApiClient } from "../../client/ApiClient";
import { ProjectStatus as ProjectStatusDto } from "../../models/ProjectStatus";
import { Organization } from "../../models/Organization";
import { useLoaderData } from "react-router-dom";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

interface Props {
  organization: Organization
  projectId: string
}

const ProjectStatus = (props: Props) => {
  const theme = useTheme()
  const [status, setStatus] = useState<ProjectStatusDto | undefined>(undefined)
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const data = useLoaderData() as any

  const getProjectStatus = async () => {
    try {
      const status = await ApiClient.getProjectStatus(props.organization.id, props.projectId)
      setStatus(status)
    } catch (e) {
      console.log("failed to get project status: ", e)
    }
  }

  const reactivateProject = async (e: SyntheticEvent) => {
    try {
      await ApiClient.reactivateProject(props.organization.id, props.projectId)
      window.location.reload()
    } catch (e) {
      console.log("failed to reactivate project", e)
    }
  }

  const clearIntervalIfDefined = () => {
    if (interval !== undefined && interval.current !== undefined) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }

  useEffect(() => {
    getProjectStatus()
    interval.current = setInterval(getProjectStatus, 10000)
    return () => {
      clearIntervalIfDefined()
    }
  }, [])

  const renderStatus = () => {
    if (status !== undefined && status?.replicas > 0 && status?.ready_replicas > 0) {
      return (
        <Box bgcolor={"#AAF0CA21"} borderRadius={"16px"} color={theme.palette.success.main} paddingLeft={"8px"}
             paddingRight={"16px"}
             paddingY={"6.5px"}>
          <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between"
                 color={theme.palette.success.main}>
            <CheckCircleOutlineIcon sx={{color: theme.palette.success.main}}/>
            Your Project is ready to use
          </Stack>
        </Box>
      )
    } else if (status !== undefined && status?.replicas === 0) {
      return (
        <Box sx={{display:' flex', gap: 2, alignItems: 'center'}}>
          <Box bgcolor={"#BAE3FE29"}
               borderRadius={"16px"}
               color={theme.palette.primary.main}
               paddingLeft={"8px"}
               paddingRight={"16px"}
               paddingY={"6.5px"}>
            <Stack direction="row" alignItems="center" gap={1} color={theme.palette.primary.main}>
              <DoDisturbIcon sx={{color: theme.palette.primary.main}}/>
              Project suspended due to inactivity
            </Stack>
          </Box>
          <Box>
            <Link component="button" variant="body1" onClick={reactivateProject}>Reactivate</Link>
          </Box>
        </Box>
      )
    } else {
      return (
        <Box bgcolor={"#BAE3FE29"}
             borderRadius={"16px"}
             color={theme.palette.primary.main}
             paddingLeft={"8px"}
             paddingRight={"16px"}
             paddingY={"6.5px"}>
          <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgress color="primary" size={20}/>
            Your project is being set up. This may take a few minutes.
          </Stack>
        </Box>
      )
    }
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {renderStatus()}
    </Stack>
  )
}

export default ProjectStatus