import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, useTheme } from "@mui/material";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import React, { useState } from "react";
import { FormButtons } from "../../../../components/form/FormButtons";
import { Project, UpdateProjectData } from "../../../../models/Project";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  required: boolean
  acquireOnRegistration: "always" | "conditional" | "never"
  acquireOnLogin: "always" | "conditional" | "never"
  userVerification: "required" | "preferred" | "discouraged"
}

export const Passkey = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    enabled: project.passkey.enabled ?? false,
    required: !(project.passkey.optional ?? false),
    acquireOnRegistration: project.passkey.acquire_on_registration,
    acquireOnLogin: project.passkey.acquire_on_login,
    userVerification: project.passkey.user_verification,
  })

  const somethingChanged = () => {
    return (project.passkey.enabled ?? false) !== state.enabled ||
      !(project.passkey.optional ?? false) !== state.required ||
      project.passkey.acquire_on_registration !== state.acquireOnRegistration ||
      project.passkey.acquire_on_login !== state.acquireOnLogin ||
      project.passkey.user_verification !== state.userVerification
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSelectChange = (e: SelectChangeEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      passkey: {
        ...project.passkey,
        enabled: state.enabled,
        optional: !state.required,
        acquire_on_registration: state.acquireOnRegistration,
        acquire_on_login: state.acquireOnLogin,
        user_verification: state.userVerification,
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      enabled: project.passkey.enabled ?? false,
      required: !(project.passkey.optional ?? false),
      acquireOnRegistration: project.passkey.acquire_on_registration,
      acquireOnLogin: project.passkey.acquire_on_login,
      userVerification: project.passkey.user_verification,
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead title="Passkeys"
                      description="Allow users to create and use passkeys to authenticate." sx={{ mb: 1 }} />
        <Switch name="enabled" color="success" checked={state.enabled}
                onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead title="Required"
                      description="Users must create a passkey when prompted. The last remaining passkey cannot be deleted."
                      size="small" sx={{ mb: 0 }}
                      minElementsVersion="1.0" />
        <Switch name="required" color="success" checked={state.required} onChange={onChange}
                disabled={!state.enabled} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Prompt during registration"
                      description="Ask the user to create a passkey when creating a new account."
                      helpText="'Conditional' is linked to the password setting of the same name. If both are set to 'Conditional', the user can choose between password and passkey during registration. If only one credential type is set to 'Conditional', the user will not be prompted to create such a credential if a credential of other type has already been created."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.0" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="acquireOnRegistration">Prompt during registration</InputLabel>
          <Select labelId="acquireOnRegistration" id="acquire_on_registration_select"
                  name="acquireOnRegistration" label="Acquire on registration" onChange={onSelectChange}
                  value={state.acquireOnRegistration}>
            <MenuItem key="always" value="always">Always</MenuItem>
            <MenuItem key="conditional" value="conditional">Conditional</MenuItem>
            <MenuItem key="never" value="never">Never</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Prompt during login"
                      description="Ask the user to create a passkey when logging in if they do not already have one."
                      helpText="'Conditional' is linked to the password setting of the same name. If both are set to 'Conditional', the user can choose between password and passkey during login. If only one credential type is set to 'Conditional', the user will not be prompted to create such a credential if a credential of other type has already been created."
                      size="small" sx={{ mb: 0 }} minElementsVersion="1.0" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="acquireOnLogin">Prompt during login</InputLabel>
          <Select labelId="acquireOnLogin" id="acquire_on_login_select"
                  name="acquireOnLogin" label="Acquire on login" onChange={onSelectChange} value={state.acquireOnLogin}>
            <MenuItem key="always" value="always">Always</MenuItem>
            <MenuItem key="conditional" value="conditional">Conditional</MenuItem>
            <MenuItem key="never" value="never">Never</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="User verification"
                      description="Select whether the user should be verified when using the passkey (e.g. Face ID, Windows Hello)."
                      helpText="Most passkey clients always perform user verification, regardless of the value of this setting. This setting also controls whether or not the backend accepts authentication requests for which no user verification has been performed."
                      size="small" sx={{ mb: 0 }} minElementsVersion="1.0" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="userVerification">User verification</InputLabel>
          <Select labelId="userVerification" id="user_verification_select"
                  name="userVerification" label="User verification" onChange={onSelectChange}
                  value={state.userVerification}>
            <MenuItem key="required" value="required">Required</MenuItem>
            <MenuItem key="preferred" value="preferred">Preferred</MenuItem>
            <MenuItem key="discouraged" value="discouraged">Discouraged</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}

