import { PasskeyProject, PasskeyProjectApiKey, UpdatePasskeyProjectData } from "../models/PasskeyProject";
import {
  CREATE_PASSKEY_PROJECT,
  CREATE_PASSKEY_PROJECT_API_KEY,
  DELETE_PASSKEY_PROJECT,
  DELETE_PASSKEY_PROJECT_API_KEY,
  generateApiUrl,
  GET_PASSKEY_PROJECT,
  LIST_PASSKEY_PROJECT,
  LIST_PASSKEY_PROJECT_API_KEY,
  LIST_PASSKEY_PROJECT_AUDIT_LOGS,
  UPDATE_PASSKEY_PROJECT
} from "../const/Url";
import { MockPasskeyProjectApiClient } from "./MockPasskeyProjectApiClient";
import { AuditLog, AuditLogsWithTotalCount } from "../models/AuditLog";

export interface IPasskeyProjectApiClient {
  getProject(request: Request, organizationId: string, projectId: string): Promise<PasskeyProject>

  getProjects(request: Request, organizationId: string): Promise<PasskeyProject[]>

  createProject(organizationId: string, applicationName: string, applicationUrl: string): Promise<PasskeyProject>

  patchProject(organizationId: string, projectId: string, data: UpdatePasskeyProjectData): Promise<PasskeyProject>

  deleteProject(organizationId: string, projectId: string): Promise<any>

  getApiKeys(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey[]>

  createApiKey(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey>

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any>

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount>
}

class PasskeyApiClient implements IPasskeyProjectApiClient {
  getProject(request: Request, organizationId: string, projectId: string): Promise<PasskeyProject> {
    return fetch(generateApiUrl(GET_PASSKEY_PROJECT, {organizationId: organizationId, projectId: projectId}), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  getProjects(request: Request, organizationId: string): Promise<PasskeyProject[]> {
    return fetch(generateApiUrl(LIST_PASSKEY_PROJECT, {organizationId: organizationId}), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  createProject(organizationId: string, applicationName: string, applicationUrl: string): Promise<PasskeyProject> {
    return fetch(generateApiUrl(CREATE_PASSKEY_PROJECT, {organizationId: organizationId}), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        application_name: applicationName,
        application_url: applicationUrl
      })
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  patchProject(organizationId: string, projectId: string, data: UpdatePasskeyProjectData): Promise<PasskeyProject> {
    return fetch(generateApiUrl(UPDATE_PASSKEY_PROJECT, {organizationId: organizationId, projectId: projectId}), {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  deleteProject(organizationId: string, projectId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PASSKEY_PROJECT, {organizationId: organizationId, projectId: projectId}), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getApiKeys(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey[]> {
    return fetch(generateApiUrl(LIST_PASSKEY_PROJECT_API_KEY, {organizationId: organizationId, projectId: projectId}), {
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  createApiKey(organizationId: string, projectId: string): Promise<PasskeyProjectApiKey> {
    return fetch(generateApiUrl(CREATE_PASSKEY_PROJECT_API_KEY, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: 'POST',
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PASSKEY_PROJECT_API_KEY, {
      organizationId: organizationId,
      projectId: projectId,
      apiKeyId: apiKeyId
    }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount> {
    return fetch(generateApiUrl(LIST_PASSKEY_PROJECT_AUDIT_LOGS, {
      organizationId: organizationId,
      projectId: projectId,
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json().then(logs => {
        return {logs: logs as AuditLog[], totalCount: parseInt(response.headers.get("X-Total-Count") || "-1", 10)}
      })
    })
  }
}

export const PasskeyProjectApiClient: IPasskeyProjectApiClient = process.env.REACT_APP_MOCK !== 'active' ? new PasskeyApiClient() : new MockPasskeyProjectApiClient()
