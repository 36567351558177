import React, { useContext } from "react";
import { Box, Card, CardActionArea, IconButton, Stack, Typography } from "@mui/material";
import { Await, useLoaderData, useOutletContext } from "react-router-dom";
import { Project } from "../../../models/Project";
import Grid2 from '@mui/material/Unstable_Grid2';
import ProjectStatus from "../../../components/Projects/ProjectStatus";
import UserCountCard from "../../../components/Projects/UserCountCard";
import { MonthlyActiveUser } from "../../../models/MonthlyActiveUser";
import { Organization } from "../../../models/Organization";
import { ProjectUsersWithTotalCount } from "../../../models/ProjectUser";
import { Width } from "../../../const/Width";
import { ActiveUsersChart } from "../../../components/Charts/ActiveUsersChart";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { ContentCopy, KeyboardArrowRight } from "@mui/icons-material";
import { integrationGuides } from "./IntegrationGuides";
import { TotalUsersChart } from "./TotalUsersChart";
import { SignupStatsChart } from "./SignupStatsChart";


interface ContextType {
  project: Project
  organization: Organization
}


const Dashboard = () => {
  const {project, organization} = useOutletContext() as ContextType
  const data = useLoaderData() as any

  const {error, success} = useContext(SnackbarContext)

  const tryCopyUrl = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      success("Copied to clipboard")
    } catch (e) {
      error("Failed to copy to clipboard")
    }
  }

  return (
    <Grid2 container spacing={3} maxWidth={Width.WIDE}>
      <Grid2 xs={12}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
          <Typography variant="h4" sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{project.application_name}</Typography>
          <Typography variant="body1" sx={{
            color: '#7C8E9C',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{project.application_url}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} sx={{mt: 2}}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, flexWrap: 'wrap', gap: 4}}>
          <Stack direction="column" flexGrow={1}>
            <Box>
              <ProjectStatus organization={organization} projectId={project.id}/>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'baseline', mt: 3}} flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>API Version:</Typography>
              <Typography variant="body1" sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
              }}>
                {project.api_version}
              </Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'baseline', mt: 1}} flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>API URL:</Typography>
              <Box sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
                wordBreak: 'break-word',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}>
                {project.api_url}
                <IconButton onClick={() => tryCopyUrl(project.api_url)} size="small"
                            sx={{alignSelf: 'center', ml: 1}}><ContentCopy fontSize="small"/></IconButton>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 1, alignItems: 'baseline', mt: 1}} flexGrow={0}>
              <Typography variant="body1" sx={{
                fontWeight: 'bold',
                width: 'fit-content',
                whiteSpace: 'nowrap'
              }}>JWKS URL:</Typography>
              <Box sx={{
                fontFamily: ['IBM Plex Sans', 'sans-serif'].join(","),
                wordBreak: 'break-word',
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}>
                {project.api_url}/.well-known/jwks.json
                <IconButton onClick={() => tryCopyUrl(project.api_url + '/.well-known/jwks.json')} size="small"
                            sx={{alignSelf: 'center', ml: 1}}><ContentCopy fontSize="small"/></IconButton>
              </Box>
            </Box>
          </Stack>
          <Box sx={{ml: {xs: 0, lg: 'auto'}, alignSelf: {xs: '', lg: 'flex-end'}, maxWidth: '100%'}}>
            <Stack direction="row" spacing={3}>
              <React.Suspense fallback={<UserCountCard isLoading userCount={0} variant="default" label="Total users"/>}>
                <Await resolve={data.users}
                       errorElement={<UserCountCard userCount={0} variant="default" label="Total users"/>}>
                  {(users: Awaited<ProjectUsersWithTotalCount>) => (
                    <UserCountCard userCount={users.totalCount} variant="default" label="Total users"/>
                  )}
                </Await>
              </React.Suspense>
              <React.Suspense
                fallback={<UserCountCard isLoading userCount={0} variant="green"
                                         label="Active users (this month)"/>}>
                <Await resolve={data.mau}
                       errorElement={<UserCountCard userCount={0} variant="green" label="Active (this month)"/>}>
                  {(mau: Awaited<MonthlyActiveUser>) => (
                    <UserCountCard userCount={mau.count} variant="green" label="Active (this month)"/>
                  )}
                </Await>
              </React.Suspense>
            </Stack>
          </Box>
        </Box>
      </Grid2>
      <Grid2 xs={12} lg={4} sx={{mt: 2}}>
        <ActiveUsersChart projectType="hanko_project" organization={organization} project={project} size="small"/>
      </Grid2>
      <Grid2 xs={12} lg={4} sx={{mt: 2}}>
        <TotalUsersChart organization={organization} project={project}/>
      </Grid2>
      <Grid2 xs={12} lg={4} sx={{mt: 2}}>
        <SignupStatsChart organization={organization} project={project}/>
      </Grid2>
      <Grid2 xs={12} sx={{mt: 7}}>
        <Typography variant="h6">Integration guides</Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3, mt: 3}}>
          {integrationGuides.map((guide) => {
            return <Card key={guide.title} sx={{height: '67px', width: {xs: '100%', sm: '180px'}, borderRadius: 2}}>
              <CardActionArea sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                p: 2,
                justifyContent: 'space-between'
              }} href={guide.href} target="_blank">
                <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
                  {guide.icon}
                  <Typography variant="body1" sx={{fontWeight: 500}}>{guide.title}</Typography>
                </Box>
                <KeyboardArrowRight/>
              </CardActionArea>
            </Card>
          })}
        </Box>
      </Grid2>
    </Grid2>
  )
}

export default Dashboard
