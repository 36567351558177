import { Link, Typography } from "@mui/material";
import { Organization } from "../models/Organization";

interface Props {
  organization: Organization
  type: "plan" | "feature"
  plan: "Starter" | "Pro" | "Business"
}

export const PlanChip = (props: Props) => {
  const {organization, type, plan} = props

  return <Link href={`/organizations/${organization.id}/billing`} underline='none' sx={{
    color: 'white',
    backgroundColor: colors[plan].default.background,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors[plan].default.border,
    '&:hover': {
      backgroundColor: colors[plan].hover.background,
      borderColor: colors[plan].hover.border
    },
    height: '30px',
    borderRadius: '29px',
    display: 'flex',
    alignItems: 'center',
    px: 1.5,
    pt: '1px',
    width: 'fit-content',
  }}>
    <Typography variant="caption" sx={{
      fontSize: "0.8125rem",
      fontWeight: 500
    }}>{plan} {type}</Typography>
  </Link>
}

const colors = {
  Starter: {
    default: {
      background: '#35114E',
      border: '#8010C2'
    },
    hover: {
      background: '#591D82',
      border: '#8010C2',
    }
  },
  Pro: {
    default: {
      background: '#0A1957',
      border: '#233AAB'
    },
    hover: {
      background: '#112578',
      border: '#233AAB',
    }
  },
  Business: {
    default: {
      background: '#0A1957',
      border: '#233AAB',
    },
    hover: {
      background: '#112578',
      border: '#233AAB',
    }
  }
}