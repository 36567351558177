import { Await, useLoaderData, useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../../Projects/ProjectWrapper";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../client/ApiClient";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Width } from "../../../const/Width";
import { SettingsHead } from "../../../components/form/SettingsHead";
import CopyableTextField from "../../../components/CopyableTextField";
import { LoadingButton } from "@mui/lab";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { HankoDialog } from "../../../components/HankoDialog";
import { PasskeyProjectApiClient } from "../../../client/PasskeyProjectApiClient";
import { PasskeyProjectApiKey } from "../../../models/PasskeyProject";
import { PasskeyProjectContextType } from "../PasskeyProjectWrapper";

export const ApiKeys = () => {
  const data = useLoaderData() as any
  const {project, organization} = useOutletContext() as PasskeyProjectContextType
  const [isCreating, setIsCreating] = useState(false)
  const [newApiKey, setNewApiKey] = useState<PasskeyProjectApiKey | undefined>(undefined)
  const revalidator = useRevalidator()
  const {error, success} = useContext(SnackbarContext)

  const createAPIKey = async (e: React.SyntheticEvent) => {
    setIsCreating(true)
    e.preventDefault()

    try {
      const response = await PasskeyProjectApiClient.createApiKey(organization.id, project.id)
      revalidator.revalidate()
      setNewApiKey(response)
    } catch (e) {
      error("Failed to create new API key")
    }

    setIsCreating(false)
  }

  const deleteApiKey = async (apiKeyId: string) => {
    try {
      await PasskeyProjectApiClient.deleteApiKey(organization.id, project.id, apiKeyId)
      revalidator.revalidate()
      success("API key deleted successfully")
    } catch (e) {
      error("Failed to delete API key")
    }
  }

  return <Box maxWidth={Width.NORMAL} display="flex" flexDirection="column">
    <SettingsHead title="API keys"
                  description={<span>Create an API key to access the Passkey API.</span>}
                  type="feature"
                  organization={organization}
                  sx={{mb: 0}}/>

    <Table sx={{mt: 8}} size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{px: 0, py: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <SettingsHead title="API Keys" description={null} sx={{mb: 0, gap: 0}}/>
              <form onSubmit={createAPIKey}>
                <LoadingButton loading={isCreating} variant="contained" type="submit" sx={{width: '160px'}}
                               disabled={isCreating}>Create
                  API key</LoadingButton>
              </form>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>

      <React.Suspense fallback={<CircularProgress/>}>
        <Await resolve={data.apiKeys} errorElement={<></>}>
          {(apiKeys: Awaited<PasskeyProjectApiKey[]>) => (
            <>
              <TableBody>
                {apiKeys.length === 0 ? <TableRow>
                  <TableCell colSpan={2} sx={{color: '#7C8E9C'}}
                             size="medium">This project has no API keys.</TableCell>
                </TableRow> : null}
                {apiKeys.map((apiKey) => {
                  return <ApiKeyTableRow apiKey={apiKey.id} deleteApiKey={deleteApiKey}/>
                })}
              </TableBody>
            </>
          )}
        </Await>
      </React.Suspense>
    </Table>
    <NewAPIKeyModal onClose={() => {
      setNewApiKey(undefined)
    }} apiKey={newApiKey ?? {id: "", name: "", secret: "", created_at: ""}} open={newApiKey !== undefined}/>
  </Box>
}

interface RowProps {
  apiKey: string
  deleteApiKey: (key: string) => Promise<void>
}

const ApiKeyTableRow = (props: RowProps) => {
  const {apiKey, deleteApiKey} = props
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteKey = async () => {
    await deleteApiKey(apiKey)
    setDeleteModalOpen(false)
  }

  return <>
    <TableRow>
      <TableCell>{apiKey}</TableCell>
      <TableCell sx={{width: 0}}><Button variant="text" color="error"
                                         onClick={() => {
                                           setDeleteModalOpen(true)
                                         }}>Delete</Button></TableCell>
    </TableRow>
    <ConfirmationModal buttonText="Delete API key" description="Do you really want to delete this API key?"
                       onClose={() => {
                         setDeleteModalOpen(false)
                       }} onConfirm={deleteKey} open={deleteModalOpen} title="Delete API key"/>
  </>
}

interface ModalProps {
  onClose: () => void
  apiKey
    :
    PasskeyProjectApiKey
  open: boolean
}

const NewAPIKeyModal = (props: ModalProps) => {
  const {open, onClose, apiKey} = props
  return <HankoDialog onClose={onClose} open={open}>
    <Box maxWidth="500px" sx={{display: "flex", gap: "24px", flexDirection: 'column'}}>
      <Typography variant="h4">New API key</Typography>
      <Alert severity="warning" variant="outlined">The secret is only displayed here. There is no possibility to view
        it
        again later.</Alert>
      <CopyableTextField copyableContent={apiKey.id} label="ID"/>
      <CopyableTextField copyableContent={apiKey.secret} label="Secret"/>
      <Button variant="contained" fullWidth onClick={onClose} sx={{mt: 2, mb: 2}}>Ok</Button>
    </Box>
  </HankoDialog>
}