import { Box, Typography } from "@mui/material";
import { HankoDialog } from "./HankoDialog";
import React, { useEffect } from "react";

import { register } from "@teamhanko/hanko-elements"

interface Props {
  open: boolean
  onClose: () => void

}

export const Profile = (props: Props) => {

  const onClose = () => {
    props.onClose()
  }

  useEffect(() => {
    register(window.REACT_APP_LOGIN_PROVIDER_URL, {shadow: true}).catch(() => {})
  }, [props.open]);

  return <HankoDialog open={props.open} onClose={onClose} fullScreen>
    <Box margin="auto" maxWidth="500px" sx={{display: "flex", flexDirection: "column"}}>
      <Typography variant="h4" sx={{mt: 2.5, mb: 5}}>Profile Settings</Typography>
      <hanko-profile lang="en"/>
    </Box>
  </HankoDialog>
}